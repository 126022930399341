import { TRPCLink } from '@trpc/client'
import { observable } from '@trpc/server/observable'
import { LowgileJsonMapper } from '@shared/data/lowgile-json-mapper'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'

export function createJsonResponseLinkMapper(jsonMapper: BaseJsonMapper<object>): TRPCLink<any> {
	return () => {
		return ({ next, op }) => {
			return observable(observer => {
				// if(op.input && typeof op.input == 'object') {
				// 	op.input = jsonMapper.writeToObject(op.input)
				// }

				const subscription = next(op).subscribe({
					next(envelope) {
						if(envelope.result.type == 'data') {
							const mapped = jsonMapper.readFromObject(envelope.result.data as object)

							const newEnvelope = {
								...envelope,
								result: {
									...envelope.result,
									data: mapped
								}
							}

							observer.next(newEnvelope)
						} else {
							observer.next(envelope)
						}
					},
					error(err) {
						observer.error(err)
					},
					complete() {
						observer.complete()
					},
				})

				return subscription
			})
		}
	}
}
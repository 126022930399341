// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    statusChart: Sys.Types.ChartConfiguration;
    requests: This.PurchaseRequest[];
    openPos: This.PurchaseOrder[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-Dashboard',
    templateUrl: './Dashboard.html',
    styleUrls: ['./Dashboard.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Dashboard extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.Dashboard';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_5 = (screen: Properties) => ['requestNumber', 'requestStatus'];
    _DataTableBlock_columns_12 = (screen: Properties) => ['requestNumber', 'itemCount'];
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        statusChart: this.statusChart !== undefined ? this.statusChart : (undefined) as Sys.Types.ChartConfiguration,
        requests: this.requests !== undefined ? this.requests : (undefined) as This.PurchaseRequest[],
        openPos: this.openPos !== undefined ? this.openPos : (undefined) as This.PurchaseOrder[]
    });

    get $inputs() {
        return {

        }
    }

    get statusChart() {
        return this.screen?.statusChart
    }

    set statusChart(statusChart: Sys.Types.ChartConfiguration) {
        statusChart = (statusChart as any)?.[ProxyTargetSymbol] ?? statusChart
        this.storeService.setProperty([this.componentStore.name], 'statusChart', statusChart)
    }

    get requests() {
        return this.screen?.requests
    }

    set requests(requests: This.PurchaseRequest[]) {
        requests = (requests as any)?.[ProxyTargetSymbol] ?? requests
        this.storeService.setProperty([this.componentStore.name], 'requests', requests)
    }

    get openPos() {
        return this.screen?.openPos
    }

    set openPos(openPos: This.PurchaseOrder[]) {
        openPos = (openPos as any)?.[ProxyTargetSymbol] ?? openPos
        this.storeService.setProperty([this.componentStore.name], 'openPos', openPos)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.requests = undefined
                θstate.screen.openPos = undefined

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    _DataTableBlock_collectionCode_5($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.requests)
    }

    _DataTableBlock_additionalFindOptions_5($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_7_7($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled, request, requestIndex }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, request: This.PurchaseRequest, requestIndex: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_6_6($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled, request, requestIndex }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, request: This.PurchaseRequest, requestIndex: number } = $scope ?? {}

        return (request.requestStatus.id)
    }

    _DataTableBlock_collectionCode_12($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.openPos)
    }

    _DataTableBlock_additionalFindOptions_12($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_13_13($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled, po, poIndex }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}

        return (po.poNumber)
    }

    _DataTableColumnBlock_cellText_14_14($scope: any): any {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled, po, poIndex }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}

        return (po.items.length)
    }

    async handler_ButtonBlock_0_0_0_1_1_click_downloadPos_0($event: any, $scope: any): Promise<void> {
        let { statusChart, requests, openPos, app, screen, $pathSegments, $disabled }: { statusChart: Sys.Types.ChartConfiguration, requests: This.PurchaseRequest[], openPos: This.PurchaseOrder[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_downloadPos($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            Sys.App.title = 'Procurement Dashboard'

            const requestsPromise = This.Db.loadByCondition(This.PurchaseRequest)
            const openPosPromise = This.Db.loadByCondition(This.PurchaseOrder, {
                where: {},
                relations: { items: true },
            })

            screen.requests = await requestsPromise
            screen.openPos = await openPosPromise

            const groupedByStatus = Object.entries(Sys.Ramda.groupBy(req => req.requestStatus.id, screen.requests))

            screen.statusChart = {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: groupedByStatus.map(([status, requests]) => (requests as any).length)
                    }],
                    labels: groupedByStatus.map(([status, requests]) => requests[0].requestStatus.id)
                },
            }

        }
    }

    async action_downloadPos($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            const base64 = await This.PurchaseOrder.exportToXls()
            const binary = Sys.Text.convert(base64, 'base64', 'binary')
            Sys.App.provideDownload(binary, `Open POs - ${Sys.Date.format(new Date, 'yyyyMMdd')}.xlsx`, 'application/vnd.ms-excel')
        }
    }
}

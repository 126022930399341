// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => PaymentTerm.loadInternal(), entryList => {
        PaymentTerm.θentryList = entryList
        PaymentTerm.θentryMap = undefined
        PaymentTerm.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class PaymentTerm {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: PaymentTerm.Id;
    static θids: ReadonlyArray<PaymentTerm.Id> = Object.freeze(['20d', '30d', '60d']);
    static θentryMap: Readonly<Record<PaymentTerm.Id, Readonly<PaymentTerm.Entry>>>;
    static θentryList: ReadonlyArray<PaymentTerm.Entry>;
    static θdropdownOptionsCache = new Map<keyof PaymentTerm.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.PaymentTerm' {
        return 'PurchaseToPay.PaymentTerm'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.PaymentTerm' {
        return 'PurchaseToPay.PaymentTerm'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return PaymentTerm.getEntryMap()[this.id]?.label
    }

    get isDays20() {
        return this.id == '20d'
    }

    static get Days20() {
        return new this('20d')
    }

    get isDays30() {
        return this.id == '30d'
    }

    static get Days30() {
        return new this('30d')
    }

    get isDays60() {
        return this.id == '60d'
    }

    static get Days60() {
        return new this('60d')
    }

    constructor(id: PaymentTerm.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: PaymentTerm.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof PaymentTerm.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<PaymentTerm.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "20d", label: "20 days" }),
            Object.freeze({ id: "30d", label: "30 days" }),
            Object.freeze({ id: "60d", label: "60 days" })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new PaymentTerm(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: PaymentTerm.Id | PaymentTerm.Option | PaymentTerm) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.PaymentTerm yet:', err.message)
            //throw err
        })
    }

    setDays20() {
        this.id = '20d'

    }

    setDays30() {
        this.id = '30d'

    }

    setDays60() {
        this.id = '60d'

    }
}

export const PaymentTermθId = String;

export type PaymentTermθId = string;

export namespace PaymentTerm {
    export type Id = '20d' | '30d' | '60d'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
    }

    export enum Option {
        Days20 = '20d',
        Days30 = '30d',
        Days60 = '60d'
    }
}
Sys.Language.languageChanged$.subscribe(() => PaymentTerm.load(true))

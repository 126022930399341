import { Parameter } from '@shared/script/parameter'
import { assignCommonProperties } from '@shared/util/data-util'

import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ConfigItemSelectionOption {
	@jsonMember(String)
	value?: string
	@jsonMember(String)
	displayName?: string

	constructor(init?: Partial<ConfigItemSelectionOption>) {
		assignCommonProperties(this, init)
	}
}

@jsonObject
export class ConfigurationItemProperty extends Parameter {
	@jsonMember(String)
	inputType: 'dropdown' | 'input' = 'input'
	@jsonArrayMember(ConfigItemSelectionOption)
	selectionOptions: ConfigItemSelectionOption[] = []
	@jsonMember(String)
	validationRegex: string = ''
	@jsonMember(String)
	hint: string = ''
	@jsonMember(Boolean)
	isSecret: boolean = false
	@jsonMember(Boolean)
	isAvailableOnClient: boolean = true

	constructor(init?: Partial<ConfigurationItemProperty>) {
		super()
		assignCommonProperties(this, init)
	}
}

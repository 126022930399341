import { Semaphore } from '@shared/util/semaphore'
import { BehaviorSubject, Subject } from 'rxjs'

export class DataLoader<T> {
	private semaphore = new Semaphore()
	private state: 'uninitialized' | 'loading' | 'valid' | 'expired' = 'uninitialized'
	private data?: T
	private dataPromise?: Promise<T>
	private expireTimeout: any

	constructor(
		private loadFn: () => Promise<T>,
		private onLoadedCallback: (data: T) => void,
		private options?: {
			doCache?: boolean,
			expireAfterMs?: number,
			autoReloadOnExpiration?: boolean
		}
	) {
		this.options = {
			doCache: true,
			expireAfterMs: 0,
			autoReloadOnExpiration: false,
			...this.options
		}
		if(!this.options.doCache) { // prevent infinite reload loop
			this.options.expireAfterMs = 0
			this.options.autoReloadOnExpiration = false
		}
	}

	async waitAndGetValidData() {
		if(this.state == 'uninitialized' || this.state == 'expired') this.reload()
		return this.dataPromise
	}

	async waitAndGetPossiblyExpiredData() {
		if(this.state == 'valid') return this.data
		if(this.state == 'uninitialized' || this.state == 'expired') this.reload()
		if(this.state == 'expired') return this.data
		return this.dataPromise
	}

	async forceReloadAndGetData() {
		return this.reload()
	}

	getCurrentPossiblyInvalidData() {
		return this.data
	}

	getState() {
		return this.state
	}

	markExpired() {
		this.cancelExpireTimeout()
		if(this.state == 'valid') this.state = 'expired'
		if(this.options?.autoReloadOnExpiration) this.forceReloadAndGetData() // don't wait for it
	}

	private cancelExpireTimeout() {
		if(this.expireTimeout) {
			clearTimeout(this.expireTimeout)
			this.expireTimeout = undefined
		}
	}

	private async reload() {
		this.state = 'loading'
		const newDataPromiseWr = Promise.withResolvers<T>()
		if(!this.dataPromise) this.dataPromise = newDataPromiseWr.promise
		
		const lock = await this.semaphore.obtainLock({ forContext: 'DataLoader.reload' })
		try {
			this.cancelExpireTimeout()

			this.dataPromise = this.loadFn()
			this.dataPromise.then(newDataPromiseWr.resolve, newDataPromiseWr.reject)

			this.data = await this.dataPromise
			// console.log('DataLoader.reloaded', JSON.stringify((this.data as any)?.[0]))
			this.state = this.options?.doCache ? 'valid' : 'expired'
	
			if(this.options?.expireAfterMs) this.expireTimeout = setTimeout(() => this.markExpired(), this.options.expireAfterMs)

			this.onLoadedCallback?.(this.data)
			return this.data
		} finally {
			lock.release()
		}
	}
}
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__ArScheduleEvent {
    @jsonMember(() => PurchaseToPay__ArScheduleEvent.Id)
    id: PurchaseToPay__ArScheduleEvent.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "name", "summary", "commChannel", "commChannel$id", "dueDate", "type", "type$id", "eventLog", "emailTemplate", "schedule", "scheduleId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "name": "string", "summary": "string", "commChannel": "PurchaseToPay.ArCommunicationChannel", "commChannel$id": "string", "dueDate": "Date", "type": "PurchaseToPay.ArScheduleEventType", "type$id": "string", "eventLog": "PurchaseToPay.ArScheduleEventLog[]", "emailTemplate": "PurchaseToPay.ArEmailTemplate[]", "schedule": "PurchaseToPay.ArSchedule", "scheduleId": "PurchaseToPay.ArSchedule.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["commChannel", "type"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ schedule: 'PurchaseToPay.ArSchedule' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ eventLog: 'PurchaseToPay.ArScheduleEventLog', emailTemplate: 'PurchaseToPay.ArEmailTemplate' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θname?: string = undefined;
    private θsummary?: string = undefined;
    private θcommChannel?: This.ArCommunicationChannel = new This.ArCommunicationChannel();
    private θdueDate?: Date = undefined;
    private θtype?: This.ArScheduleEventType = new This.ArScheduleEventType();
    private θeventLog?: PurchaseToPay.ArScheduleEventLog[] = undefined;
    private θemailTemplate?: PurchaseToPay.ArEmailTemplate[] = undefined;
    private θschedule?: PurchaseToPay.ArSchedule = undefined;
    private θscheduleId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        name: 0,
        summary: 0,
        dueDate: 0,
        eventLog: 0,
        emailTemplate: 0,
        schedule: 0,
        scheduleId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        name: () => {
            return false
        }
        , summary: () => {
            return false
        }
        , dueDate: () => {
            return false
        }
        , eventLog: () => {
            return false
        }
        , emailTemplate: () => {
            return false
        }
        , schedule: () => {
            return false
        }
        , scheduleId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.ArScheduleEvent' {
        return 'PurchaseToPay.ArScheduleEvent'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.ArScheduleEvent' {
        return 'PurchaseToPay.ArScheduleEvent'
    }

    static get __baseType(): 'PurchaseToPay.ArScheduleEvent' {
        return 'PurchaseToPay.ArScheduleEvent'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.ArScheduleEvent' {
        return 'PurchaseToPay.ArScheduleEvent'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__ArScheduleEvent[EntityInfoSymbol]
    }

    get name() {
        return this.θname
    }

    set name(name: string) {
        this.θname = LowgileDataUtil.coerceDataType(name, 'string', false, true)
    }

    get summary() {
        return this.θsummary
    }

    set summary(summary: string) {
        this.θsummary = LowgileDataUtil.coerceDataType(summary, 'string', false, true)
    }

    get commChannel(): This.ArCommunicationChannel {
        return this.θcommChannel
    }

    set commChannel(commChannel: This.ArCommunicationChannel | This.ArCommunicationChannel.Id) {
        if (!this.θcommChannel) this.θcommChannel = new This.ArCommunicationChannel()
        this.θcommChannel.θupdate(commChannel)
    }

    @jsonMember(String)
    get 'commChannel$id'(): string {
        return this.θcommChannel?.id
    }

    set 'commChannel$id'(commChannel: string) {
        if (!this.θcommChannel) this.θcommChannel = new This.ArCommunicationChannel()
        this.θcommChannel.id = commChannel as any
    }

    get dueDate() {
        return this.θdueDate
    }

    set dueDate(dueDate: Date) {
        this.θdueDate = LowgileDataUtil.coerceDataType(dueDate, 'Date', false, true)
    }

    get type(): This.ArScheduleEventType {
        return this.θtype
    }

    set type(type: This.ArScheduleEventType | This.ArScheduleEventType.Id) {
        if (!this.θtype) this.θtype = new This.ArScheduleEventType()
        this.θtype.θupdate(type)
    }

    @jsonMember(String)
    get 'type$id'(): string {
        return this.θtype?.id
    }

    set 'type$id'(type: string) {
        if (!this.θtype) this.θtype = new This.ArScheduleEventType()
        this.θtype.id = type as any
    }

    get eventLog() {
        return this.θeventLog
    }

    set eventLog(eventLog: PurchaseToPay.ArScheduleEventLog[]) {
        this.θeventLog = LowgileDataUtil.coerceDataType(eventLog, PurchaseToPay.ArScheduleEventLog, true, true)
    }

    get emailTemplate() {
        return this.θemailTemplate
    }

    set emailTemplate(emailTemplate: PurchaseToPay.ArEmailTemplate[]) {
        this.θemailTemplate = LowgileDataUtil.coerceDataType(emailTemplate, PurchaseToPay.ArEmailTemplate, true, true)
    }

    get schedule() {
        return this.θschedule
    }

    set schedule(schedule: PurchaseToPay.ArSchedule) {
        this.θschedule = LowgileDataUtil.coerceDataType(schedule, PurchaseToPay.ArSchedule, false, true)
    }

    get scheduleId() {
        return this.θscheduleId
    }

    set scheduleId(scheduleId: number) {
        this.θscheduleId = LowgileDataUtil.coerceDataType(scheduleId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__ArScheduleEvent.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__ArScheduleEvent>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__ArScheduleEvent(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__ArScheduleEvent>, restrictToClass?: Class<Partial<PurchaseToPay__ArScheduleEvent>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('name', 'summary', 'commChannel$id', 'dueDate', 'type$id', 'eventLog', 'emailTemplate', 'schedule', 'scheduleId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__ArScheduleEvent { }

export import ArScheduleEvent = PurchaseToPay__ArScheduleEvent
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__ArScheduleEvent.prototype, 'name')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEvent.prototype, 'summary')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEvent.prototype, 'dueDate')
    jsonArrayMember(() => PurchaseToPay.ArScheduleEventLog)(PurchaseToPay__ArScheduleEvent.prototype, 'eventLog')
    jsonArrayMember(() => PurchaseToPay.ArEmailTemplate)(PurchaseToPay__ArScheduleEvent.prototype, 'emailTemplate')
    jsonMember(() => PurchaseToPay.ArSchedule)(PurchaseToPay__ArScheduleEvent.prototype, 'schedule')
    jsonMember(() => Number)(PurchaseToPay__ArScheduleEvent.prototype, 'scheduleId')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEvent.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEvent.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEvent.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEvent.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__ArScheduleEvent.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__ArScheduleEvent.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__ArScheduleEvent.prototype, 'versionNumber')
})

namespace PurchaseToPay__ArScheduleEvent {
}

export const ArScheduleEventθId = Number;

export type ArScheduleEventθId = number;

namespace PurchaseToPay__ArScheduleEvent {
    export type Id = NominalType<number, 'PurchaseToPay.ArScheduleEvent'>
    export const Id = Number
}

// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    myTasks: Sys.Types.UserTaskToken[];
    teamTasks: Sys.Types.UserTaskToken[];
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-PortalWorklist',
    templateUrl: './PortalWorklist.html',
    styleUrls: ['./PortalWorklist.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalWorklist extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.PortalWorklist';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_8 = (screen: Properties) => ['taskName', 'requestNumber', 'requestedItems', 'requestor'];
    _DataTableBlock_columns_11 = (screen: Properties) => ['taskName', 'requestNumber', 'requestedItems', 'requestor'];
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        myTasks: this.myTasks !== undefined ? this.myTasks : ([]) as Sys.Types.UserTaskToken[],
        teamTasks: this.teamTasks !== undefined ? this.teamTasks : ([]) as Sys.Types.UserTaskToken[]
    });

    get $inputs() {
        return {

        }
    }

    get myTasks() {
        return this.screen?.myTasks
    }

    set myTasks(myTasks: Sys.Types.UserTaskToken[]) {
        myTasks = (myTasks as any)?.[ProxyTargetSymbol] ?? myTasks
        this.storeService.setProperty([this.componentStore.name], 'myTasks', myTasks)
    }

    get teamTasks() {
        return this.screen?.teamTasks
    }

    set teamTasks(teamTasks: Sys.Types.UserTaskToken[]) {
        teamTasks = (teamTasks as any)?.[ProxyTargetSymbol] ?? teamTasks
        this.storeService.setProperty([this.componentStore.name], 'teamTasks', teamTasks)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.myTasks = []
                θstate.screen.teamTasks = []

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_DataTableBlock_1_rowClick_onTaskClick_0($event: any, $scope: any): Promise<void> {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let item: Sys.Types.UserTaskToken = $event[1], index: number = $event[2]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onTaskClick(item, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_8($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.myTasks)
    }

    _DataTableColumnBlock_cellText_9_9($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.taskName)
    }

    _DataTableColumnBlock_cellText_14_14($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestNumber ?? '')
    }

    _DataTableColumnBlock_cellText_17_17($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestedItems ?? '')
    }

    _DataTableColumnBlock_cellText_15_15($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestor ?? '')
    }

    async handler_DataTableBlock_3_rowClick_onTaskClick_0($event: any, $scope: any): Promise<void> {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let item: Sys.Types.UserTaskToken = $event[1], index: number = $event[2]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onTaskClick(item, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_11($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.teamTasks)
    }

    _DataTableColumnBlock_cellText_12_12($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.taskName)
    }

    _DataTableColumnBlock_cellText_18_18($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestNumber ?? '')
    }

    _DataTableColumnBlock_cellText_13_13($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestedItems ?? '')
    }

    _DataTableColumnBlock_cellText_16_16($scope: any): any {
        let { myTasks, teamTasks, app, screen, $pathSegments, $disabled, item, index }: { myTasks: Sys.Types.UserTaskToken[], teamTasks: Sys.Types.UserTaskToken[], app: any, screen: any, $pathSegments: string[], $disabled: boolean, item: Sys.Types.UserTaskToken, index: number } = $scope ?? {}

        return (item.publicMetadata.requestor ?? '')
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.myTasks = await Sys.Process.getAllTasksForUser({ includeUserAllowed: true, includeAssigned: true })
            screen.teamTasks = await Sys.Process.getAllTasksForUser({ includeRoleAllowed: true })
        }
    }

    async action_onTaskClick(selectedTask: Sys.Types.UserTaskToken, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        selectedTask = this.storeService.replaceWithStateVersion(selectedTask, $context)
        {
            await Sys.Process.navigateToTask(selectedTask, false)
        }
    }
}

// Auto-generated by Lowgile
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/System";
import * as System from "../../Module/System";
import "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { ResourceDefinition } from "@shared/types";

export class $Resources {
}

// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseRequest {
    @jsonMember(() => PurchaseToPay__PurchaseRequest.Id)
    id: PurchaseToPay__PurchaseRequest.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "requestorUserId", "requestorName", "evaluationComment", "requestNumber", "requestDate", "billingAddress", "deliveryAddress", "requestStatus", "requestStatus$id", "processInstanceId", "itemSummary", "items", "linkedToRequest", "linkedToRequestId", "vendorOffers", "evaluationAttachments", "approvals", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber", "previewText", "totalBudgetInChf"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "requestorUserId": "string", "requestorName": "string", "evaluationComment": "string", "requestNumber": "string", "requestDate": "Date", "billingAddress": "string", "deliveryAddress": "string", "requestStatus": "PurchaseToPay.PurchaseRequestStatus", "requestStatus$id": "string", "processInstanceId": "number", "itemSummary": "string", "items": "PurchaseToPay.PurchaseRequestItem[]", "linkedToRequest": "PurchaseToPay.PurchaseRequest", "linkedToRequestId": "PurchaseToPay.PurchaseRequest.Id", "vendorOffers": "PurchaseToPay.PurchaseVendorOffer[]", "evaluationAttachments": "PurchaseToPay.PurchaseRequestEvaluationAttachment[]", "approvals": "PurchaseToPay.PurchaseRequestApproval[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "previewText": "string", "totalBudgetInChf": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["requestStatus"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "requestorName", "requestNumber", "requestDate", "requestStatus", "itemSummary"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ linkedToRequest: 'PurchaseToPay.PurchaseRequest' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ items: 'PurchaseToPay.PurchaseRequestItem', vendorOffers: 'PurchaseToPay.PurchaseVendorOffer', evaluationAttachments: 'PurchaseToPay.PurchaseRequestEvaluationAttachment', approvals: 'PurchaseToPay.PurchaseRequestApproval' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θrequestorUserId?: string = undefined;
    private θrequestorName?: string = undefined;
    private θevaluationComment?: string = undefined;
    private θrequestNumber?: string = undefined;
    private θrequestDate?: Date = new Date;
    private θbillingAddress?: string = undefined;
    private θdeliveryAddress?: string = undefined;
    private θrequestStatus?: This.PurchaseRequestStatus = This.PurchaseRequestStatus.Unsubmitted;
    private θprocessInstanceId?: number = undefined;
    private θitemSummary?: string = undefined;
    private θitems?: PurchaseToPay.PurchaseRequestItem[] = undefined;
    private θlinkedToRequest?: PurchaseToPay.PurchaseRequest = undefined;
    private θlinkedToRequestId?: number = null;
    private θvendorOffers?: PurchaseToPay.PurchaseVendorOffer[] = undefined;
    private θevaluationAttachments?: PurchaseToPay.PurchaseRequestEvaluationAttachment[] = undefined;
    private θapprovals?: PurchaseToPay.PurchaseRequestApproval[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        requestorUserId: 0,
        requestorName: 0,
        evaluationComment: 0,
        requestNumber: 0,
        requestDate: 0,
        billingAddress: 0,
        deliveryAddress: 0,
        processInstanceId: 0,
        itemSummary: 0,
        items: 0,
        linkedToRequest: 0,
        linkedToRequestId: 0,
        vendorOffers: 0,
        evaluationAttachments: 0,
        approvals: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        requestorUserId: () => {
            return false
        }
        , requestorName: () => {
            return false
        }
        , evaluationComment: () => {
            return false
        }
        , requestNumber: () => {
            return false
        }
        , requestDate: () => {
            return false
        }
        , billingAddress: () => {
            return false
        }
        , deliveryAddress: () => {
            return false
        }
        , processInstanceId: () => {
            return false
        }
        , itemSummary: () => {
            return false
        }
        , items: () => {
            return false
        }
        , linkedToRequest: () => {
            return false
        }
        , linkedToRequestId: () => {
            return false
        }
        , vendorOffers: () => {
            return false
        }
        , evaluationAttachments: () => {
            return false
        }
        , approvals: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseRequest' {
        return 'PurchaseToPay.PurchaseRequest'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseRequest' {
        return 'PurchaseToPay.PurchaseRequest'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseRequest' {
        return 'PurchaseToPay.PurchaseRequest'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseRequest' {
        return 'PurchaseToPay.PurchaseRequest'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseRequest[EntityInfoSymbol]
    }

    get requestorUserId() {
        return this.θrequestorUserId
    }

    set requestorUserId(requestorUserId: string) {
        this.θrequestorUserId = LowgileDataUtil.coerceDataType(requestorUserId, 'string', false, true)
    }

    get requestorName() {
        return this.θrequestorName
    }

    set requestorName(requestorName: string) {
        this.θrequestorName = LowgileDataUtil.coerceDataType(requestorName, 'string', false, true)
    }

    get evaluationComment() {
        return this.θevaluationComment
    }

    set evaluationComment(evaluationComment: string) {
        this.θevaluationComment = LowgileDataUtil.coerceDataType(evaluationComment, 'string', false, true)
    }

    get requestNumber() {
        return this.θrequestNumber
    }

    set requestNumber(requestNumber: string) {
        this.θrequestNumber = LowgileDataUtil.coerceDataType(requestNumber, 'string', false, true)
    }

    get requestDate() {
        return this.θrequestDate
    }

    set requestDate(requestDate: Date) {
        this.θrequestDate = LowgileDataUtil.coerceDataType(requestDate, 'Date', false, true)
    }

    get billingAddress() {
        return this.θbillingAddress
    }

    set billingAddress(billingAddress: string) {
        this.θbillingAddress = LowgileDataUtil.coerceDataType(billingAddress, 'string', false, true)
    }

    get deliveryAddress() {
        return this.θdeliveryAddress
    }

    set deliveryAddress(deliveryAddress: string) {
        this.θdeliveryAddress = LowgileDataUtil.coerceDataType(deliveryAddress, 'string', false, true)
    }

    get requestStatus(): This.PurchaseRequestStatus {
        return this.θrequestStatus
    }

    set requestStatus(requestStatus: This.PurchaseRequestStatus | This.PurchaseRequestStatus.Id) {
        if (!this.θrequestStatus) this.θrequestStatus = new This.PurchaseRequestStatus()
        this.θrequestStatus.θupdate(requestStatus)
    }

    @jsonMember(String)
    get 'requestStatus$id'(): string {
        return this.θrequestStatus?.id
    }

    set 'requestStatus$id'(requestStatus: string) {
        if (!this.θrequestStatus) this.θrequestStatus = This.PurchaseRequestStatus.Unsubmitted
        this.θrequestStatus.id = requestStatus as any
    }

    get processInstanceId() {
        return this.θprocessInstanceId
    }

    set processInstanceId(processInstanceId: number) {
        this.θprocessInstanceId = LowgileDataUtil.coerceDataType(processInstanceId, 'number', false, true)
    }

    get itemSummary() {
        return this.θitemSummary
    }

    set itemSummary(itemSummary: string) {
        this.θitemSummary = LowgileDataUtil.coerceDataType(itemSummary, 'string', false, true)
    }

    get items() {
        return this.θitems
    }

    set items(items: PurchaseToPay.PurchaseRequestItem[]) {
        this.θitems = LowgileDataUtil.coerceDataType(items, PurchaseToPay.PurchaseRequestItem, true, true)
    }

    get linkedToRequest() {
        return this.θlinkedToRequest
    }

    set linkedToRequest(linkedToRequest: PurchaseToPay.PurchaseRequest) {
        this.θlinkedToRequest = LowgileDataUtil.coerceDataType(linkedToRequest, PurchaseToPay.PurchaseRequest, false, true)
    }

    get linkedToRequestId() {
        return this.θlinkedToRequestId
    }

    set linkedToRequestId(linkedToRequestId: number) {
        this.θlinkedToRequestId = LowgileDataUtil.coerceDataType(linkedToRequestId, 'number', false, true)
    }

    get vendorOffers() {
        return this.θvendorOffers
    }

    set vendorOffers(vendorOffers: PurchaseToPay.PurchaseVendorOffer[]) {
        this.θvendorOffers = LowgileDataUtil.coerceDataType(vendorOffers, PurchaseToPay.PurchaseVendorOffer, true, true)
    }

    get evaluationAttachments() {
        return this.θevaluationAttachments
    }

    set evaluationAttachments(evaluationAttachments: PurchaseToPay.PurchaseRequestEvaluationAttachment[]) {
        this.θevaluationAttachments = LowgileDataUtil.coerceDataType(evaluationAttachments, PurchaseToPay.PurchaseRequestEvaluationAttachment, true, true)
    }

    get approvals() {
        return this.θapprovals
    }

    set approvals(approvals: PurchaseToPay.PurchaseRequestApproval[]) {
        this.θapprovals = LowgileDataUtil.coerceDataType(approvals, PurchaseToPay.PurchaseRequestApproval, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseRequest.θmaxLength
    }

    get previewText(): string {
        const parts = [this.requestNumber]
        if (this.items) {
            parts.push(` (${this.items.map(i => `${i.quantity} ${i.name}`).join(', ')})`)
        }
        return parts.join('')
    }

    get totalBudgetInChf(): number {
        return this.items.reduce((acc, item) => {
            return acc + (item?.quantity ?? 0) * (item?.overallBudget ?? 0) * (item?.overallBudgetCurrency.amountInChf ?? 1)
        }, 0)
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseRequest>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseRequest(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseRequest>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseRequest>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('requestorUserId', 'requestorName', 'evaluationComment', 'requestNumber', 'requestDate', 'billingAddress', 'deliveryAddress', 'requestStatus$id', 'processInstanceId', 'itemSummary', 'items', 'linkedToRequest', 'linkedToRequestId', 'vendorOffers', 'evaluationAttachments', 'approvals', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    async generateRfqText(serverStore: typeof PurchaseToPay.Db | null, vendorOffer: This.PurchaseVendorOffer): Promise<string> {
        const args = [...arguments]
        args[0] = args[0]?.__type
        const result = await ExecutionService.executeMethodAndReturnData<string>('Entity', 'PurchaseToPay', 'PurchaseRequest', this, 'generateRfqText', args)
        return result
    }

    getRequestItemTotalPrice(itemIdx: number): number | undefined {
        const offerItems = this.vendorOffers.map(offer => offer.getItemById(this.items[itemIdx].id))
        const totalPrice = offerItems.reduce((acc, item) => acc + (item?.unitPrice ?? 0) * (item?.quantityToOrder ?? 0) * (item?.currency.amountInChf ?? 1), 0)

        if (Number.isNaN(totalPrice)) return undefined
        return totalPrice
    }

    getRequestItemTotalQuantityToOrder(itemIdx: number): number {
        const offerItems = this.vendorOffers.map(offer => offer.getItemById(this.items[itemIdx].id))
        const totalQuantity = offerItems.reduce((acc, item) => acc + (item?.quantityToOrder ?? 0), 0)

        return totalQuantity
    }

    async parseIncomingOfferDocument(serverStore: typeof PurchaseToPay.Db | null, documentBase64: string, offerId: number): Promise<This.PurchaseVendorOfferItem[]> {
        const args = [...arguments]
        args[0] = args[0]?.__type
        const result = await ExecutionService.executeMethodAndReturnData<This.PurchaseVendorOfferItem[]>('Entity', 'PurchaseToPay', 'PurchaseRequest', this, 'parseIncomingOfferDocument', args)
        return result
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseRequest { }

export import PurchaseRequest = PurchaseToPay__PurchaseRequest
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'requestorUserId')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'requestorName')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'evaluationComment')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'requestNumber')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequest.prototype, 'requestDate')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'billingAddress')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'deliveryAddress')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequest.prototype, 'processInstanceId')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'itemSummary')
    jsonArrayMember(() => PurchaseToPay.PurchaseRequestItem)(PurchaseToPay__PurchaseRequest.prototype, 'items')
    jsonMember(() => PurchaseToPay.PurchaseRequest)(PurchaseToPay__PurchaseRequest.prototype, 'linkedToRequest')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequest.prototype, 'linkedToRequestId')
    jsonArrayMember(() => PurchaseToPay.PurchaseVendorOffer)(PurchaseToPay__PurchaseRequest.prototype, 'vendorOffers')
    jsonArrayMember(() => PurchaseToPay.PurchaseRequestEvaluationAttachment)(PurchaseToPay__PurchaseRequest.prototype, 'evaluationAttachments')
    jsonArrayMember(() => PurchaseToPay.PurchaseRequestApproval)(PurchaseToPay__PurchaseRequest.prototype, 'approvals')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequest.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequest.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseRequest.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseRequest.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseRequest.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseRequest {
}

export const PurchaseRequestθId = Number;

export type PurchaseRequestθId = number;

namespace PurchaseToPay__PurchaseRequest {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseRequest'>
    export const Id = Number
}

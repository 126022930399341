import 'reflect-metadata'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ChangeDetectionService } from '@ng-shared/lib/services/change-detection.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { ngZone: ChangeDetectionService.getNgZone() })
  .catch(err => console.error(err));

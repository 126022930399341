// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    areaTitle: string;
    approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[];
    isExpanded: boolean;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-IncludeApprovals',
    templateUrl: './IncludeApprovals.html',
    styleUrls: ['./IncludeApprovals.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncludeApprovals extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.IncludeApprovals';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    _DataTableBlock_columns_4 = (screen: Properties) => ['date', 'Action', 'approver', 'comment'];
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        areaTitle: this.areaTitle !== undefined ? this.areaTitle : (This.$T.approvalComments) as string,
        approvals: this.approvals !== undefined ? this.approvals : (undefined) as (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[],
        isExpanded: this.isExpanded !== undefined ? this.isExpanded : (undefined) as boolean
    });

    get $inputs() {
        return {
            areaTitle: this.areaTitle,
            approvals: this.approvals
        }
    }

    @Input()
    get areaTitle() {
        return this.screen?.areaTitle
    }

    set areaTitle(areaTitle: string) {
        areaTitle = (areaTitle as any)?.[ProxyTargetSymbol] ?? areaTitle
        this.storeService.setProperty([this.componentStore.name], 'areaTitle', areaTitle)
    }

    @Input()
    get approvals() {
        return this.screen?.approvals
    }

    set approvals(approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[]) {
        approvals = (approvals as any)?.[ProxyTargetSymbol] ?? approvals
        this.storeService.setProperty([this.componentStore.name], 'approvals', approvals)
    }

    get isExpanded() {
        return this.screen?.isExpanded
    }

    set isExpanded(isExpanded: boolean) {
        isExpanded = (isExpanded as any)?.[ProxyTargetSymbol] ?? isExpanded
        this.storeService.setProperty([this.componentStore.name], 'isExpanded', isExpanded)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.areaTitle = this.dialogData.areaTitle ?? this.areaTitle
            this.approvals = this.dialogData.approvals ?? this.approvals
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.isExpanded = undefined

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    _DataTableBlock_collectionCode_4($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.approvals)
    }

    _DataTableBlock_additionalFindOptions_4($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_5_5($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled, approval }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, approval: This.PurchaseRequestApproval } = $scope ?? {}

        return (Sys.Date.format(approval.date, 'dd.MM.yyyy HH:mm'))
    }

    _DataTableColumnBlock_cellText_6_6($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled, approval }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, approval: This.PurchaseRequestApproval } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_16_16($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled, approval }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, approval: This.PurchaseRequestApproval } = $scope ?? {}

        return (approval.userDisplayName)
    }

    _DataTableColumnBlock_cellText_17_17($scope: any): any {
        let { areaTitle, approvals, isExpanded, app, screen, $pathSegments, $disabled, approval }: { areaTitle: string, approvals: (This.PurchaseRequestApproval | This.PurchaseOrderApproval)[], isExpanded: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, approval: This.PurchaseRequestApproval } = $scope ?? {}

        return (approval.comment)
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.isExpanded = screen.approvals.some(a => !a.isSeen)
            screen.approvals.forEach(a => a.isSeen = true)
        }
    }
}

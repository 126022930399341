import { BoTypeSymbol } from "@shared/types";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

export class DefaultDeliveryAddress {
    static readonly [BoTypeSymbol] = 'UserDefinedConfiguration';
    static readonly __type = 'PurchaseToPay.DefaultDeliveryAddress';
    private static value?: DefaultDeliveryAddress.ValueType;

    static async get(): Promise<DefaultDeliveryAddress.ValueType> {
        if (this.value === undefined) {
            this.value = await ExecutionService.executeMethodAndReturnData<DefaultDeliveryAddress.ValueType>('UserDefinedConfiguration', 'PurchaseToPay', 'DefaultDeliveryAddress', this, 'get', [])
        }

        return this.value
    }

    static async set(value: DefaultDeliveryAddress.ValueType): Promise<void> {
        this.value = value
        await ExecutionService.executeMethodAndReturnData<DefaultDeliveryAddress.ValueType>('UserDefinedConfiguration', 'PurchaseToPay', 'DefaultDeliveryAddress', this, 'set', [value])
    }
}

export namespace DefaultDeliveryAddress {
    export type ValueType = string
}

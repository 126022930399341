import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Form, FormControl, FormControlDirective, FormGroup, NgControl } from '@angular/forms'
import { BoTypeSymbol, ValidationRule } from '@shared/types'
import { FormControlCache } from '../store/form-control-cache'
import { ValidationUtil } from '@ng-shared/lib/validation/validation-util'
import { ScopeDirective } from '../directives/scope.directive'
import { FormDirective } from '../directives/form.directive'
import { LowgilePathSymbol } from '../store/store-proxy-handler'

export interface FormBinding {
	object: object
	property: string
	formControl: FormControl
	isRequired: boolean
}

@Pipe({
	name: 'formBinding',
	pure: true
})
export class FormBindingPipe implements PipeTransform, OnDestroy {
	private binding?: FormBinding = undefined
	private directive?: FormControlDirective = undefined
	private forms: FormGroup[] = []
	private name = ''

	private	static nameCounter = 1

	constructor(
		private scopeDirective: ScopeDirective,
		private formDirective: FormDirective,
	) {}
		
	transform(bindingObject: object, bindingProperty: string, validationRules: ValidationRule<any>[] | undefined, $disabled: boolean): FormBinding {
		this.forms = this.formDirective?.viewRef?.context?.$implicit ?? []
		if(this.name) {
			for(const form of this.forms) form.removeControl(this.name)
		}

		const value = Reflect.get(bindingObject, bindingProperty)
		const alternativeBindingProperty = this.getAlternativeBindingPropertyOnObject(value)

		const object = alternativeBindingProperty ? value : bindingObject
		const property = alternativeBindingProperty || bindingProperty
		const validator = ValidationUtil.createAsyncValidationFunction(validationRules, this.scopeDirective)

		const formControlResult = FormControlCache.getFormControl(object, property, true, validator)
		const formControl = formControlResult.formControl
		this.binding = {
			object,
			property,
			formControl: formControl!,
			isRequired: !!validationRules?.find(rule => rule.name == 'required'),
		}

		if(formControl) {
			if($disabled) {
				formControl.disable({ onlySelf: true, emitEvent: false })
			} else {
				formControl.enable({ onlySelf: true, emitEvent: false })
			}
			this.forms = this.forms
			this.name = `fc_${FormBindingPipe.nameCounter++}`
			// this.directive = new FormControlDirective([], validator ? [validator] : [], [], null)
			// this.directive.form = formControl
			for(const form of this.forms) form.addControl(this.name, formControl)
			// console.log(this.name, 'added to', this.forms.length)
		}

		return this.binding
	}

	ngOnDestroy(): void {
		if(this.name) {
			for(const form of this.forms) form.removeControl(this.name)
		}
	}
	
	private getAlternativeBindingPropertyOnObject(value: any) {
		if(value?.[BoTypeSymbol] == 'StaticEntity') return 'id' // in case of StaticEntity
		return undefined
	}
}

// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__ArInvoice {
    @jsonMember(() => PurchaseToPay__ArInvoice.Id)
    id: PurchaseToPay__ArInvoice.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "currency", "currency$id", "amount", "invoiceDate", "dueDate", "status", "status$id", "debtor", "debtorId", "schedule", "scheduleId", "dispute", "disputeId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "currency": "PurchaseToPay.Currency", "currency$id": "string", "amount": "number", "invoiceDate": "Date", "dueDate": "Date", "status": "PurchaseToPay.ArInvoiceStatus", "status$id": "string", "debtor": "PurchaseToPay.ArDebtor", "debtorId": "PurchaseToPay.ArDebtor.Id", "schedule": "PurchaseToPay.ArSchedule", "scheduleId": "PurchaseToPay.ArSchedule.Id", "dispute": "PurchaseToPay.ArDispute", "disputeId": "PurchaseToPay.ArDispute.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["currency", "status"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ debtor: 'PurchaseToPay.ArDebtor' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ schedule: 'PurchaseToPay.ArSchedule', dispute: 'PurchaseToPay.ArDispute' } as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θcurrency?: This.Currency = This.Currency.CHF;
    private θamount?: number = undefined;
    private θinvoiceDate?: Date = undefined;
    private θdueDate?: Date = undefined;
    private θstatus?: This.ArInvoiceStatus = new This.ArInvoiceStatus();
    private θdebtor?: PurchaseToPay.ArDebtor = undefined;
    private θdebtorId?: number = null;
    private θschedule?: PurchaseToPay.ArSchedule = undefined;
    private θscheduleId?: number = null;
    private θdispute?: PurchaseToPay.ArDispute = undefined;
    private θdisputeId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        amount: 0,
        invoiceDate: 0,
        dueDate: 0,
        debtor: 0,
        debtorId: 0,
        schedule: 0,
        scheduleId: 0,
        dispute: 0,
        disputeId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        amount: () => {
            return false
        }
        , invoiceDate: () => {
            return false
        }
        , dueDate: () => {
            return false
        }
        , debtor: () => {
            return false
        }
        , debtorId: () => {
            return false
        }
        , schedule: () => {
            return false
        }
        , scheduleId: () => {
            return false
        }
        , dispute: () => {
            return false
        }
        , disputeId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.ArInvoice' {
        return 'PurchaseToPay.ArInvoice'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.ArInvoice' {
        return 'PurchaseToPay.ArInvoice'
    }

    static get __baseType(): 'PurchaseToPay.ArInvoice' {
        return 'PurchaseToPay.ArInvoice'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.ArInvoice' {
        return 'PurchaseToPay.ArInvoice'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__ArInvoice[EntityInfoSymbol]
    }

    get currency(): This.Currency {
        return this.θcurrency
    }

    set currency(currency: This.Currency | This.Currency.Id) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.θupdate(currency)
    }

    @jsonMember(String)
    get 'currency$id'(): string {
        return this.θcurrency?.id
    }

    set 'currency$id'(currency: string) {
        if (!this.θcurrency) this.θcurrency = This.Currency.CHF
        this.θcurrency.id = currency as any
    }

    get amount() {
        return this.θamount
    }

    set amount(amount: number) {
        this.θamount = LowgileDataUtil.coerceDataType(amount, 'number', false, true)
    }

    get invoiceDate() {
        return this.θinvoiceDate
    }

    set invoiceDate(invoiceDate: Date) {
        this.θinvoiceDate = LowgileDataUtil.coerceDataType(invoiceDate, 'Date', false, true)
    }

    get dueDate() {
        return this.θdueDate
    }

    set dueDate(dueDate: Date) {
        this.θdueDate = LowgileDataUtil.coerceDataType(dueDate, 'Date', false, true)
    }

    get status(): This.ArInvoiceStatus {
        return this.θstatus
    }

    set status(status: This.ArInvoiceStatus | This.ArInvoiceStatus.Id) {
        if (!this.θstatus) this.θstatus = new This.ArInvoiceStatus()
        this.θstatus.θupdate(status)
    }

    @jsonMember(String)
    get 'status$id'(): string {
        return this.θstatus?.id
    }

    set 'status$id'(status: string) {
        if (!this.θstatus) this.θstatus = new This.ArInvoiceStatus()
        this.θstatus.id = status as any
    }

    get debtor() {
        return this.θdebtor
    }

    set debtor(debtor: PurchaseToPay.ArDebtor) {
        this.θdebtor = LowgileDataUtil.coerceDataType(debtor, PurchaseToPay.ArDebtor, false, true)
    }

    get debtorId() {
        return this.θdebtorId
    }

    set debtorId(debtorId: number) {
        this.θdebtorId = LowgileDataUtil.coerceDataType(debtorId, 'number', false, true)
    }

    get schedule() {
        return this.θschedule
    }

    set schedule(schedule: PurchaseToPay.ArSchedule) {
        this.θschedule = LowgileDataUtil.coerceDataType(schedule, PurchaseToPay.ArSchedule, false, true)
    }

    get scheduleId() {
        return this.θscheduleId
    }

    set scheduleId(scheduleId: number) {
        this.θscheduleId = LowgileDataUtil.coerceDataType(scheduleId, 'number', false, true)
    }

    get dispute() {
        return this.θdispute
    }

    set dispute(dispute: PurchaseToPay.ArDispute) {
        this.θdispute = LowgileDataUtil.coerceDataType(dispute, PurchaseToPay.ArDispute, false, true)
    }

    get disputeId() {
        return this.θdisputeId
    }

    set disputeId(disputeId: number) {
        this.θdisputeId = LowgileDataUtil.coerceDataType(disputeId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__ArInvoice.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__ArInvoice>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__ArInvoice(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__ArInvoice>, restrictToClass?: Class<Partial<PurchaseToPay__ArInvoice>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('currency$id', 'amount', 'invoiceDate', 'dueDate', 'status$id', 'debtor', 'debtorId', 'schedule', 'scheduleId', 'dispute', 'disputeId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__ArInvoice { }

export import ArInvoice = PurchaseToPay__ArInvoice
setTimeout(() => {
    jsonMember(() => Number)(PurchaseToPay__ArInvoice.prototype, 'amount')
    jsonMember(() => Date)(PurchaseToPay__ArInvoice.prototype, 'invoiceDate')
    jsonMember(() => Date)(PurchaseToPay__ArInvoice.prototype, 'dueDate')
    jsonMember(() => PurchaseToPay.ArDebtor)(PurchaseToPay__ArInvoice.prototype, 'debtor')
    jsonMember(() => Number)(PurchaseToPay__ArInvoice.prototype, 'debtorId')
    jsonMember(() => PurchaseToPay.ArSchedule)(PurchaseToPay__ArInvoice.prototype, 'schedule')
    jsonMember(() => Number)(PurchaseToPay__ArInvoice.prototype, 'scheduleId')
    jsonMember(() => PurchaseToPay.ArDispute)(PurchaseToPay__ArInvoice.prototype, 'dispute')
    jsonMember(() => Number)(PurchaseToPay__ArInvoice.prototype, 'disputeId')
    jsonMember(() => Date)(PurchaseToPay__ArInvoice.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__ArInvoice.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__ArInvoice.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__ArInvoice.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__ArInvoice.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__ArInvoice.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__ArInvoice.prototype, 'versionNumber')
})

namespace PurchaseToPay__ArInvoice {
}

export const ArInvoiceθId = Number;

export type ArInvoiceθId = number;

namespace PurchaseToPay__ArInvoice {
    export type Id = NominalType<number, 'PurchaseToPay.ArInvoice'>
    export const Id = Number
}

import { BoTypeSymbol } from "@shared/types";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/System";
import * as System from "../../Module/System";
import "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

export class UserLanguage {
    static readonly [BoTypeSymbol] = 'UserDefinedConfiguration';
    static readonly __type = 'System.UserLanguage';
    private static value?: UserLanguage.ValueType;

    static async get(): Promise<UserLanguage.ValueType> {
        if (this.value === undefined) {
            this.value = await ExecutionService.executeMethodAndReturnData<UserLanguage.ValueType>('UserDefinedConfiguration', 'System', 'UserLanguage', this, 'get', [])
        }

        return this.value
    }

    static async set(value: UserLanguage.ValueType): Promise<void> {
        this.value = value
        await ExecutionService.executeMethodAndReturnData<UserLanguage.ValueType>('UserDefinedConfiguration', 'System', 'UserLanguage', this, 'set', [value])
    }
}

export namespace UserLanguage {
    export type ValueType = string
}

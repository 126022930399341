// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    request: This.PurchaseRequest;
    approval: This.PurchaseRequisitionApproval;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-ApprovePurchaseRequisition',
    templateUrl: './ApprovePurchaseRequisition.html',
    styleUrls: ['./ApprovePurchaseRequisition.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovePurchaseRequisition extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.ApprovePurchaseRequisition';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    @Output()
    submit = new EventEmitter<This.PurchaseRequisitionApproval>();
    $output = {
        submit: async (data: This.PurchaseRequisitionApproval) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('submit', data)
            this.submit.emit(data)
        },
    };
    _DataTableBlock_columns_173 = (screen: Properties) => ['item', ...((() => { return (screen.request.vendorOffers) })() ?? []).flatMap((offer, offerIdx) => [
        'offer_' + offerIdx
    ]), 'total'];
    $validators: Record<number, ValidationRule<any>[]> = {
        177: [
            async ($value: any, $scope: any) => {
                const { request, approval, app, screen, $pathSegments, $disabled, form, item, itemIdx, offer, offerIdx } = $scope
                if (offer.itemsOffered?.[itemIdx].allow) return undefined
                if (!parseInt($value)) return undefined
                return {
                    error: 'Item is not allowed',
                    ruleId: 'notAllowed',
                    skipSubsequentRules: true,
                }
            },
            async ($value: any, $scope: any) => {
                const { request, approval, app, screen, $pathSegments, $disabled, form, item, itemIdx, offer, offerIdx } = $scope

                const $config = (offer.itemsOffered?.[itemIdx].quantityOffered) as any
                let valid = false
                if (typeof $value == 'number' || $value instanceof Date) {
                    valid = $value <= $config
                } else {
                    valid = !$value || $value.toString().length <= $config
                }

                if (valid) return undefined
                return {
                    error: `Only ${$config} item${$config != 1 ? 's were' : ' was'} offered`,
                    ruleId: 'maximum',
                }

            }
        ]
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        request: this.request !== undefined ? this.request : (undefined) as This.PurchaseRequest,
        approval: this.approval !== undefined ? this.approval : (new This.PurchaseRequisitionApproval) as This.PurchaseRequisitionApproval
    });

    get $inputs() {
        return {
            request: this.request
        }
    }

    @Input()
    get request() {
        return this.screen?.request
    }

    set request(request: This.PurchaseRequest) {
        request = (request as any)?.[ProxyTargetSymbol] ?? request
        this.storeService.setProperty([this.componentStore.name], 'request', request)
    }

    get approval() {
        return this.screen?.approval
    }

    set approval(approval: This.PurchaseRequisitionApproval) {
        approval = (approval as any)?.[ProxyTargetSymbol] ?? approval
        this.storeService.setProperty([this.componentStore.name], 'approval', approval)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.request = this.dialogData.request ?? this.request
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.approval = new This.PurchaseRequisitionApproval

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    _DataTableBlock_collectionCode_173($scope: any): any {
        let { request, approval, app, screen, $pathSegments, $disabled, form }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}

        return (screen.request.items)
    }

    _DataTableColumnBlock_cellText_174_174($scope: any): any {
        let { request, approval, app, screen, $pathSegments, $disabled, form, item, itemIdx }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_176_176($scope: any): any {
        let { request, approval, app, screen, $pathSegments, $disabled, form, item, itemIdx }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_197_197($scope: any): any {
        let { request, approval, app, screen, $pathSegments, $disabled, form, item, itemIdx }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form, item: This.PurchaseRequestItem, itemIdx: number } = $scope ?? {}

        return (screen.request.getRequestItemTotalPrice(itemIdx))
    }

    async handler_ButtonBlock_0_0_0_0_2_0_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { request, approval, app, screen, $pathSegments, $disabled, form }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit(true, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_0_2_1_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { request, approval, app, screen, $pathSegments, $disabled, form }: { request: This.PurchaseRequest, approval: This.PurchaseRequisitionApproval, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit(false, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_submit(isApproved: boolean, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        isApproved = this.storeService.replaceWithStateVersion(isApproved, $context)
        {
            screen.approval.isApproved = isApproved
            output.submit(screen.approval)
        }
    }
}

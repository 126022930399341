// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseVendorOfferAttachment {
    @jsonMember(() => PurchaseToPay__PurchaseVendorOfferAttachment.Id)
    id: PurchaseToPay__PurchaseVendorOfferAttachment.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "content", "fileName", "uploadedOn", "source", "vendorOffer", "vendorOfferId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "content": "string", "fileName": "string", "uploadedOn": "Date", "source": "string", "vendorOffer": "PurchaseToPay.PurchaseVendorOffer", "vendorOfferId": "PurchaseToPay.PurchaseVendorOffer.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ vendorOffer: 'PurchaseToPay.PurchaseVendorOffer' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θcontent?: string = undefined;
    private θfileName?: string = undefined;
    private θuploadedOn?: Date = undefined;
    private θsource?: string = undefined;
    private θvendorOffer?: PurchaseToPay.PurchaseVendorOffer = undefined;
    private θvendorOfferId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        content: 0,
        fileName: 0,
        uploadedOn: 0,
        source: 0,
        vendorOffer: 0,
        vendorOfferId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        content: () => {
            return false
        }
        , fileName: () => {
            return false
        }
        , uploadedOn: () => {
            return false
        }
        , source: () => {
            return false
        }
        , vendorOffer: () => {
            return false
        }
        , vendorOfferId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseVendorOfferAttachment' {
        return 'PurchaseToPay.PurchaseVendorOfferAttachment'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseVendorOfferAttachment' {
        return 'PurchaseToPay.PurchaseVendorOfferAttachment'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseVendorOfferAttachment' {
        return 'PurchaseToPay.PurchaseVendorOfferAttachment'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseVendorOfferAttachment' {
        return 'PurchaseToPay.PurchaseVendorOfferAttachment'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseVendorOfferAttachment[EntityInfoSymbol]
    }

    get content() {
        return this.θcontent
    }

    set content(content: string) {
        this.θcontent = LowgileDataUtil.coerceDataType(content, 'string', false, true)
    }

    get fileName() {
        return this.θfileName
    }

    set fileName(fileName: string) {
        this.θfileName = LowgileDataUtil.coerceDataType(fileName, 'string', false, true)
    }

    get uploadedOn() {
        return this.θuploadedOn
    }

    set uploadedOn(uploadedOn: Date) {
        this.θuploadedOn = LowgileDataUtil.coerceDataType(uploadedOn, 'Date', false, true)
    }

    get source() {
        return this.θsource
    }

    set source(source: string) {
        this.θsource = LowgileDataUtil.coerceDataType(source, 'string', false, true)
    }

    get vendorOffer() {
        return this.θvendorOffer
    }

    set vendorOffer(vendorOffer: PurchaseToPay.PurchaseVendorOffer) {
        this.θvendorOffer = LowgileDataUtil.coerceDataType(vendorOffer, PurchaseToPay.PurchaseVendorOffer, false, true)
    }

    get vendorOfferId() {
        return this.θvendorOfferId
    }

    set vendorOfferId(vendorOfferId: number) {
        this.θvendorOfferId = LowgileDataUtil.coerceDataType(vendorOfferId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseVendorOfferAttachment.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseVendorOfferAttachment>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseVendorOfferAttachment(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseVendorOfferAttachment>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseVendorOfferAttachment>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('content', 'fileName', 'uploadedOn', 'source', 'vendorOffer', 'vendorOfferId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseVendorOfferAttachment { }

export import PurchaseVendorOfferAttachment = PurchaseToPay__PurchaseVendorOfferAttachment
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'content')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'fileName')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'uploadedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'source')
    jsonMember(() => PurchaseToPay.PurchaseVendorOffer)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'vendorOffer')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'vendorOfferId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseVendorOfferAttachment.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseVendorOfferAttachment {
}

export const PurchaseVendorOfferAttachmentθId = Number;

export type PurchaseVendorOfferAttachmentθId = number;

namespace PurchaseToPay__PurchaseVendorOfferAttachment {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseVendorOfferAttachment'>
    export const Id = Number
}

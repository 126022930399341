// import { CurrentSystemVersion } from '@shared/system/version';
import { CommonSystemBridge } from '@shared/system/common-system-bridge';
import { CommonLanguageBridge } from '@shared/bridges/common-language-bridge';
import { CommonRateLimitingBridge } from '@shared/bridges/common-rate-limiting-bridge';
import * as Text from '@shared/bridges/common-text-bridge';
import { CommonDateBridge } from '@shared/bridges/common-date-bridge';
import * as User from '@shared/bridges/common-user-bridge'
import * as Math from '@shared/bridges/common-math-bridge'
// import { default as HttpClient } from 'axios'
import * as Ramda from 'ramda'
import * as Types from '@shared/types'
// import { AuthBridge } from './bridges/auth-bridge'
// import { DbBridge } from './bridges/db-bridge'

export {
	// CurrentSystemVersion as Version, // TODO: re-introduce this. Was removed due to problems importing JSON in frontend apps
	CommonSystemBridge as System,
	CommonLanguageBridge as Language,
	CommonRateLimitingBridge as RateLimiting,
	Math,
	Text,
	// HttpClient,
	Ramda,
	Types,
	User,
}
export let Date: CommonDateBridge // overridden
export * from '@shared/app-types'

export async function wait(timeMs: number) {
	return new Promise<void>(res => setTimeout(res, timeMs))
}
import { Setting } from './settings/setting'
import { UserRecord } from './auth'
import { BuildConfiguration } from './build/build-configuration';
import { UserTaskToken, UserTaskTokenDetails } from './types'

export const MappableSharedRootClasses = {
	UserRecord,
	Setting,
	BuildConfiguration,
	UserTaskToken,
	UserTaskTokenDetails,
} as const
import CodeBlockWriter, { Options} from 'code-block-writer'

export class AsyncCodeBlockWriter {
	private writeQueue: QueueItem[] = []
	private childPromises: Promise<void>[] = []

	constructor(private writerOptions?: Partial<Options>) {}

	writeLine(line: string) {
		this.writeQueue.push({ type: 'writeLine', text: line })
		return this
	}
	
	write(code: string) {
		this.writeQueue.push({ type: 'write', text: code })
		return this
	}
	
	block(action: (writer: AsyncCodeBlockWriter) => Promise<void>) {
		const blockWriter = new AsyncCodeBlockWriter()

		const actionPromise = action(blockWriter)
		if(actionPromise) this.childPromises.push(actionPromise)

		this.writeQueue.push({ type: 'block', blockWriter })
		return this
	}

	protected async awaitChildBlocks() {
		for(const item of this.writeQueue) {
			if(item.type == 'block') {
				this.childPromises.push(item.blockWriter.awaitChildBlocks())
			}
		}
		await Promise.all(this.childPromises)
	}

	async toString(): Promise<string> {
		await this.awaitChildBlocks()
		const writer = new CodeBlockWriter(this.writerOptions)
		this.flushToWriter(writer)
		return writer.toString()
	}

	private flushToWriter(writer: CodeBlockWriter) {
		for(const item of this.writeQueue) {
			switch(item.type) {
				case 'write':
					writer.write(item.text)
					break
				case 'writeLine':
					writer.writeLine(item.text)
					break
				case 'block':
					writer.block(() => item.blockWriter.flushToWriter(writer))
					break
			}
		}
	}
}

type QueueItem =
	| { type: 'write', text: string }
	| { type: 'writeLine', text: string }
	| { type: 'block', blockWriter: AsyncCodeBlockWriter }

import { UserIdType } from '@shared/types'
import { assignCommonProperties } from '@shared/util/data-util'


export class UserLoginToken {
	id?: number
	refreshTokenHash!: string
	userId?: UserIdType
	validUntil!: Date

	constructor(init?: Partial<UserLoginToken>) {
		assignCommonProperties(this, init)
	}
}
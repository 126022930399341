// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => Product.loadInternal(), entryList => {
        Product.θentryList = entryList
        Product.θentryMap = undefined
        Product.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class Product {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: Product.Id;
    static θids: ReadonlyArray<Product.Id> = Object.freeze(['laptop', 'monitor', 'desk', 'chair']);
    static θentryMap: Readonly<Record<Product.Id, Readonly<Product.Entry>>>;
    static θentryList: ReadonlyArray<Product.Entry>;
    static θdropdownOptionsCache = new Map<keyof Product.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.Product' {
        return 'PurchaseToPay.Product'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.Product' {
        return 'PurchaseToPay.Product'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return Product.getEntryMap()[this.id]?.label
    }

    get productGroup() {
        return Product.getEntryMap()[this.id]?.productGroup
    }

    get isLaptop() {
        return this.id == 'laptop'
    }

    static get Laptop() {
        return new this('laptop')
    }

    get isMonitor() {
        return this.id == 'monitor'
    }

    static get Monitor() {
        return new this('monitor')
    }

    get isDesk() {
        return this.id == 'desk'
    }

    static get Desk() {
        return new this('desk')
    }

    get isChair() {
        return this.id == 'chair'
    }

    static get Chair() {
        return new this('chair')
    }

    constructor(id: Product.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: Product.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof Product.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<Product.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "laptop", label: "Laptop", productGroup: PurchaseToPay.ProductGroup.ItEquipment }),
            Object.freeze({ id: "monitor", label: "Monitor", productGroup: PurchaseToPay.ProductGroup.ItEquipment }),
            Object.freeze({ id: "desk", label: "Desk", productGroup: PurchaseToPay.ProductGroup.Furniture }),
            Object.freeze({ id: "chair", label: "Chair", productGroup: PurchaseToPay.ProductGroup.Furniture })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new Product(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: Product.Id | Product.Option | Product) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.Product yet:', err.message)
            //throw err
        })
    }

    setLaptop() {
        this.id = 'laptop'

    }

    setMonitor() {
        this.id = 'monitor'

    }

    setDesk() {
        this.id = 'desk'

    }

    setChair() {
        this.id = 'chair'

    }
}

export const ProductθId = String;

export type ProductθId = string;

export namespace Product {
    export type Id = 'laptop' | 'monitor' | 'desk' | 'chair'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
        productGroup: PurchaseToPay.ProductGroup;
    }

    export enum Option {
        Laptop = 'laptop',
        Monitor = 'monitor',
        Desk = 'desk',
        Chair = 'chair'
    }
}
Sys.Language.languageChanged$.subscribe(() => Product.load(true))

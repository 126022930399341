import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BaseTrpcService } from './base-trpc.service'
import { type AppRootRouter } from '@shared/declarations/api/app-root-router'
import { CsrfService } from './csrf.service'
import { SharedJsonMapper } from '@shared/data/shared-json-mapper'

@Injectable({
  providedIn: 'root'
})
export class AppTrpcService extends BaseTrpcService<AppRootRouter> {
  constructor(
		protected injector: Injector,
		public csrfService: CsrfService,
	) {
    super(injector, csrfService, new SharedJsonMapper(false))
  }
}

export const AppTrpcServiceInjectionToken = new InjectionToken<AppTrpcService>('AppTrpcService')
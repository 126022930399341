import { Injectable } from '@angular/core'
import { ConsumerToSharedWorkerMessage, SharedWorkerToConsumerMessage, SubscribableSharedWorkerToConsumerMessage, SubscribableSharedWorkerToConsumerMessageParams, SubscribableSharedWorkerToConsumerMessagePickProperties } from '../worker/shared-worker-messages'
import { filter, firstValueFrom, Subject, switchMap } from 'rxjs'
import { AuthService } from './auth-service'

@Injectable({
	providedIn: 'root'
})
export class SharedBackgroundWorkerService {
	private worker: SharedWorker
	private messageSubject = new Subject<SharedWorkerToConsumerMessage>()
	readonly messages$ = this.messageSubject.asObservable()

	constructor(
		private authService: AuthService,
	) {}

	init(worker: SharedWorker) {
		this.worker = worker
		worker.port.onmessage = ({ data }) => {
		  this.messageSubject.next(data)
		};
		worker.port.start()

		window.addEventListener('pagehide', event => {
			this.send({ type: 'disconnect' })
			worker.port.close()
		})
	}

	on<T extends SharedWorkerToConsumerMessage['type']>(messageType: T, fn: (message: SharedWorkerToConsumerMessage & { type: T }) => void) {
		return this.on$(messageType).subscribe(fn)
	}

	on$<T extends SharedWorkerToConsumerMessage['type']>(messageType: T) {
		return this.messages$.pipe(
			filter(message => message.type == messageType),
		)
	}

	subscribe<T extends SubscribableSharedWorkerToConsumerMessage['type']>(messageType: T, params: SubscribableSharedWorkerToConsumerMessageParams<T>, pickProperties: SubscribableSharedWorkerToConsumerMessagePickProperties<T>, fn: (message: SharedWorkerToConsumerMessage & { type: T }) => void) {
		return this.subscribe$(messageType, params, pickProperties).subscribe(fn)
	}

	subscribe$<T extends SubscribableSharedWorkerToConsumerMessage['type']>(messageType: T, params: SubscribableSharedWorkerToConsumerMessageParams<T>, pickProperties: SubscribableSharedWorkerToConsumerMessagePickProperties<T>) {
		this.send({ type: 'subscribe', messageType, params, pickProperties })
		return this.on$(messageType)
	}

	send(message: ConsumerToSharedWorkerMessage) {
		this.worker.port.postMessage(message)
	}
}
// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseInvoice {
    @jsonMember(() => PurchaseToPay__PurchaseInvoice.Id)
    id: PurchaseToPay__PurchaseInvoice.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "invoiceNumber", "date", "dueDate", "fileContent", "fileName", "currency", "currency$id", "paymentStatus", "paymentStatus$id", "postingDate", "isRevoked", "items", "purchaseOrder", "purchaseOrderId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "invoiceNumber": "string", "date": "Date", "dueDate": "Date", "fileContent": "string", "fileName": "string", "currency": "PurchaseToPay.Currency", "currency$id": "string", "paymentStatus": "PurchaseToPay.PaymentStatus", "paymentStatus$id": "string", "postingDate": "Date", "isRevoked": "boolean", "items": "PurchaseToPay.PurchaseInvoiceItem[]", "purchaseOrder": "PurchaseToPay.PurchaseOrder", "purchaseOrderId": "PurchaseToPay.PurchaseOrder.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["currency", "paymentStatus"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseOrder: 'PurchaseToPay.PurchaseOrder' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ items: 'PurchaseToPay.PurchaseInvoiceItem' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θinvoiceNumber?: string = undefined;
    private θdate?: Date = undefined;
    private θdueDate?: Date = undefined;
    private θfileContent?: string = undefined;
    private θfileName?: string = undefined;
    private θcurrency?: This.Currency = This.Currency.CHF;
    private θpaymentStatus?: This.PaymentStatus = This.PaymentStatus.Open;
    private θpostingDate?: Date = undefined;
    private θisRevoked?: boolean = false;
    private θitems?: PurchaseToPay.PurchaseInvoiceItem[] = undefined;
    private θpurchaseOrder?: PurchaseToPay.PurchaseOrder = undefined;
    private θpurchaseOrderId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        invoiceNumber: 0,
        date: 0,
        dueDate: 0,
        fileContent: 0,
        fileName: 0,
        postingDate: 0,
        isRevoked: 0,
        items: 0,
        purchaseOrder: 0,
        purchaseOrderId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        invoiceNumber: () => {
            return false
        }
        , date: () => {
            return false
        }
        , dueDate: () => {
            return false
        }
        , fileContent: () => {
            return false
        }
        , fileName: () => {
            return false
        }
        , postingDate: () => {
            return false
        }
        , isRevoked: () => {
            return false
        }
        , items: () => {
            return false
        }
        , purchaseOrder: () => {
            return false
        }
        , purchaseOrderId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseInvoice' {
        return 'PurchaseToPay.PurchaseInvoice'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseInvoice' {
        return 'PurchaseToPay.PurchaseInvoice'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseInvoice' {
        return 'PurchaseToPay.PurchaseInvoice'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseInvoice' {
        return 'PurchaseToPay.PurchaseInvoice'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseInvoice[EntityInfoSymbol]
    }

    get invoiceNumber() {
        return this.θinvoiceNumber
    }

    set invoiceNumber(invoiceNumber: string) {
        this.θinvoiceNumber = LowgileDataUtil.coerceDataType(invoiceNumber, 'string', false, true)
    }

    get date() {
        return this.θdate
    }

    set date(date: Date) {
        this.θdate = LowgileDataUtil.coerceDataType(date, 'Date', false, true)
    }

    get dueDate() {
        return this.θdueDate
    }

    set dueDate(dueDate: Date) {
        this.θdueDate = LowgileDataUtil.coerceDataType(dueDate, 'Date', false, true)
    }

    get fileContent() {
        return this.θfileContent
    }

    set fileContent(fileContent: string) {
        this.θfileContent = LowgileDataUtil.coerceDataType(fileContent, 'string', false, true)
    }

    get fileName() {
        return this.θfileName
    }

    set fileName(fileName: string) {
        this.θfileName = LowgileDataUtil.coerceDataType(fileName, 'string', false, true)
    }

    get currency(): This.Currency {
        return this.θcurrency
    }

    set currency(currency: This.Currency | This.Currency.Id) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.θupdate(currency)
    }

    @jsonMember(String)
    get 'currency$id'(): string {
        return this.θcurrency?.id
    }

    set 'currency$id'(currency: string) {
        if (!this.θcurrency) this.θcurrency = This.Currency.CHF
        this.θcurrency.id = currency as any
    }

    get paymentStatus(): This.PaymentStatus {
        return this.θpaymentStatus
    }

    set paymentStatus(paymentStatus: This.PaymentStatus | This.PaymentStatus.Id) {
        if (!this.θpaymentStatus) this.θpaymentStatus = new This.PaymentStatus()
        this.θpaymentStatus.θupdate(paymentStatus)
    }

    @jsonMember(String)
    get 'paymentStatus$id'(): string {
        return this.θpaymentStatus?.id
    }

    set 'paymentStatus$id'(paymentStatus: string) {
        if (!this.θpaymentStatus) this.θpaymentStatus = This.PaymentStatus.Open
        this.θpaymentStatus.id = paymentStatus as any
    }

    get postingDate() {
        return this.θpostingDate
    }

    set postingDate(postingDate: Date) {
        this.θpostingDate = LowgileDataUtil.coerceDataType(postingDate, 'Date', false, true)
    }

    get isRevoked() {
        return this.θisRevoked
    }

    set isRevoked(isRevoked: boolean) {
        this.θisRevoked = LowgileDataUtil.coerceDataType(isRevoked, 'boolean', false, true)
    }

    get items() {
        return this.θitems
    }

    set items(items: PurchaseToPay.PurchaseInvoiceItem[]) {
        this.θitems = LowgileDataUtil.coerceDataType(items, PurchaseToPay.PurchaseInvoiceItem, true, true)
    }

    get purchaseOrder() {
        return this.θpurchaseOrder
    }

    set purchaseOrder(purchaseOrder: PurchaseToPay.PurchaseOrder) {
        this.θpurchaseOrder = LowgileDataUtil.coerceDataType(purchaseOrder, PurchaseToPay.PurchaseOrder, false, true)
    }

    get purchaseOrderId() {
        return this.θpurchaseOrderId
    }

    set purchaseOrderId(purchaseOrderId: number) {
        this.θpurchaseOrderId = LowgileDataUtil.coerceDataType(purchaseOrderId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseInvoice.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseInvoice>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseInvoice(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseInvoice>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseInvoice>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('invoiceNumber', 'date', 'dueDate', 'fileContent', 'fileName', 'currency$id', 'paymentStatus$id', 'postingDate', 'isRevoked', 'items', 'purchaseOrder', 'purchaseOrderId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    static async parseUpload(fileContent: string, po: This.PurchaseOrder): Promise<any> {
        const args = [...arguments]
        const result = await ExecutionService.executeMethodAndReturnData<any>('Entity', 'PurchaseToPay', 'PurchaseInvoice', this, 'parseUpload', args)
        return result
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseInvoice { }

export import PurchaseInvoice = PurchaseToPay__PurchaseInvoice
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'invoiceNumber')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'date')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'dueDate')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'fileContent')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'fileName')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'postingDate')
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseInvoice.prototype, 'isRevoked')
    jsonArrayMember(() => PurchaseToPay.PurchaseInvoiceItem)(PurchaseToPay__PurchaseInvoice.prototype, 'items')
    jsonMember(() => PurchaseToPay.PurchaseOrder)(PurchaseToPay__PurchaseInvoice.prototype, 'purchaseOrder')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoice.prototype, 'purchaseOrderId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoice.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoice.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoice.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseInvoice {
}

export const PurchaseInvoiceθId = Number;

export type PurchaseInvoiceθId = number;

namespace PurchaseToPay__PurchaseInvoice {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseInvoice'>
    export const Id = Number
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../lowgile/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../lowgile/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top {
  width: 70vw;
  height: 70vh;
  padding: 1em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdlbmVyaWMtcG9wdXAuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDQyxXQUFBO0VBQ0EsWUFBQTtFQUNBLFlBQUE7QUFDRCIsImZpbGUiOiJnZW5lcmljLXBvcHVwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRvcCB7XG5cdHdpZHRoOiA3MHZ3O1xuXHRoZWlnaHQ6IDcwdmg7XG5cdHBhZGRpbmc6IDFlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/generic-popup/generic-popup.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,YAAA;AACD;AACA,oXAAoX","sourcesContent":[".top {\n\twidth: 70vw;\n\theight: 70vh;\n\tpadding: 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

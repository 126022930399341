import { NumberPropertyKeys, TypedPropertyKeys } from '@shared/types'

function map<T, R>(items: T[], propertyOrFn: TypedPropertyKeys<T, R> | ((item: T) => R)) {
	return items.map(item => {
		if(typeof propertyOrFn === 'function') {
			return propertyOrFn(item)
		}
		return item[propertyOrFn] as R
	})
}

export function sum(items: number[]): number {
	return items.reduce((sum, item) => sum + item, 0)
}

export function sumMap<T extends object>(items: T[], propertyOrFn: NumberPropertyKeys<T> | ((item: T) => number)): number {
	const mappedItems = map(items, propertyOrFn)
	return sum(mappedItems)
}

export function avg(items: number[]): number {
	return sum(items) / items.length
}

export function avgMap<T extends object>(items: T[], propertyOrFn: NumberPropertyKeys<T> | ((item: T) => number)): number {
	const mappedItems = map(items, propertyOrFn)
	return avg(mappedItems)
}

export function min(items: number[]): number {
	return items.reduce((min, item) => Math.min(min, item), Number.POSITIVE_INFINITY)
}

export function minMap<T extends object>(items: T[], propertyOrFn: NumberPropertyKeys<T> | ((item: T) => number)): number {
	const mappedItems = map(items, propertyOrFn)
	return min(mappedItems)
}

export function max(items: number[]): number {
	return items.reduce((max, item) => Math.max(max, item), Number.NEGATIVE_INFINITY)
}

export function maxMap<T extends object>(items: T[], propertyOrFn: NumberPropertyKeys<T> | ((item: T) => number)): number {
	const mappedItems = map(items, propertyOrFn)
	return max(mappedItems)
}
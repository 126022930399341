// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/System";
import * as System from "../../Module/System";
import "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

const cache = new Map<string, any>();

export class $Config {
    static get Environment() {
        return {
            'type': "'DEV'",
            'publicName': "DEV",
            'shortName': "DEV",
            'color': '#1e88e5',
            'publicUrl': "https://dev.lowgile.com"
        }
    }

    static get Logging() {
        return {
            'logLevel': 'info',
            'logSqlQueriesSlowerThanMs': 250,
            'raiseIssueForSqlQueriesSlowerThanMs': 1000,
            'logSqlQueriesWithErrors': true
        }
    }

    static get PasswordPolicy() {
        return {
            'minimumLength': Number("1"),
            'mustContainUpperCaseLetters': Number("0"),
            'mustContainLowerCaseLetters': Number("0"),
            'mustContainSymbols': Number("0"),
            'mustContainDigits': Number("0"),
            'mustMatchRegex': ''
        }
    }

    static get Security() {
        return {
            'allowRegisteringUsers': false
        }
    }
}

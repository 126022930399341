import { assignCommonProperties } from '@shared/util/data-util'

import { type ModuleIdType, type BusinessObjectIdType, type UserIdType, UserIdCtor } from './../types';
import { jsonMember, jsonArrayMember, jsonObject } from 'typedjson'
import { User } from './user';

@jsonObject
export class UserConfiguration {
	@jsonMember(Number)
	id?: number

	@jsonMember(() => UserIdCtor)
	userId?: UserIdType
	user?: User

	@jsonMember(String)
	configModuleId: ModuleIdType = ''

	@jsonMember(String)
	configBoId: BusinessObjectIdType = ''

	@jsonMember(String)
	valueJson: string = ''

	constructor(init?: Partial<UserConfiguration>) {
		assignCommonProperties(this, init)
	}

	getQualifiedName() {
		return `${this.configModuleId}.${this.configBoId}`
	}
}
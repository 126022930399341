// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => TaxRate.loadInternal(), entryList => {
        TaxRate.θentryList = entryList
        TaxRate.θentryMap = undefined
        TaxRate.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class TaxRate {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: TaxRate.Id;
    static θids: ReadonlyArray<TaxRate.Id> = Object.freeze(['normal', 'reduced', 'special']);
    static θentryMap: Readonly<Record<TaxRate.Id, Readonly<TaxRate.Entry>>>;
    static θentryList: ReadonlyArray<TaxRate.Entry>;
    static θdropdownOptionsCache = new Map<keyof TaxRate.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.TaxRate' {
        return 'PurchaseToPay.TaxRate'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.TaxRate' {
        return 'PurchaseToPay.TaxRate'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return TaxRate.getEntryMap()[this.id]?.label
    }

    get isNormalRate() {
        return this.id == 'normal'
    }

    static get NormalRate() {
        return new this('normal')
    }

    get isReducedRate() {
        return this.id == 'reduced'
    }

    static get ReducedRate() {
        return new this('reduced')
    }

    get isSpecialRate() {
        return this.id == 'special'
    }

    static get SpecialRate() {
        return new this('special')
    }

    constructor(id: TaxRate.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: TaxRate.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof TaxRate.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<TaxRate.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "normal", label: "Normal: 8.1%" }),
            Object.freeze({ id: "reduced", label: "Reduced: 2.6%" }),
            Object.freeze({ id: "special", label: "Special: 3.8%" })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new TaxRate(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: TaxRate.Id | TaxRate.Option | TaxRate) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.TaxRate yet:', err.message)
            //throw err
        })
    }

    setNormalRate() {
        this.id = 'normal'

    }

    setReducedRate() {
        this.id = 'reduced'

    }

    setSpecialRate() {
        this.id = 'special'

    }
}

export const TaxRateθId = String;

export type TaxRateθId = string;

export namespace TaxRate {
    export type Id = 'normal' | 'reduced' | 'special'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
    }

    export enum Option {
        NormalRate = 'normal',
        ReducedRate = 'reduced',
        SpecialRate = 'special'
    }
}
Sys.Language.languageChanged$.subscribe(() => TaxRate.load(true))

import { UserIdType } from '@shared/types'
import { assignCommonProperties } from '@shared/util/data-util'


export class UserAuthenticationToken {
	id?: number
	userId?: UserIdType
	token!: string
	issuedOn!: Date
	validUntil!: Date

	constructor(init?: Partial<UserAuthenticationToken>) {
		assignCommonProperties(this, init)
	}
}
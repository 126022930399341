// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    request: This.PurchaseRequest;
    pendingOfferCount: number;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-CompletePoInfo',
    templateUrl: './CompletePoInfo.html',
    styleUrls: ['./CompletePoInfo.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompletePoInfo extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.CompletePoInfo';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    @Output()
    submit = new EventEmitter<This.PurchaseRequest>();
    @Output()
    save = new EventEmitter<This.PurchaseRequest>();
    @Output()
    back = new EventEmitter<void>();
    $output = {
        submit: async (data: This.PurchaseRequest) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('submit', data)
            this.submit.emit(data)
        },
        save: async (data: This.PurchaseRequest) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('save', data)
            this.save.emit(data)
        },
        back: async (data: void) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('back', data)
            this.back.emit(data)
        },
    };
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        request: this.request !== undefined ? this.request : (undefined) as This.PurchaseRequest,
        pendingOfferCount: this.pendingOfferCount !== undefined ? this.pendingOfferCount : (undefined) as number
    });

    get $inputs() {
        return {
            request: this.request
        }
    }

    @Input()
    get request() {
        return this.screen?.request
    }

    set request(request: This.PurchaseRequest) {
        request = (request as any)?.[ProxyTargetSymbol] ?? request
        this.storeService.setProperty([this.componentStore.name], 'request', request)
    }

    get pendingOfferCount() {
        return this.screen?.pendingOfferCount
    }

    set pendingOfferCount(pendingOfferCount: number) {
        pendingOfferCount = (pendingOfferCount as any)?.[ProxyTargetSymbol] ?? pendingOfferCount
        this.storeService.setProperty([this.componentStore.name], 'pendingOfferCount', pendingOfferCount)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.request = this.dialogData.request ?? this.request
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.pendingOfferCount = undefined

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_ButtonBlock_0_0_2_1_0_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { request, pendingOfferCount, app, screen, $pathSegments, $disabled, form }: { request: This.PurchaseRequest, pendingOfferCount: number, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit(screen.request, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_2_1_1_click_save_0($event: any, $scope: any): Promise<void> {
        let { request, pendingOfferCount, app, screen, $pathSegments, $disabled, form }: { request: This.PurchaseRequest, pendingOfferCount: number, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_save(screen.request, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_submit(request: This.PurchaseRequest, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        request = this.storeService.replaceWithStateVersion(request, $context)
        {
            output.submit(request)
        }
    }

    async action_save(request: This.PurchaseRequest, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        request = this.storeService.replaceWithStateVersion(request, $context)
        {
            output.save(request)
        }
    }
}

import { JsonMappable } from './json-mappable'
import type { Class } from './../types'
import { IndexedObject, TypedJSON } from 'typedjson'
import * as R from 'ramda'

export interface ValueAndDuplicatePaths {
	valueJson: string
	duplicatePaths: [string, string][]
}

export class BaseJsonMapper<T extends object> {
	constructor(
		private prettyPrint: boolean = false,
		private knownTypes: Record<string, Class<object>> = {},
		private typedJSON?: typeof TypedJSON,
	) {
		if(!typedJSON) this.typedJSON = TypedJSON
	}

	readFromObject<V extends T | T[]>(obj: V, clazz?: Class<T>): V {
		const createdObjects = new Map<any, any>()

		const readFromObjectInternal: (value: any, clazz?: Class<T>) => V = (value, clazz) => {
			if(!value || typeof value != 'object') return value

			if(createdObjects.has(value)) return createdObjects.get(value)
	
			if(value instanceof Array) {
				const createdObj = value.map(item => readFromObjectInternal(item, clazz)) as V
				createdObjects.set(value, createdObj)
				return createdObj
			}
	
			if((value as any).__type == 'ArrayBuffer') {
				// TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
				var binaryString = atob((value as any).data)
				var bytes = new Uint8Array(binaryString.length)
				for (var i = 0; i < binaryString.length; i++) {
					bytes[i] = binaryString.charCodeAt(i)
				}
				const createdObj = bytes.buffer as V
				createdObjects.set(value, createdObj)
				return createdObj
			}
	
			if(value instanceof Date) return value
	
			let type = ''
			if(!clazz) {
				type = (value as JsonMappable).__type
				if(type) {
					clazz = this.knownTypes[type] as Class<T>
					if(!clazz) {
						const [moduleId, boId, subType] = type?.split('.') ?? []
						if(subType) {
							clazz = (this.knownTypes[`${moduleId}.${boId}`] as any)?.[subType]
						}
					}
				}
			}
	
			if(clazz) {
				const jsonSerializer = new this.typedJSON!(clazz, {
					errorHandler: err => console.error(err),
					typeResolver: (resolveObj: IndexedObject, knownTypes) => {
						if(resolveObj === value && clazz) return clazz
						if(resolveObj.__type) {
							let type = this.knownTypes[resolveObj.__type]
							if(!type) {
								const [moduleId, boId, subType] = resolveObj.__type?.split('.') ?? []
								if(subType) {
									type = (this.knownTypes[`${moduleId}.${boId}`] as any)?.[subType]
								}
							}
							if(type) return type
							// TODO: VALIDATE IF NOT BROKEN: CHANGED [] TO .GET ON 2023-10-11
							return knownTypes.get((resolveObj as JsonMappable).__type)
						}
						if(resolveObj instanceof Array) {
							return Array
						}
						return undefined
					},
				})
				const parsed: T = <any>jsonSerializer.parse(value)
		
				if (!parsed) throw new Error(`Failed to parse object of type "${type}" from JSON`)
				createdObjects.set(value, parsed)
				return parsed as V
			}
	
			const entries = Object.entries(value)
			entries.forEach(e => e[1] = readFromObjectInternal(e[1]))
			const createdObj = Object.fromEntries(entries) as V
			createdObjects.set(value, createdObj)
			return createdObj
		}

		return readFromObjectInternal(obj, clazz)
	}

	readFromJson(json: string, clazz?: Class<T>) {
		const jsObj: any = JSON.parse(json)
		const obj = this.readFromObject(jsObj, clazz)
		return obj
	}

	writeToObject<V extends T | T[]>(obj: V, convertTypedJsonObjectsToPlainObjects = true): V {
		const createdObjects = new Map<any, any>()

		const writeToObjectInternal: (value: any) => V = (value) => {
			if(typeof value != 'object' || value == null) return value
			if(createdObjects.has(value)) return createdObjects.get(value)
			
			if(value instanceof Array) {
				const createdObj = value.map(item => writeToObjectInternal(item)) as V
				createdObjects.set(value, createdObj)
				return createdObj
			}

			if(value instanceof ArrayBuffer) {
				// TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
				let ascii = ''
				const uint8 = new Uint8Array(value)
				for(let i = 0; i < value.byteLength; i++) {
					ascii += String.fromCharCode(uint8[i])
				}
				const createdObj = {
					__type: 'ArrayBuffer',
					data: btoa(ascii),
				} as V
				createdObjects.set(value, createdObj)
				return createdObj
			}

			if(value instanceof Date) return value

			if(this.isTypedJsonObject(value)) {
				if(!convertTypedJsonObjectsToPlainObjects) return value

				const objJson = this.writeToJson(value)
				const plainObj = JSON.parse(objJson)
				createdObjects.set(value, plainObj)
				return plainObj
			}

			const entries = Object.entries(value).map(([k, v]) => {
				return [k, writeToObjectInternal(v)]
			})
			const createdObj = Object.fromEntries(entries)
			createdObjects.set(value, createdObj)
			return createdObj
		}

		return writeToObjectInternal(obj)
	}

	writeToJson(obj: T | T[]) {
		if(this.isTypedJsonObject(obj)) {
			const jsonSerializer = new this.typedJSON!(obj.constructor as Class<T>, {
				indent: this.prettyPrint ? '\t' as any : undefined,
			})
			// obj is already a TypedJSON-compatible class instance
			const json = jsonSerializer.stringify(obj)
			return json
		} else {
			// obj is an plain object or array
			return JSON.stringify(obj, (key, value) => {
				if(this.isTypedJsonObject(obj)) {
					const objJson = this.writeToJson(obj)
					const plainObj = JSON.parse(objJson)
					return plainObj
				}
				return value
			})
		}
	}

	writeToJsonRemovingDuplicates(obj: T): ValueAndDuplicatePaths {
		const objPaths = new Map<any, string[]>()
		function convertValue(value: any, path: string): any {
			if(value && typeof value == 'object') {
				const paths = objPaths.get(value) ?? []
				paths.push(path)
				objPaths.set(value, paths)
				
				if(paths.length == 1) {
					const entries = Object.entries<any>(value).map(([k, v]) => [k, convertValue(v, path ? `${path}/${k}` : k)])
					console.log(value, path, entries)
					return Object.fromEntries(entries)
				} else {
					return undefined
				}
			}

			return value
		}

		const value = convertValue(obj, '')
		const valueJson = this.writeToJson(value)
		const duplicatePaths = [...objPaths.values()].filter(paths => paths.length > 1).flatMap(paths => {
			const [first, ...others] = paths
			return others.map(other => [other, first] as [string, string])
		})

		return {
			valueJson,
			duplicatePaths,
		}
	}

	readFromValueAndDuplicatePaths(valueAndDuplicatePaths: ValueAndDuplicatePaths) {
		let obj = this.readFromJson(valueAndDuplicatePaths.valueJson)

		for(const [source, target] of valueAndDuplicatePaths.duplicatePaths) {
			const sourceParts = source.split('/')
			const targetParts = target.split('/')
			
			const val = R.path(sourceParts, obj)
			obj = R.assocPath(targetParts, val, obj)
		}

		return obj
	}

	isTypedJsonObject(obj: any) {
		if(!obj || typeof obj != 'object') return false
		if(obj instanceof Date) return false
		if(obj instanceof Array) return false
	
		return obj.constructor && obj.constructor !== Object
	}
}
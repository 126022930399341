// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => PurchaseRequestStatus.loadInternal(), entryList => {
        PurchaseRequestStatus.θentryList = entryList
        PurchaseRequestStatus.θentryMap = undefined
        PurchaseRequestStatus.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class PurchaseRequestStatus {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: PurchaseRequestStatus.Id;
    static θids: ReadonlyArray<PurchaseRequestStatus.Id> = Object.freeze(['unsubmitted', 'submitted', 'approved', 'offersRequested', 'offersReceived', 'posSentOut', 'goodsPartiallyReceived', 'goodsFullyReceived', 'paymentPending', 'paidAndClosed']);
    static θentryMap: Readonly<Record<PurchaseRequestStatus.Id, Readonly<PurchaseRequestStatus.Entry>>>;
    static θentryList: ReadonlyArray<PurchaseRequestStatus.Entry>;
    static θdropdownOptionsCache = new Map<keyof PurchaseRequestStatus.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.PurchaseRequestStatus' {
        return 'PurchaseToPay.PurchaseRequestStatus'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.PurchaseRequestStatus' {
        return 'PurchaseToPay.PurchaseRequestStatus'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return PurchaseRequestStatus.getEntryMap()[this.id]?.label
    }

    get isUnsubmitted() {
        return this.id == 'unsubmitted'
    }

    static get Unsubmitted() {
        return new this('unsubmitted')
    }

    get isSubmitted() {
        return this.id == 'submitted'
    }

    static get Submitted() {
        return new this('submitted')
    }

    get isApproved() {
        return this.id == 'approved'
    }

    static get Approved() {
        return new this('approved')
    }

    get isOffersRequested() {
        return this.id == 'offersRequested'
    }

    static get OffersRequested() {
        return new this('offersRequested')
    }

    get isOffersReceived() {
        return this.id == 'offersReceived'
    }

    static get OffersReceived() {
        return new this('offersReceived')
    }

    get isPosSentOut() {
        return this.id == 'posSentOut'
    }

    static get PosSentOut() {
        return new this('posSentOut')
    }

    get isGoodsPartiallyReceived() {
        return this.id == 'goodsPartiallyReceived'
    }

    static get GoodsPartiallyReceived() {
        return new this('goodsPartiallyReceived')
    }

    get isGoodFullyReceived() {
        return this.id == 'goodsFullyReceived'
    }

    static get GoodFullyReceived() {
        return new this('goodsFullyReceived')
    }

    get isPaymentPending() {
        return this.id == 'paymentPending'
    }

    static get PaymentPending() {
        return new this('paymentPending')
    }

    get isPaidAndClosed() {
        return this.id == 'paidAndClosed'
    }

    static get PaidAndClosed() {
        return new this('paidAndClosed')
    }

    constructor(id: PurchaseRequestStatus.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: PurchaseRequestStatus.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof PurchaseRequestStatus.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<PurchaseRequestStatus.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "unsubmitted", label: "Unsubmitted" }),
            Object.freeze({ id: "submitted", label: "Submitted" }),
            Object.freeze({ id: "approved", label: "Approved" }),
            Object.freeze({ id: "offersRequested", label: "Offers requested" }),
            Object.freeze({ id: "offersReceived", label: "Offers received" }),
            Object.freeze({ id: "posSentOut", label: "POs sent out" }),
            Object.freeze({ id: "goodsPartiallyReceived", label: "Goods partially received" }),
            Object.freeze({ id: "goodsFullyReceived", label: "Goods fully received" }),
            Object.freeze({ id: "paymentPending", label: "Payment pending" }),
            Object.freeze({ id: "paidAndClosed", label: "Paid and closed" })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new PurchaseRequestStatus(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: PurchaseRequestStatus.Id | PurchaseRequestStatus.Option | PurchaseRequestStatus) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.PurchaseRequestStatus yet:', err.message)
            //throw err
        })
    }

    setUnsubmitted() {
        this.id = 'unsubmitted'

    }

    setSubmitted() {
        this.id = 'submitted'

    }

    setApproved() {
        this.id = 'approved'

    }

    setOffersRequested() {
        this.id = 'offersRequested'

    }

    setOffersReceived() {
        this.id = 'offersReceived'

    }

    setPosSentOut() {
        this.id = 'posSentOut'

    }

    setGoodsPartiallyReceived() {
        this.id = 'goodsPartiallyReceived'

    }

    setGoodFullyReceived() {
        this.id = 'goodsFullyReceived'

    }

    setPaymentPending() {
        this.id = 'paymentPending'

    }

    setPaidAndClosed() {
        this.id = 'paidAndClosed'

    }
}

export const PurchaseRequestStatusθId = String;

export type PurchaseRequestStatusθId = string;

export namespace PurchaseRequestStatus {
    export type Id = 'unsubmitted' | 'submitted' | 'approved' | 'offersRequested' | 'offersReceived' | 'posSentOut' | 'goodsPartiallyReceived' | 'goodsFullyReceived' | 'paymentPending' | 'paidAndClosed'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
    }

    export enum Option {
        Unsubmitted = 'unsubmitted',
        Submitted = 'submitted',
        Approved = 'approved',
        OffersRequested = 'offersRequested',
        OffersReceived = 'offersReceived',
        PosSentOut = 'posSentOut',
        GoodsPartiallyReceived = 'goodsPartiallyReceived',
        GoodFullyReceived = 'goodsFullyReceived',
        PaymentPending = 'paymentPending',
        PaidAndClosed = 'paidAndClosed'
    }
}
Sys.Language.languageChanged$.subscribe(() => PurchaseRequestStatus.load(true))

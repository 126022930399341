export class ScreenUtil {
	static escapeHtml(text: string | undefined) {
		if(!text) return ''
		return text
	}

	static provideDownload(content: any, fileName: string, mimeType?: 'text/plain; charset=utf8' | string) {
		const link = document.createElement('a')
		const blob = new Blob([content], mimeType ? { type: mimeType } : undefined)
		const url = URL.createObjectURL(blob)
		link.setAttribute('href', url)
		link.setAttribute('download', fileName)
		link.click()
	}
}
import { PathOccurenceMap, PathType, ValueType } from './path-occurence-map'

export class PathOccurenceVisitor {
	visit(obj: ValueType, prefix?: PathType) {
		const pathOccurenceMap = new PathOccurenceMap()
		const visitedObjs = new Set<ValueType>()

		function visit(obj: ValueType, prefix?: PathType) {
			if(visitedObjs.has(obj)) return
			visitedObjs.add(obj)
			if(obj == null) return

			for(const [key, value] of Object.entries(obj)) {
				const path = prefix ? [...prefix, key] : [key]
				
				if(value && typeof value == 'object') {
					pathOccurenceMap.set(path, value)
					visit(value, path)
				}
			}
		}

		visit(obj, prefix)
		return pathOccurenceMap
	}
}
import { Component, OnDestroy, OnInit, inject, ViewChild, TemplateRef, ViewContainerRef, EmbeddedViewRef, AfterViewInit, Injector, ChangeDetectorRef } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { filter, map, Subscription, tap } from 'rxjs'
import { ProcessService } from '@ng-shared/lib/services/process.service'
import * as $Modules from '../Module/$modules'
import { CommonModule } from '@angular/common'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'
import { Class, ProcessOutgoingFlowInfo } from '@shared/types'
import { toSignal } from '@angular/core/rxjs-interop'
import { assignCurrentTaskToCurrentUser, driveProcessAndFollowNextUserTask } from '@ng-shared/lib/bridges/frontend-process-bridge'
import { MatButtonModule } from '@angular/material/button'
import { AppContext } from '@ng-shared/lib/app-context'

@Component({
	template: `
		<ng-template #content>
			<div data-content-area="content" class="fill">
				@if(taskDetails$ | async; as taskDetails) {
					@if(taskDetails.screenModuleId && taskDetails.screenBoId) {
						@if(screenComponentClass$ | async; as componentClass) {
							<ng-container *ngComponentOutlet="componentClass; inputs: assignments$ | async"  />
						} @else {
							<div>Screen Component {{taskDetails.screenModuleId}}.{{taskDetails.screenBoId}} not found for task "{{ taskDetails.taskName }}"</div>
						}
					} @else {
						<div style="padding: 2em;">
							<h1>{{ taskDetails.taskName }}</h1>
							<h3>Swimlane "{{ taskDetails.swimlaneName }}"</h3>
							<div style="display: flex; flex-direction: column; align-items: flex-start;">
								@if(!taskDetails.assignedToUserId) {
									<button mat-raised-button (click)="assignTask()" color="primary" style="margin-bottom: 2em;">Assign task to myself</button>
								}
								<h2>Available actions:</h2>
								@for(flow of taskDetails.outgoingFlows; track flow.flowId) {
									<button
										mat-raised-button
										(click)="triggerFlow(flow)"
										[color]="flow.isDefault ? 'primary' : ''"
										[disabled]="!taskDetails.assignedToUserId"
										style="margin-bottom: 1em;"
									>{{ flow.flowName }}{{ flow.nextUserTaskName ? (' (going to "' + flow.nextUserTaskName + '")') : '' }} </button>
								}
							</div>
						</div>
					}
				}
			</div>
		</ng-template>

		<!-- @if(layoutScreenComponentClass$ | async; as layoutScreenComponentClass) {
			<ng-container *ngComponentOutlet="layoutScreenComponentClass; content: contentViewNodes" />
		} @else { -->
			<ng-container *ngTemplateOutlet="content"></ng-container>
		<!-- } -->
    `,
	standalone: true,
	imports: [CommonModule, MatButtonModule],
})
export class ProcessTaskComponent implements OnInit, OnDestroy, AfterViewInit {
	private route = inject(ActivatedRoute)
    private titleService = inject(Title)
	private processService = inject(ProcessService)
	
	private viewContainerRef = inject(ViewContainerRef)
	@ViewChild('content', { static: true }) private contentTemplate: TemplateRef<any>
	protected contentViewNodes: any[][] = []

	private subscriptions: Subscription[] = []

	protected taskDetails$ = this.processService.taskDetails$.pipe(
		filter(Boolean),
		tap(taskDetails => {
			this.titleService.setTitle(taskDetails.taskName)
			AppContext.processId.set(`${taskDetails.processModuleId}.${taskDetails.processBoId}`)
		}),
	)
	protected taskDetails = toSignal(this.taskDetails$)
	protected screenComponentClass$ = this.taskDetails$.pipe(
		map(taskDetails => $Modules[taskDetails.screenModuleId]?.$Screens?.[taskDetails.screenBoId]?.component),
	)
	protected layoutScreenComponentClass$ = this.taskDetails$.pipe(
		map(taskDetails => $Modules[taskDetails.layoutScreenModuleId]?.$Screens?.[taskDetails.layoutScreenBoId]?.component),
	)
	protected assignments$ = this.taskDetails$.pipe(
		map(taskDetails => {
			const classes = Object.fromEntries(
				Object.entries($Modules).flatMap(([moduleId, moduleClass]) => Object.entries(moduleClass).map(([className, classDef]) => [`${moduleId}.${className}`, classDef] as const))
			) as Record<string, Class<object>>
			
			const jsonMapper = AppContext.jsonMapper //new BaseJsonMapper(false, classes)

			const assignments = Object.fromEntries(taskDetails.assignments.map(([varName, value]) => [varName, jsonMapper.readFromObject(value)]))
			return assignments
		}),
	)

	constructor() {
		this.subscriptions = [
			this.route.params.subscribe(params => {
				this.processService.tokenId.set(params.tokenId)
			}),
		]
	}

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe())
	}

	ngAfterViewInit(): void {
		// this.contentViewNodes = [this.viewContainerRef.createEmbeddedView(this.contentTemplate).rootNodes]
	}

	triggerFlow(flow: ProcessOutgoingFlowInfo) {
		driveProcessAndFollowNextUserTask(flow.flowName, null, this.taskDetails().id)
	}

	async assignTask() {
		await assignCurrentTaskToCurrentUser()
	}
}
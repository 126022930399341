import { assignCommonProperties } from '@shared/util/data-util'

import type { BranchNameType, ModuleIdType } from '@shared/types'
import { jsonMember, jsonObject, toJson } from 'typedjson'

export const BuildModes = {
	dev: 'Development',
	prod: 'Production (optimized)',
	default: 'Default',
	external: 'External (don\'t use)',
 } as const
export type BuildMode = keyof typeof BuildModes
export type BuildStatusName = 'Stopped' | 'Has errors' | 'Building' | 'Running'
export interface BuildStatus {
	status: BuildStatusName
	fgColor: string
	bgColor: string
}
export const BuildStatuses: Record<BuildStatusName, BuildStatus> = {
	'Stopped': { status: 'Stopped', fgColor: 'silver', bgColor: 'lightgray' },
	'Has errors': { status: 'Has errors', fgColor: 'red', bgColor: 'salmon' },
	'Building': { status: 'Building', fgColor: 'blue', bgColor: 'lightskyblue' },
	'Running': { status: 'Running', fgColor: 'green', bgColor: 'lightgreen' },
}
export const StartBehaviors = {
	auto: 'Autostart',
	manual: 'Manual',
	onSave: 'On save',
} as const
export type StartBehavior = keyof typeof StartBehaviors

@jsonObject
@toJson
export class BuildConfiguration {
	@jsonMember(String)
	get __type(): string { return 'BuildConfiguration' }
	set __type(_) { /*ignore*/ }

	@jsonMember(String)
	branchName!: BranchNameType
	@jsonMember(String)
	appModuleId!: ModuleIdType
	@jsonMember(String)
	startBehavior: StartBehavior = 'manual'
	@jsonMember(Boolean)
	isActive: boolean = false
	@jsonMember(Boolean)
	isImmediateStartRequested: boolean = false
	@jsonMember(Boolean)
	isInitialBuildDone: boolean = false
	@jsonMember(Boolean)
	isBuildDone: boolean = false
	@jsonMember(String)
	buildMode: BuildMode = 'default'
	@jsonMember(String)
	buildError: string | null = null

	constructor(init?: Partial<BuildConfiguration>) {
		assignCommonProperties(this, init)
	}

	updateConfig(from: BuildConfiguration) {
		this.startBehavior = from.startBehavior
		this.isActive = from.isActive
		this.buildMode = from.buildMode
		this.isImmediateStartRequested = from.isImmediateStartRequested
	}

	updateStatus(from: BuildConfiguration) {
		this.isBuildDone = from.isBuildDone
		this.isInitialBuildDone = from.isInitialBuildDone
		this.buildError = from.buildError
	}

	getBuildStatus(): BuildStatus {
		if(!this.isActive) return BuildStatuses['Stopped']
		if(this.buildError) return BuildStatuses['Has errors']
		if(!this.isBuildDone) return BuildStatuses['Building']
		return BuildStatuses['Running']
	}

	matches(other: BuildConfiguration) {
		return this.branchName == other.branchName && this.appModuleId == other.appModuleId
	}
}
import { CommonModule } from '@angular/common'
import {} from '@angular/common/http'
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// import { SocketIoModule } from 'ngx-socket-io'
import { DevBarComponent } from './components/dev-bar/dev-bar.component'
import { FileUploadComponent } from './components/file-upload/file-upload.component'
import { GenericPopupComponent } from './components/generic-popup/generic-popup.component'
import { GridCheckboxEditorComponent } from './components/grid/grid-checkbox-editor/grid-checkbox-editor.component'
import { ValidationErrorsComponent } from './components/validation-errors.component'
// import { VideoComponent } from './components/video/video.component'
import { FormDirective } from './directives/form.directive'
import { ScopeDirective } from './directives/scope.directive'
import { MaterialModules } from './material.modules'
import { Base64SrcPipe } from './pipes/base64-src.pipe'
import { FormBindingPipe } from './pipes/form-binding.pipe'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe'
import { SafeUrlPipe } from './pipes/safe-url.pipe'
import { LetDirective } from '@ngrx/component'
import { OverlayModule } from '@angular/cdk/overlay'
import { UntilChangedPipe } from './pipes/until-changed.pipe'
import { SearchableDropdownComponent } from './components/searchable-dropdown/searchable-dropdown.component'
import { GenericTableComponent } from './components/generic-table/generic-table.component'
import { DynamicTabDirective } from './directives/dynamic-tab.directive'
import { AngularSplitModule } from 'angular-split'
import { ConstPipe } from './pipes/const.pipe'
import { JsonHighlightPipe } from './pipes/json-highlight.pipe'
import { SnackBarComponent } from './components/snack-bar/snack-bar.component'
import { FormFieldDirective } from './directives/form-field.directive'
import { MaybeAsyncPipe } from './pipes/maybe-async.pipe'
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component'
import { NgChartsModule } from 'ng2-charts'
import { GenericTableColumn } from './components/generic-table/generic-table-column.directive'
import { TabGroupControlDirective } from './directives/tab-group-control.directive'
import { TabGroupTabControlDirective } from './directives/tab-group-tab-control-directive'
import { EscapeHtmlPipe } from './pipes/escape-html.pipe'
import { ReplaceNewlinesPipe } from './pipes/replace-newlines.pipe'
import { ImageSelectorComponent } from './components/image-selector/image-selector.component'
import { InlineCommentComponent } from './components/inline-comment/inline-comment'
import { TypeSafeMatCellDef } from './directives/typesafe-matcelldef.directive'
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component'

const Declarations = [
      // Components:
      DevBarComponent,
      FileUploadComponent,
      GenericPopupComponent,
      ImageSelectorComponent,
      ValidationErrorsComponent,
      GenericTableComponent,
      GridCheckboxEditorComponent,
      InlineCommentComponent,
      LanguageSwitcherComponent,
      RichTextEditorComponent,
      SearchableDropdownComponent,
      SnackBarComponent,
      // VideoComponent,
      // Pipes
      Base64SrcPipe,
      ConstPipe,
      EscapeHtmlPipe,
      JsonHighlightPipe,
      MaybeAsyncPipe,
      ReplaceNewlinesPipe,
      SafeHtmlPipe,
      SafeResourceUrlPipe,
      SafeUrlPipe,
      FormBindingPipe,
      UntilChangedPipe,
      // Directives
      DynamicTabDirective,
      FormDirective,
      FormFieldDirective,
      GenericTableColumn,
      ScopeDirective,
      TabGroupControlDirective,
      TabGroupTabControlDirective,
      TypeSafeMatCellDef,
] as const

const ImportExport = [
  ...MaterialModules,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // HttpClientModule,
  LetDirective,
  OverlayModule,
  AngularSplitModule,
  NgChartsModule,
]

@NgModule({
  imports: [
    // SocketIoModule.forRoot({
    //   url: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    //   options: {
    //     transports: ['websocket'],
    //   }
    // }),
    ...ImportExport,
  ],
  declarations: [
    ...Declarations,
  ],
  exports: [
    ...Declarations,
    ...ImportExport,
  ]
})
export class NgSharedModule {}

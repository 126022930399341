// Auto-generated by Lowgile
import { Routes } from "@angular/router";
import { ApprovePurchaseOrder as PurchaseToPay__ApprovePurchaseOrder } from "@app/Screen/PurchaseToPay/ApprovePurchaseOrder";
import { ApprovePurchaseRequest as PurchaseToPay__ApprovePurchaseRequest } from "@app/Screen/PurchaseToPay/ApprovePurchaseRequest";
import { ApprovePurchaseRequisition as PurchaseToPay__ApprovePurchaseRequisition } from "@app/Screen/PurchaseToPay/ApprovePurchaseRequisition";
import { AwaitDeliveryAndPayment as PurchaseToPay__AwaitDeliveryAndPayment } from "@app/Screen/PurchaseToPay/AwaitDeliveryAndPayment";
import { ClassifyProcurementType as PurchaseToPay__ClassifyProcurementType } from "@app/Screen/PurchaseToPay/ClassifyProcurementType";
import { CompletePoInfo as PurchaseToPay__CompletePoInfo } from "@app/Screen/PurchaseToPay/CompletePoInfo";
import { ConfirmOrder as PurchaseToPay__ConfirmOrder } from "@app/Screen/PurchaseToPay/ConfirmOrder";
import { Dashboard as PurchaseToPay__Dashboard } from "@app/Screen/PurchaseToPay/Dashboard";
import { EnterInvoice as PurchaseToPay__EnterInvoice } from "@app/Screen/PurchaseToPay/EnterInvoice";
import { EvaluateOffers as PurchaseToPay__EvaluateOffers } from "@app/Screen/PurchaseToPay/EvaluateOffers";
import { GoodsReceipt as PurchaseToPay__GoodsReceipt } from "@app/Screen/PurchaseToPay/GoodsReceipt";
import { Home as PurchaseToPay__Home } from "@app/Screen/PurchaseToPay/Home";
import { IncludeApprovals as PurchaseToPay__IncludeApprovals } from "@app/Screen/PurchaseToPay/IncludeApprovals";
import { IncludePurchaseInfo as PurchaseToPay__IncludePurchaseInfo } from "@app/Screen/PurchaseToPay/IncludePurchaseInfo";
import { IncludeRequest as PurchaseToPay__IncludeRequest } from "@app/Screen/PurchaseToPay/IncludeRequest";
import { InvoiceReceipt as PurchaseToPay__InvoiceReceipt } from "@app/Screen/PurchaseToPay/InvoiceReceipt";
import { MainLayout as PurchaseToPay__MainLayout } from "@app/Screen/PurchaseToPay/MainLayout";
import { ManageMasterData as PurchaseToPay__ManageMasterData } from "@app/Screen/PurchaseToPay/ManageMasterData";
import { PortalWorklist as PurchaseToPay__PortalWorklist } from "@app/Screen/PurchaseToPay/PortalWorklist";
import { ProcessLayout as PurchaseToPay__ProcessLayout } from "@app/Screen/PurchaseToPay/ProcessLayout";
import { RaiseRequest as PurchaseToPay__RaiseRequest } from "@app/Screen/PurchaseToPay/RaiseRequest";
import { SearchRequest as PurchaseToPay__SearchRequest } from "@app/Screen/PurchaseToPay/SearchRequest";
import { SelectVendors as PurchaseToPay__SelectVendors } from "@app/Screen/PurchaseToPay/SelectVendors";
import { Test as PurchaseToPay__Test } from "@app/Screen/PurchaseToPay/Test";
import { UserProfile as PurchaseToPay__UserProfile } from "@app/Screen/PurchaseToPay/UserProfile";
import { ViewPurchaseRequest as PurchaseToPay__ViewPurchaseRequest } from "@app/Screen/PurchaseToPay/ViewPurchaseRequest";
import { WaitForOffers as PurchaseToPay__WaitForOffers } from "@app/Screen/PurchaseToPay/WaitForOffers";
import { DefineSchedule as PurchaseToPay__DefineSchedule } from "@app/Screen/PurchaseToPay/DefineSchedule";
import { ProcessTaskComponent } from "@app/components/process-task.component";

export const AppRoutes: Routes = [
    { path: 'ApprovePurchaseOrder', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseOrder }] },
    { path: 'PurchaseToPay.ApprovePurchaseOrder', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseOrder }] },
    { path: 'ApprovePurchaseRequest', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseRequest }] },
    { path: 'PurchaseToPay.ApprovePurchaseRequest', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseRequest }] },
    { path: 'ApprovePurchaseRequisition', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseRequisition }] },
    { path: 'PurchaseToPay.ApprovePurchaseRequisition', children: [{ path: '**', component: PurchaseToPay__ApprovePurchaseRequisition }] },
    { path: 'AwaitDeliveryAndPayment', children: [{ path: '**', component: PurchaseToPay__AwaitDeliveryAndPayment }] },
    { path: 'PurchaseToPay.AwaitDeliveryAndPayment', children: [{ path: '**', component: PurchaseToPay__AwaitDeliveryAndPayment }] },
    { path: 'ClassifyProcurementType', children: [{ path: '**', component: PurchaseToPay__ClassifyProcurementType }] },
    { path: 'PurchaseToPay.ClassifyProcurementType', children: [{ path: '**', component: PurchaseToPay__ClassifyProcurementType }] },
    { path: 'CompletePoInfo', children: [{ path: '**', component: PurchaseToPay__CompletePoInfo }] },
    { path: 'PurchaseToPay.CompletePoInfo', children: [{ path: '**', component: PurchaseToPay__CompletePoInfo }] },
    { path: 'ConfirmOrder', children: [{ path: '**', component: PurchaseToPay__ConfirmOrder }] },
    { path: 'PurchaseToPay.ConfirmOrder', children: [{ path: '**', component: PurchaseToPay__ConfirmOrder }] },
    { path: 'Dashboard', children: [{ path: '**', component: PurchaseToPay__Dashboard }] },
    { path: 'PurchaseToPay.Dashboard', children: [{ path: '**', component: PurchaseToPay__Dashboard }] },
    { path: 'EnterInvoice', children: [{ path: '**', component: PurchaseToPay__EnterInvoice }] },
    { path: 'PurchaseToPay.EnterInvoice', children: [{ path: '**', component: PurchaseToPay__EnterInvoice }] },
    { path: 'EvaluateOffers', children: [{ path: '**', component: PurchaseToPay__EvaluateOffers }] },
    { path: 'PurchaseToPay.EvaluateOffers', children: [{ path: '**', component: PurchaseToPay__EvaluateOffers }] },
    { path: 'GoodsReceipt', children: [{ path: '**', component: PurchaseToPay__GoodsReceipt }] },
    { path: 'PurchaseToPay.GoodsReceipt', children: [{ path: '**', component: PurchaseToPay__GoodsReceipt }] },
    { path: 'Home', children: [{ path: '**', component: PurchaseToPay__Home }] },
    { path: 'PurchaseToPay.Home', children: [{ path: '**', component: PurchaseToPay__Home }] },
    { path: 'IncludeApprovals', children: [{ path: '**', component: PurchaseToPay__IncludeApprovals }] },
    { path: 'PurchaseToPay.IncludeApprovals', children: [{ path: '**', component: PurchaseToPay__IncludeApprovals }] },
    { path: 'IncludePurchaseInfo', children: [{ path: '**', component: PurchaseToPay__IncludePurchaseInfo }] },
    { path: 'PurchaseToPay.IncludePurchaseInfo', children: [{ path: '**', component: PurchaseToPay__IncludePurchaseInfo }] },
    { path: 'IncludeRequest', children: [{ path: '**', component: PurchaseToPay__IncludeRequest }] },
    { path: 'PurchaseToPay.IncludeRequest', children: [{ path: '**', component: PurchaseToPay__IncludeRequest }] },
    { path: 'InvoiceReceipt', children: [{ path: '**', component: PurchaseToPay__InvoiceReceipt }] },
    { path: 'PurchaseToPay.InvoiceReceipt', children: [{ path: '**', component: PurchaseToPay__InvoiceReceipt }] },
    { path: 'MainLayout', children: [{ path: '**', component: PurchaseToPay__MainLayout }] },
    { path: 'PurchaseToPay.MainLayout', children: [{ path: '**', component: PurchaseToPay__MainLayout }] },
    { path: 'ManageMasterData', children: [{ path: '**', component: PurchaseToPay__ManageMasterData }] },
    { path: 'PurchaseToPay.ManageMasterData', children: [{ path: '**', component: PurchaseToPay__ManageMasterData }] },
    { path: 'PortalWorklist', children: [{ path: '**', component: PurchaseToPay__PortalWorklist }] },
    { path: 'PurchaseToPay.PortalWorklist', children: [{ path: '**', component: PurchaseToPay__PortalWorklist }] },
    { path: 'ProcessLayout', children: [{ path: '**', component: PurchaseToPay__ProcessLayout }] },
    { path: 'PurchaseToPay.ProcessLayout', children: [{ path: '**', component: PurchaseToPay__ProcessLayout }] },
    { path: 'RaiseRequest', children: [{ path: '**', component: PurchaseToPay__RaiseRequest }] },
    { path: 'PurchaseToPay.RaiseRequest', children: [{ path: '**', component: PurchaseToPay__RaiseRequest }] },
    { path: 'SearchRequest', children: [{ path: '**', component: PurchaseToPay__SearchRequest }] },
    { path: 'PurchaseToPay.SearchRequest', children: [{ path: '**', component: PurchaseToPay__SearchRequest }] },
    { path: 'SelectVendors', children: [{ path: '**', component: PurchaseToPay__SelectVendors }] },
    { path: 'PurchaseToPay.SelectVendors', children: [{ path: '**', component: PurchaseToPay__SelectVendors }] },
    { path: 'Test', children: [{ path: '**', component: PurchaseToPay__Test }] },
    { path: 'PurchaseToPay.Test', children: [{ path: '**', component: PurchaseToPay__Test }] },
    { path: 'UserProfile', children: [{ path: '**', component: PurchaseToPay__UserProfile }] },
    { path: 'PurchaseToPay.UserProfile', children: [{ path: '**', component: PurchaseToPay__UserProfile }] },
    { path: 'ViewPurchaseRequest', children: [{ path: '**', component: PurchaseToPay__ViewPurchaseRequest }] },
    { path: 'PurchaseToPay.ViewPurchaseRequest', children: [{ path: '**', component: PurchaseToPay__ViewPurchaseRequest }] },
    { path: 'WaitForOffers', children: [{ path: '**', component: PurchaseToPay__WaitForOffers }] },
    { path: 'PurchaseToPay.WaitForOffers', children: [{ path: '**', component: PurchaseToPay__WaitForOffers }] },
    { path: 'DefineSchedule', children: [{ path: '**', component: PurchaseToPay__DefineSchedule }] },
    { path: 'PurchaseToPay.DefineSchedule', children: [{ path: '**', component: PurchaseToPay__DefineSchedule }] },
    { path: 't/:tokenId', component: ProcessTaskComponent },
    { path: '**', component: PurchaseToPay__Home }
];

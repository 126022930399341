import { BackendResponse } from '@shared/data/backend-response'
import { HasType, HasBoType, EntityCommon, IsBo, BoTypeSymbol, CallableBo, CsrfHeaderName, BusinessObjectTypeType } from '@shared/types'
import { firstValueFrom, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { AppContext } from '../app-context'
import { CsrfService } from './csrf.service'

export class ExecutionService {
  static async executeMethodAndReturnResponse<T>(boType: BusinessObjectTypeType, moduleId: string, boId: string, obj: any, methodName: string, args: any[]) {
    const path = `/__appApi/${AppContext.appModuleId}/${boType}/${moduleId}/${boId}/${methodName}`
    const csrfService = AppContext.injector.get(CsrfService)

    const exec$ = csrfService.isLoaded$.pipe(
      switchMap(() => {
        return AppContext.http.post<BackendResponse<T>>(path, {
          obj,
          args: AppContext.jsonMapper.writeToObject(args),
        }, {
          headers: {
            [CsrfHeaderName]: csrfService.csrfToken() ?? '',
          }
        })
      }),
      catchError(err => of({
        error: {
          message: err?.message ?? err,
          context: `Call to Server API for ${moduleId}.${boId}.${methodName}`
        }
      } as BackendResponse<T>))
    )
    const response = await firstValueFrom(exec$)
    response.data = AppContext.jsonMapper.readFromObject(response.data as object) as T

    return response
  }

  static async executeMethodAndReturnData<T>(boType: BusinessObjectTypeType, moduleId: string, boId: string, obj: any, methodName: string, args: any[]) {
    const response = await this.executeMethodAndReturnResponse<T>(boType, moduleId, boId, obj, methodName, args)
    if(response.error) {
      throw response.error
    }
    return response.data
  }

  static convertBase64ToArrayBuffer(base64: string) {
    // TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

import { UserIdCtor, type UserIdType } from '@shared/types'
import { assignCommonProperties } from '@shared/util/data-util'

import { jsonMember, jsonObject, toJson } from 'typedjson'

export const UserMfaMechanismTypes = ['phone', 'authenticator'] as const

@toJson
@jsonObject
export class UserMfaMechanism {
	@jsonMember(Number)
	id?: number
	@jsonMember(() => UserIdCtor)
	userId?: UserIdType
	@jsonMember(String)
	name?: string
	@jsonMember(String)
	type?: typeof UserMfaMechanismTypes[number]
	// used for phone number or authenticator secret
	@jsonMember(String)
	authentication?: string

	// only used for SMS
	@jsonMember(String)
	tokenIssued?: string

	@jsonMember(Date)
	tokenValidUntil!: Date

	@jsonMember(Date)
	creationDate?: Date
	@jsonMember(Date)
	lastUsedDate?: Date

	constructor(init?: Partial<UserMfaMechanism>) {
		assignCommonProperties(this, init)
	}
}
import { tap, catchError, take, map, switchMap, startWith, filter, shareReplay, share } from 'rxjs/operators';
import { EMPTY, firstValueFrom, of, Subject } from 'rxjs';
import { ClientLoginResponse } from '@shared/auth/login-response';
import { Injectable, inject } from '@angular/core';
import { UserRecord } from '@shared/auth/user-record'
import { SystemPermissionName } from '@shared/auth/system-permissions'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'
import { User } from '@shared/auth/user'
import { AppTrpcServiceInjectionToken } from './app-trpc.service'
import { LoginRequest } from '@shared/auth/login-request'
import { IdbService } from './idb.service'
import { IdbRefreshTokenProperty, IdbUserProperty } from '@shared/types'
import { CsrfService } from './csrf.service'
import { on$ } from '@shared/util/data-util'


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private trpc = inject(AppTrpcServiceInjectionToken)
  private idb = inject(IdbService)
  private csrfService = inject(CsrfService)
  refreshToken: string
  user?: UserRecord
  renewTimeout?: number

  private loaded$ = this.csrfService.isLoaded$
  private forceRenewLoginSubject = new Subject<void>()
  readonly loginRenewed$ = on$(this.loaded$, () => this.forceRenewLoginSubject.pipe(
    startWith(undefined),
    switchMap(() => this.renewLogin()),
    filter(Boolean),
    share(),
  ))
  readonly loggedIn$ = this.loginRenewed$.pipe(
    take(1),
    shareReplay(1),
  )
  heartbeat$ = on$(this.loaded$, () => this.trpc.subscriptionAsObservable(c => c.auth.heartbeat$, undefined))
  loginConfig$ = on$(this.loaded$, () => this.trpc.queryAsObservable(c => c.auth.getLoginConfig.query()))
  errorLogger: (message: any) => void = console.error
  initializedPromise: Promise<void>
  
  
  constructor() {
    this.initializedPromise = new Promise<void>(async res => {
      const user = await this.idb.get(IdbUserProperty)
      this.user = user ? new UserRecord(user) : undefined
      this.refreshToken = await this.idb.get(IdbRefreshTokenProperty) ?? ''
      res()
    })
  }


  login$(request: LoginRequest) {
    return this.trpc.queryAsObservable(c => c.auth.login.mutate(request)).pipe(
      catchError(err => {
        return of({
          nextStage: request.stage,
          error: err.message,
        } as ClientLoginResponse)
      }),
      switchMap(async loginResponse => {
        if(loginResponse.nextStage == 'successful') {
          await this.setTokens(loginResponse, true);
        }
        if(loginResponse.error) {
          this.errorLogger(loginResponse.error)
        }
        return loginResponse
      }),
      tap(x => {
        console.log('login response', x)
      })
    )
  }

  logout$() {
    return this.trpc.queryAsObservable(c => c.auth.logout.mutate()).pipe(
      switchMap(async loginResponse => {
        await this.setTokens(null, true);
        this.trpc.csrfService.forgetCsrfToken()
        return loginResponse
      }),
      catchError(err => {
        this.errorLogger('Failed to log out')
        return EMPTY
      })
    )
  }

  requestAuthToken$(request: LoginRequest) {
    return this.trpc.queryAsObservable(c => c.auth.requestAuthToken.mutate({
      userName: request.userName,
      password: request.password,
    })).pipe(
      catchError(err => {
        this.errorLogger('Failed to request an SMS token')
        return EMPTY
      })
    )
  }

  private async renewLogin() {
    try {
      const loginResponse = await this.trpc.client.auth.renewLogin.mutate({
        refreshToken: this.refreshToken,
      })
      if(loginResponse.nextStage == 'successful') {
        // console.log('renewed login')
        await this.setTokens(loginResponse, true);
      }
      return loginResponse
    } catch(err) {
      this.errorLogger('Failed to renew login... redirecting to login page')
      if(typeof window != 'undefined') {
        setTimeout(() => window.location.pathname = 'studio/login', 1500)
      }
      return undefined
    }
  }

  async forceRenewLogin() {
    this.forceRenewLoginSubject.next()
    return firstValueFrom(this.loginRenewed$)
  }

  updatePassword(oldPassword: string, newPassword: string) {
    const updatePassword$ = this.trpc.queryAsObservable(c => c.user.updatePassword.mutate({
        oldPassword,
        newPassword,
      }))
      .pipe(
        catchError(err => {
          return of(false)
        }),
        tap((successful) => {
          if(!successful) {
            this.errorLogger('Failed to update password')
          }
        })
      )

    return firstValueFrom(updatePassword$)
  }

  isUsernameAvailable(userName: string) {
    const updatePassword$ = this.trpc.queryAsObservable(c => c.user.isUsernameAvailable.query({
      userName,
    }))
    .pipe(
      catchError(err => {
        this.errorLogger('Failed to get availability')
        return EMPTY
      }),
    )

    return firstValueFrom(updatePassword$)
  }

  async registerUser(user: User, password: string, generatePassword: boolean) {
    return  this.trpc.client.user.register.mutate({
      user,
      password,
      generatePassword,
    })
  }

  async startPasswordRecovery(userNameOrEmail: string) {
    return this.trpc.client.user.startPasswordRecovery.mutate({
      userNameOrEmail,
    })
  }

  async finalizePasswordRecovery(userName: string, recoveryToken: string, newPassword: string) {
    return this.trpc.client.user.finalizePasswordRecovery.mutate({
      userName,
      recoveryToken,
      newPassword,
    })
  }

  async setTokens(loginResponse: ClientLoginResponse | null, storeInIdb: boolean) {
    let doStore = false
    if(loginResponse) {
      this.refreshToken = loginResponse.refreshToken ?? ''
      this.user = new BaseJsonMapper().readFromObject(loginResponse.user!, UserRecord)
      doStore = !!this.refreshToken
    } else {
      this.refreshToken = ''
      this.user = undefined
      doStore = true
    }

    if (doStore && storeInIdb) {
      if(this.refreshToken) {
        await this.idb.put(IdbRefreshTokenProperty, this.refreshToken)
        await this.idb.put(IdbUserProperty, this.user)

        this.startRenewalTimeout(loginResponse?.expiresAt)
      } else {
        await this.idb.delete(IdbRefreshTokenProperty)
        await this.idb.delete(IdbUserProperty)
      }
    }
  }

  startRenewalTimeout(expiresAt: number | undefined) {
    if(!expiresAt) return
    if(this.renewTimeout) clearTimeout(this.renewTimeout)
    
    const renewInMs = Math.max(expiresAt - Date.now() - 5000, 2000)
    this.renewTimeout = setTimeout(() => {
      this.forceRenewLogin()
    }, renewInMs) as any // renew 5s before expiration
  }

  async isLoggedIn() {
    await this.initializedPromise
    return !!this.user?.id;
  }

  getUser() {
    return this.user
  }

  getUserFullName() {
    if (!this.user) return '(Anonymous)';

    return `${this.user.firstName} ${this.user.lastName}\n(${this.user.userName})`;
  }

  hasSystemPermission(permissionName: SystemPermissionName) {
    return this.user?.hasSystemPermission(permissionName) ?? false
  }
}

// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseOrder {
    @jsonMember(() => PurchaseToPay__PurchaseOrder.Id)
    id: PurchaseToPay__PurchaseOrder.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "requestorUserId", "poNumber", "currency", "currency$id", "deliveryAddress", "billingAddress", "paymentTerm", "paymentTerm$id", "incoTerm", "incoTerm$id", "isClosed", "items", "orderExtras", "purchaseGoodsReceiptNotes", "invoices", "vendorOffer", "vendorOfferId", "approvals", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber", "previewText"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "requestorUserId": "string", "poNumber": "string", "currency": "PurchaseToPay.Currency", "currency$id": "string", "deliveryAddress": "string", "billingAddress": "string", "paymentTerm": "PurchaseToPay.PaymentTerm", "paymentTerm$id": "string", "incoTerm": "PurchaseToPay.IncoTerm", "incoTerm$id": "string", "isClosed": "boolean", "items": "PurchaseToPay.PurchaseOrderItem[]", "orderExtras": "PurchaseToPay.PurchaseOrderExtra[]", "purchaseGoodsReceiptNotes": "PurchaseToPay.PurchaseGoodsReceiptNote[]", "invoices": "PurchaseToPay.PurchaseInvoice[]", "vendorOffer": "PurchaseToPay.PurchaseVendorOffer", "vendorOfferId": "PurchaseToPay.PurchaseVendorOffer.Id", "approvals": "PurchaseToPay.PurchaseOrderApproval[]", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "previewText": "string", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["currency", "paymentTerm", "incoTerm"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ vendorOffer: 'PurchaseToPay.PurchaseVendorOffer' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ items: 'PurchaseToPay.PurchaseOrderItem', orderExtras: 'PurchaseToPay.PurchaseOrderExtra', purchaseGoodsReceiptNotes: 'PurchaseToPay.PurchaseGoodsReceiptNote', invoices: 'PurchaseToPay.PurchaseInvoice', approvals: 'PurchaseToPay.PurchaseOrderApproval' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θrequestorUserId?: string = undefined;
    private θpoNumber?: string = undefined;
    private θcurrency?: This.Currency = This.Currency.CHF;
    private θdeliveryAddress?: string = undefined;
    private θbillingAddress?: string = undefined;
    private θpaymentTerm?: This.PaymentTerm = This.PaymentTerm.Days30;
    private θincoTerm?: This.IncoTerm = This.IncoTerm.DDP;
    private θisClosed?: boolean = false;
    private θitems?: PurchaseToPay.PurchaseOrderItem[] = undefined;
    private θorderExtras?: PurchaseToPay.PurchaseOrderExtra[] = undefined;
    private θpurchaseGoodsReceiptNotes?: PurchaseToPay.PurchaseGoodsReceiptNote[] = undefined;
    private θinvoices?: PurchaseToPay.PurchaseInvoice[] = undefined;
    private θvendorOffer?: PurchaseToPay.PurchaseVendorOffer = undefined;
    private θvendorOfferId?: number = null;
    private θapprovals?: PurchaseToPay.PurchaseOrderApproval[] = undefined;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        requestorUserId: 0,
        poNumber: 0,
        deliveryAddress: 0,
        billingAddress: 0,
        isClosed: 0,
        items: 0,
        orderExtras: 0,
        purchaseGoodsReceiptNotes: 0,
        invoices: 0,
        vendorOffer: 0,
        vendorOfferId: 0,
        approvals: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        requestorUserId: () => {
            return false
        }
        , poNumber: () => {
            return false
        }
        , deliveryAddress: () => {
            return false
        }
        , billingAddress: () => {
            return false
        }
        , isClosed: () => {
            return false
        }
        , items: () => {
            return false
        }
        , orderExtras: () => {
            return false
        }
        , purchaseGoodsReceiptNotes: () => {
            return false
        }
        , invoices: () => {
            return false
        }
        , vendorOffer: () => {
            return false
        }
        , vendorOfferId: () => {
            return false
        }
        , approvals: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseOrder' {
        return 'PurchaseToPay.PurchaseOrder'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseOrder' {
        return 'PurchaseToPay.PurchaseOrder'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseOrder' {
        return 'PurchaseToPay.PurchaseOrder'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseOrder' {
        return 'PurchaseToPay.PurchaseOrder'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseOrder[EntityInfoSymbol]
    }

    get requestorUserId() {
        return this.θrequestorUserId
    }

    set requestorUserId(requestorUserId: string) {
        this.θrequestorUserId = LowgileDataUtil.coerceDataType(requestorUserId, 'string', false, true)
    }

    get poNumber() {
        return this.θpoNumber
    }

    set poNumber(poNumber: string) {
        this.θpoNumber = LowgileDataUtil.coerceDataType(poNumber, 'string', false, true)
    }

    get currency(): This.Currency {
        return this.θcurrency
    }

    set currency(currency: This.Currency | This.Currency.Id) {
        if (!this.θcurrency) this.θcurrency = new This.Currency()
        this.θcurrency.θupdate(currency)
    }

    @jsonMember(String)
    get 'currency$id'(): string {
        return this.θcurrency?.id
    }

    set 'currency$id'(currency: string) {
        if (!this.θcurrency) this.θcurrency = This.Currency.CHF
        this.θcurrency.id = currency as any
    }

    get deliveryAddress() {
        return this.θdeliveryAddress
    }

    set deliveryAddress(deliveryAddress: string) {
        this.θdeliveryAddress = LowgileDataUtil.coerceDataType(deliveryAddress, 'string', false, true)
    }

    get billingAddress() {
        return this.θbillingAddress
    }

    set billingAddress(billingAddress: string) {
        this.θbillingAddress = LowgileDataUtil.coerceDataType(billingAddress, 'string', false, true)
    }

    get paymentTerm(): This.PaymentTerm {
        return this.θpaymentTerm
    }

    set paymentTerm(paymentTerm: This.PaymentTerm | This.PaymentTerm.Id) {
        if (!this.θpaymentTerm) this.θpaymentTerm = new This.PaymentTerm()
        this.θpaymentTerm.θupdate(paymentTerm)
    }

    @jsonMember(String)
    get 'paymentTerm$id'(): string {
        return this.θpaymentTerm?.id
    }

    set 'paymentTerm$id'(paymentTerm: string) {
        if (!this.θpaymentTerm) this.θpaymentTerm = This.PaymentTerm.Days30
        this.θpaymentTerm.id = paymentTerm as any
    }

    get incoTerm(): This.IncoTerm {
        return this.θincoTerm
    }

    set incoTerm(incoTerm: This.IncoTerm | This.IncoTerm.Id) {
        if (!this.θincoTerm) this.θincoTerm = new This.IncoTerm()
        this.θincoTerm.θupdate(incoTerm)
    }

    @jsonMember(String)
    get 'incoTerm$id'(): string {
        return this.θincoTerm?.id
    }

    set 'incoTerm$id'(incoTerm: string) {
        if (!this.θincoTerm) this.θincoTerm = This.IncoTerm.DDP
        this.θincoTerm.id = incoTerm as any
    }

    get isClosed() {
        return this.θisClosed
    }

    set isClosed(isClosed: boolean) {
        this.θisClosed = LowgileDataUtil.coerceDataType(isClosed, 'boolean', false, true)
    }

    get items() {
        return this.θitems
    }

    set items(items: PurchaseToPay.PurchaseOrderItem[]) {
        this.θitems = LowgileDataUtil.coerceDataType(items, PurchaseToPay.PurchaseOrderItem, true, true)
    }

    get orderExtras() {
        return this.θorderExtras
    }

    set orderExtras(orderExtras: PurchaseToPay.PurchaseOrderExtra[]) {
        this.θorderExtras = LowgileDataUtil.coerceDataType(orderExtras, PurchaseToPay.PurchaseOrderExtra, true, true)
    }

    get purchaseGoodsReceiptNotes() {
        return this.θpurchaseGoodsReceiptNotes
    }

    set purchaseGoodsReceiptNotes(purchaseGoodsReceiptNotes: PurchaseToPay.PurchaseGoodsReceiptNote[]) {
        this.θpurchaseGoodsReceiptNotes = LowgileDataUtil.coerceDataType(purchaseGoodsReceiptNotes, PurchaseToPay.PurchaseGoodsReceiptNote, true, true)
    }

    get invoices() {
        return this.θinvoices
    }

    set invoices(invoices: PurchaseToPay.PurchaseInvoice[]) {
        this.θinvoices = LowgileDataUtil.coerceDataType(invoices, PurchaseToPay.PurchaseInvoice, true, true)
    }

    get vendorOffer() {
        return this.θvendorOffer
    }

    set vendorOffer(vendorOffer: PurchaseToPay.PurchaseVendorOffer) {
        this.θvendorOffer = LowgileDataUtil.coerceDataType(vendorOffer, PurchaseToPay.PurchaseVendorOffer, false, true)
    }

    get vendorOfferId() {
        return this.θvendorOfferId
    }

    set vendorOfferId(vendorOfferId: number) {
        this.θvendorOfferId = LowgileDataUtil.coerceDataType(vendorOfferId, 'number', false, true)
    }

    get approvals() {
        return this.θapprovals
    }

    set approvals(approvals: PurchaseToPay.PurchaseOrderApproval[]) {
        this.θapprovals = LowgileDataUtil.coerceDataType(approvals, PurchaseToPay.PurchaseOrderApproval, true, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseOrder.θmaxLength
    }

    get previewText(): string {
        const parts = [this.poNumber]
        if (this.items) {
            parts.push(` (${this.items.map(i => i.description).join(', ')})`)
        }

        return parts.join('')
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseOrder>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseOrder(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseOrder>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseOrder>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('requestorUserId', 'poNumber', 'currency$id', 'deliveryAddress', 'billingAddress', 'paymentTerm$id', 'incoTerm$id', 'isClosed', 'items', 'orderExtras', 'purchaseGoodsReceiptNotes', 'invoices', 'vendorOffer', 'vendorOfferId', 'approvals', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    static async exportToXls(): Promise<string> {
        const args = [...arguments]
        const result = await ExecutionService.executeMethodAndReturnData<string>('Entity', 'PurchaseToPay', 'PurchaseOrder', this, 'exportToXls', args)
        return result
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseOrder { }

export import PurchaseOrder = PurchaseToPay__PurchaseOrder
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'requestorUserId')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'poNumber')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'deliveryAddress')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'billingAddress')
    jsonMember(() => Boolean)(PurchaseToPay__PurchaseOrder.prototype, 'isClosed')
    jsonArrayMember(() => PurchaseToPay.PurchaseOrderItem)(PurchaseToPay__PurchaseOrder.prototype, 'items')
    jsonArrayMember(() => PurchaseToPay.PurchaseOrderExtra)(PurchaseToPay__PurchaseOrder.prototype, 'orderExtras')
    jsonArrayMember(() => PurchaseToPay.PurchaseGoodsReceiptNote)(PurchaseToPay__PurchaseOrder.prototype, 'purchaseGoodsReceiptNotes')
    jsonArrayMember(() => PurchaseToPay.PurchaseInvoice)(PurchaseToPay__PurchaseOrder.prototype, 'invoices')
    jsonMember(() => PurchaseToPay.PurchaseVendorOffer)(PurchaseToPay__PurchaseOrder.prototype, 'vendorOffer')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrder.prototype, 'vendorOfferId')
    jsonArrayMember(() => PurchaseToPay.PurchaseOrderApproval)(PurchaseToPay__PurchaseOrder.prototype, 'approvals')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrder.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrder.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseOrder.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseOrder.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseOrder.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseOrder {
}

export const PurchaseOrderθId = Number;

export type PurchaseOrderθId = number;

namespace PurchaseToPay__PurchaseOrder {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseOrder'>
    export const Id = Number
}

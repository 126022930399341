// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseGoodsReceiptNoteItem {
    @jsonMember(() => PurchaseToPay__PurchaseGoodsReceiptNoteItem.Id)
    id: PurchaseToPay__PurchaseGoodsReceiptNoteItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "rejectionReason", "rejectionReason$id", "comment", "quantityRejected", "quantityReceived", "lineNumber", "quantity", "description", "itemCode", "purchaseOrderLineNumber", "postingDate", "unit", "unit$id", "purchaseGoodsReceiptNote", "purchaseGoodsReceiptNoteId", "purchaseOrderItem", "purchaseOrderItemId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "rejectionReason": "PurchaseToPay.PurchaseRejectionReason", "rejectionReason$id": "string", "comment": "string", "quantityRejected": "number", "quantityReceived": "number", "lineNumber": "number", "quantity": "number", "description": "string", "itemCode": "string", "purchaseOrderLineNumber": "string", "postingDate": "Date", "unit": "PurchaseToPay.Unit", "unit$id": "string", "purchaseGoodsReceiptNote": "PurchaseToPay.PurchaseGoodsReceiptNote", "purchaseGoodsReceiptNoteId": "PurchaseToPay.PurchaseGoodsReceiptNote.Id", "purchaseOrderItem": "PurchaseToPay.PurchaseOrderItem", "purchaseOrderItemId": "PurchaseToPay.PurchaseOrderItem.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["rejectionReason", "unit"] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseGoodsReceiptNote: 'PurchaseToPay.PurchaseGoodsReceiptNote', purchaseOrderItem: 'PurchaseToPay.PurchaseOrderItem' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θrejectionReason?: This.PurchaseRejectionReason = new This.PurchaseRejectionReason();
    private θcomment?: string = undefined;
    private θquantityRejected?: number = undefined;
    private θquantityReceived?: number = undefined;
    private θlineNumber?: number = undefined;
    private θquantity?: number = undefined;
    private θdescription?: string = undefined;
    private θitemCode?: string = undefined;
    private θpurchaseOrderLineNumber?: string = undefined;
    private θpostingDate?: Date = undefined;
    private θunit?: This.Unit = new This.Unit();
    private θpurchaseGoodsReceiptNote?: PurchaseToPay.PurchaseGoodsReceiptNote = undefined;
    private θpurchaseGoodsReceiptNoteId?: number = null;
    private θpurchaseOrderItem?: PurchaseToPay.PurchaseOrderItem = undefined;
    private θpurchaseOrderItemId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        comment: 0,
        quantityRejected: 0,
        quantityReceived: 0,
        lineNumber: 0,
        quantity: 0,
        description: 0,
        itemCode: 0,
        purchaseOrderLineNumber: 0,
        postingDate: 0,
        purchaseGoodsReceiptNote: 0,
        purchaseGoodsReceiptNoteId: 0,
        purchaseOrderItem: 0,
        purchaseOrderItemId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        comment: () => {
            return false
        }
        , quantityRejected: () => {
            return false
        }
        , quantityReceived: () => {
            return false
        }
        , lineNumber: () => {
            return false
        }
        , quantity: () => {
            return false
        }
        , description: () => {
            return false
        }
        , itemCode: () => {
            return false
        }
        , purchaseOrderLineNumber: () => {
            return false
        }
        , postingDate: () => {
            return false
        }
        , purchaseGoodsReceiptNote: () => {
            return false
        }
        , purchaseGoodsReceiptNoteId: () => {
            return false
        }
        , purchaseOrderItem: () => {
            return false
        }
        , purchaseOrderItemId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNoteItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNoteItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNoteItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNoteItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseGoodsReceiptNoteItem[EntityInfoSymbol]
    }

    get rejectionReason(): This.PurchaseRejectionReason {
        return this.θrejectionReason
    }

    set rejectionReason(rejectionReason: This.PurchaseRejectionReason | This.PurchaseRejectionReason.Id) {
        if (!this.θrejectionReason) this.θrejectionReason = new This.PurchaseRejectionReason()
        this.θrejectionReason.θupdate(rejectionReason)
    }

    @jsonMember(String)
    get 'rejectionReason$id'(): string {
        return this.θrejectionReason?.id
    }

    set 'rejectionReason$id'(rejectionReason: string) {
        if (!this.θrejectionReason) this.θrejectionReason = new This.PurchaseRejectionReason()
        this.θrejectionReason.id = rejectionReason as any
    }

    get comment() {
        return this.θcomment
    }

    set comment(comment: string) {
        this.θcomment = LowgileDataUtil.coerceDataType(comment, 'string', false, true)
    }

    get quantityRejected() {
        return this.θquantityRejected
    }

    set quantityRejected(quantityRejected: number) {
        this.θquantityRejected = LowgileDataUtil.coerceDataType(quantityRejected, 'number', false, true)
    }

    get quantityReceived() {
        return this.θquantityReceived
    }

    set quantityReceived(quantityReceived: number) {
        this.θquantityReceived = LowgileDataUtil.coerceDataType(quantityReceived, 'number', false, true)
    }

    get lineNumber() {
        return this.θlineNumber
    }

    set lineNumber(lineNumber: number) {
        this.θlineNumber = LowgileDataUtil.coerceDataType(lineNumber, 'number', false, true)
    }

    get quantity() {
        return this.θquantity
    }

    set quantity(quantity: number) {
        this.θquantity = LowgileDataUtil.coerceDataType(quantity, 'number', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get itemCode() {
        return this.θitemCode
    }

    set itemCode(itemCode: string) {
        this.θitemCode = LowgileDataUtil.coerceDataType(itemCode, 'string', false, true)
    }

    get purchaseOrderLineNumber() {
        return this.θpurchaseOrderLineNumber
    }

    set purchaseOrderLineNumber(purchaseOrderLineNumber: string) {
        this.θpurchaseOrderLineNumber = LowgileDataUtil.coerceDataType(purchaseOrderLineNumber, 'string', false, true)
    }

    get postingDate() {
        return this.θpostingDate
    }

    set postingDate(postingDate: Date) {
        this.θpostingDate = LowgileDataUtil.coerceDataType(postingDate, 'Date', false, true)
    }

    get unit(): This.Unit {
        return this.θunit
    }

    set unit(unit: This.Unit | This.Unit.Id) {
        if (!this.θunit) this.θunit = new This.Unit()
        this.θunit.θupdate(unit)
    }

    @jsonMember(String)
    get 'unit$id'(): string {
        return this.θunit?.id
    }

    set 'unit$id'(unit: string) {
        if (!this.θunit) this.θunit = new This.Unit()
        this.θunit.id = unit as any
    }

    get purchaseGoodsReceiptNote() {
        return this.θpurchaseGoodsReceiptNote
    }

    set purchaseGoodsReceiptNote(purchaseGoodsReceiptNote: PurchaseToPay.PurchaseGoodsReceiptNote) {
        this.θpurchaseGoodsReceiptNote = LowgileDataUtil.coerceDataType(purchaseGoodsReceiptNote, PurchaseToPay.PurchaseGoodsReceiptNote, false, true)
    }

    get purchaseGoodsReceiptNoteId() {
        return this.θpurchaseGoodsReceiptNoteId
    }

    set purchaseGoodsReceiptNoteId(purchaseGoodsReceiptNoteId: number) {
        this.θpurchaseGoodsReceiptNoteId = LowgileDataUtil.coerceDataType(purchaseGoodsReceiptNoteId, 'number', false, true)
    }

    get purchaseOrderItem() {
        return this.θpurchaseOrderItem
    }

    set purchaseOrderItem(purchaseOrderItem: PurchaseToPay.PurchaseOrderItem) {
        this.θpurchaseOrderItem = LowgileDataUtil.coerceDataType(purchaseOrderItem, PurchaseToPay.PurchaseOrderItem, false, true)
    }

    get purchaseOrderItemId() {
        return this.θpurchaseOrderItemId
    }

    set purchaseOrderItemId(purchaseOrderItemId: number) {
        this.θpurchaseOrderItemId = LowgileDataUtil.coerceDataType(purchaseOrderItemId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseGoodsReceiptNoteItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseGoodsReceiptNoteItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseGoodsReceiptNoteItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseGoodsReceiptNoteItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseGoodsReceiptNoteItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('rejectionReason$id', 'comment', 'quantityRejected', 'quantityReceived', 'lineNumber', 'quantity', 'description', 'itemCode', 'purchaseOrderLineNumber', 'postingDate', 'unit$id', 'purchaseGoodsReceiptNote', 'purchaseGoodsReceiptNoteId', 'purchaseOrderItem', 'purchaseOrderItemId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    getQuantityAccepted(): number {
        return this.quantityReceived - this.quantityRejected
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseGoodsReceiptNoteItem { }

export import PurchaseGoodsReceiptNoteItem = PurchaseToPay__PurchaseGoodsReceiptNoteItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'comment')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'quantityRejected')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'quantityReceived')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'lineNumber')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'quantity')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'description')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'itemCode')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'purchaseOrderLineNumber')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'postingDate')
    jsonMember(() => PurchaseToPay.PurchaseGoodsReceiptNote)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'purchaseGoodsReceiptNote')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'purchaseGoodsReceiptNoteId')
    jsonMember(() => PurchaseToPay.PurchaseOrderItem)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'purchaseOrderItem')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'purchaseOrderItemId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNoteItem.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseGoodsReceiptNoteItem {
}

export const PurchaseGoodsReceiptNoteItemθId = Number;

export type PurchaseGoodsReceiptNoteItemθId = number;

namespace PurchaseToPay__PurchaseGoodsReceiptNoteItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseGoodsReceiptNoteItem'>
    export const Id = Number
}

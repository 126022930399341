import { AuthInterceptor } from '@ng-shared/lib/services/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $Modules from './Module/$modules'

// import { BackendService } from './services/backend.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, inject } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Declarations } from './config/declarations'
import { MaterialModules } from './material.modules'
// import { StoreModule } from '@ngrx/store';
// import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ComponentStore } from '@ngrx/component-store';
// import { EffectsModule } from '@ngrx/effects'
// import { Effects } from './store/effects'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
// import { reducerFactory } from './store/reducers';
// import { BindingDirective } from './store/binding.directive'
import { BindingDirective } from '@ng-shared/lib/store/binding.directive'
import { DateAdapter } from '@angular/material/core'
// import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgSharedModule } from '@ng-shared/lib/ng-shared.module';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter'
import { FrontendLanguageBridge } from '@ng-shared/lib/bridges/frontend-language-bridge'
import { NgChartsModule } from 'ng2-charts'
import { Language } from '@ng-shared/lib/frontend-sys'
import { DateFnsLocales } from './config/config'
import { AppTrpcService, AppTrpcServiceInjectionToken } from '@ng-shared/lib/services/app-trpc.service'
import { AuthService } from '@ng-shared/lib/services/auth-service'

@NgModule({
  declarations: [
	AppComponent,
	...Declarations,
	BindingDirective,
  ],
  imports: [
    BrowserModule,
	NgSharedModule,
	AppRoutingModule,
	BrowserAnimationsModule,
	FormsModule,
	ReactiveFormsModule,
	...MaterialModules,
	HttpClientModule,
	NgChartsModule,
    // StoreModule.forRoot(<any>{}, {
	// 	reducerFactory,
	// 	runtimeChecks: {
	// 		strictStateImmutability: false,
	// 		strictActionImmutability: false,
	// 	}
	// }),
	// StoreDevtoolsModule.instrument({ maxAge: 50 }),
	// EffectsModule.forRoot([Effects]),
	NgxExtendedPdfViewerModule,
  ],
  providers: [
	  ComponentStore,
	  { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
	//   { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
	{ provide: MAT_DATE_FORMATS, useValue: {
		parse: {
			dateInput: ['dd.MM.yy', 'dd.MM.yyyy'],
		  },
		  display: {
			dateInput: 'dd.MM.yyyy',
			monthYearLabel: 'MMM yyyy',
			dateA11yLabel: 'LL',
			monthYearA11yLabel: 'MMMM yyyy',
		  },
	  }},
	  { provide: MAT_DATE_LOCALE, useFactory: () => Language.dateLocale },
	  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	  { provide: AppTrpcServiceInjectionToken, useExisting: AppTrpcService },
	],
  bootstrap: [AppComponent]
})
export class AppModule { 
	constructor(
		// private backendService: BackendService
	) {
		$Modules // don't remove; important for avoiding circular dependency issues!
		FrontendLanguageBridge.init()
		FrontendLanguageBridge.θsetDateLocales(DateFnsLocales)
		inject(AuthService).heartbeat$.subscribe()
	}
}

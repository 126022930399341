import { ConversionType, TextConversionType } from '@shared/bridges/common-text-bridge'

export * from '@shared/bridges/common-text-bridge'

export function convert(input: string, from: TextConversionType, to: TextConversionType): string
export function convert(input: ArrayBuffer, from: 'binary', to: TextConversionType): string
export function convert(input: string, from: TextConversionType, to: 'binary'): ArrayBuffer
export function convert(input: ArrayBuffer, from: 'binary', to: 'binary'): ArrayBuffer
export function convert(input: string | ArrayBuffer, from: ConversionType, to: ConversionType): string | ArrayBuffer {
	// TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
	if(from == to) return input
	
	let ascii = ''

	if(from == 'binary') {
		const buffer = input as ArrayBuffer
		const uint8 = new Uint8Array(buffer)
		for(let i = 0; i < buffer.byteLength; i++) {
			ascii += String.fromCharCode(uint8[i])
		}
	} else if(from == 'base64') {
		ascii = atob(String(input))
	} else if(from == 'hex') {
		const hex = input as string
		for(let i = 0; i < hex.length; i += 2) {
			ascii += String.fromCharCode(parseInt(hex.substring(i, i+2), 16))
		}
	} else {
		ascii = input as string
	}

	if(to == 'binary') {
		const buffer = new ArrayBuffer(ascii.length)
		const uint8 = new Uint8Array(buffer)
		for(let i = 0; i < ascii.length; i++) {
			uint8[i] = ascii.charCodeAt(i)
		}
		return buffer
	}
	if(to == 'hex') {
		let hex = ''
		for(let i = 0; i < ascii.length; i++) {
			hex += ('0' + ascii.charCodeAt(i).toString(16)).slice(-2)
		}
		return hex
	}
	if(to == 'base64') return btoa(ascii)

	return ascii
}
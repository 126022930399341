// Auto-generated by Lowgile
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/Common";
import * as Common from "../../Module/Common";
import { System } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

const Translations = { "English": { "validationEmail": "Invalid E-Mail address", "validationRequired": "Required\n" } } as const, $LabelIds = ["validationEmail", "validationRequired"] as const;
for (const obj of Object.values(Translations)) {
    for (const label in obj) {
        if (obj[label] instanceof Function) obj[label].toString = obj[label].toJSON = function() { return this() }

    }

}

let T = Translations['English'];
export const $T = new Proxy({}, {
    get(target, p) { return getLabel(p.toString()) },
    getOwnPropertyDescriptor(target, p) { return { configurable: true, enumerable: true } },
    ownKeys(target) { return Reflect.ownKeys(T) },
}) as TType, $T_English = Translations['English'], $Languages = { "English": "English" } as const;

export type TType = typeof Translations[keyof typeof Translations] & { [key: string]: any };
Sys.Language.language$.subscribe(lang => { T = Translations[lang] ?? {} })

function getLabel(label: string) {
    let translation = T[label]
    const defaultLanguage = Sys.Language.defaultLanguage
    const translationInDefaultLanguage = Translations[defaultLanguage]?.[label]
    if (Sys.Language.editMode) {
        if (translation) {
            const allTranslations = Object.entries(Translations).map(([lang, translations]) => `${lang}: ${translations[label]}`).join('&#013;')
            const title = `Label ${label}&#013;&#013;${allTranslations}`.replaceAll('"', '&quot;')
            return `<span class="translation" title="${title}" onclick="event.preventDefault(); event.stopPropagation(); document.dispatchEvent(new CustomEvent('translateLabel', { detail: { moduleId: 'Common', boId: '${label}' } }))">${translation}</span>`
        }
        if (translationInDefaultLanguage) {
            return `<span class="translation translation-default-language" title="Label ${label} in default language (${defaultLanguage})" onclick="event.preventDefault(); event.stopPropagation(); document.dispatchEvent(new CustomEvent('translateLabel', { detail: { moduleId: 'Common', boId: '${label}' } }))">${translationInDefaultLanguage}</span>`
        }
    }
    if (translation) return translation
    if (translationInDefaultLanguage) return translationInDefaultLanguage
    if (label == '__type') return undefined // otherwise it interferes with loading of known types for jsonMapper
    return `<span class="translation translation-missing" title="Label ${label}" onclick="event.preventDefault(); event.stopPropagation(); document.dispatchEvent(new CustomEvent('translateLabel', { detail: { moduleId: 'Common', boId: '${label}' } }))">${label}</span>`
}

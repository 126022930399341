// Auto-generated by Lowgile
import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy, Input, ChangeDetectionStrategy, AfterContentInit, ContentChildren, QueryList, ViewChild, TrackByFunction, Output, EventEmitter, ElementRef, ChangeDetectorRef, AfterViewInit, TemplateRef, ContentChild } from "@angular/core";
import {
	MatCellDef,
    MatHeaderCell,
    MatHeaderRowDef,
    MatRowDef,
    MatTable,
    MatTableDataSource,
  } from '@angular/material/table';
import { BoTypeSymbol, Class, DataQuery, EntityClassOnDb, EntityInfoSymbol, EntityInstance, FindManyOptions, PagedData, PagingDefinition, ServerDataStoreClass, TableViewConfiguration, TableViewConfigurations, UserDefinedConfigurationClass } from '@shared/types'
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, EMPTY, filter, from, map, Observable, of, shareReplay, startWith, Subject, Subscription, switchMap, tap } from 'rxjs'
import { DataQueryFilter } from '@shared/data/data-query-filter'
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort'
import { MentionConfig } from 'angular-mentions'
import { EntityUtils } from '@shared/bos/utils/entity-utils'
import { assocPath } from 'ramda'
import { GenericTableColumn } from './generic-table-column.directive'

let matSort!: MatSort

@Component({
    selector: 'lowgile-table',
    templateUrl: './generic-table.component.html',
    styleUrls: ['./generic-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MatSort, useFactory: () => {
			return matSort
		} }, // provide MatSort to the column definitions that are loaded through ContentChildren; without this provider, they wouldn't have access to MatSort
    ],
	exportAs: 'lowgileTable',
})
export class GenericTableComponent<T extends EntityInstance> implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	private _data: T[] | ((paging: PagingDefinition) => T[] | Promise<T[] | PagedData<T>>) = []
	@Input() set data(data: T[] | ((paging: PagingDefinition) => T[] | Promise<T[]>)) {
		this._data = data
		if(typeof data != 'function') {
			if(data !== this.dataSource.data) {
				this.dataSource.data = data
				this.paginator?.firstPage()
			}
		}
	}
	private onPaging?: (paging: PagingDefinition) => void

	@Input() entity!: any
	@Input() entitySubsetName: string = ''
	@Input() serverDataStore?: ServerDataStoreClass
    @Input() columns!: string[]
	@Input() trackBy!: TrackByFunction<T>
	@Input() showSettings: boolean = true
	@Input() userDefinedConfiguration?: UserDefinedConfigurationClass<TableViewConfigurations>
	@Input() usePaging: boolean = false
	@Input() pageSize: number = 20
	@Input() pageSizeOptions: number[] = [20]
	@Input() useGlobalFilter: boolean = false
	@Input() debouncingDelay: number = (this.entity && this.serverDataStore) ? 250 : 0
	@Input() stickyHeader: boolean = false
	@Input() set additionalFindOptions(options: FindManyOptions<T>) {
		if(JSON.stringify(options) == JSON.stringify(this._additionalFindOptions)) return
		this._additionalFindOptions = options
		this.reInit()
	}
	_additionalFindOptions?: FindManyOptions<T>
	@Input() allClasses: Record<string, Record<string, any>> = {}
	@Input() rowStyleFn?: (item: T, scope: any) => string
	@Input() surroundingScope: any
	@Output() rowClick = new EventEmitter<[Event, T, number]>()

	@ViewChild('header') headerEl!: ElementRef<HTMLTableRowElement>

    dataSource: MatTableDataSource<T> = new MatTableDataSource()
	entityClass!: EntityClassOnDb
	filterSubject = new BehaviorSubject<string>('')
	filter$ = this.filterSubject.pipe(
		debounceTime(this.debouncingDelay),
		startWith(''),
		distinctUntilChanged(),
	)
	columnFiltersSubject = new BehaviorSubject<Record<string, string>>({})
	columnFilters$ = this.columnFiltersSubject.pipe(
		debounceTime(this.debouncingDelay),
		distinctUntilChanged((prev, curr) => JSON.stringify(prev) == JSON.stringify(curr)),

	)
	pageSubject = new Subject<PageEvent>()
	sortSubject = new BehaviorSubject<Sort>({ active: '', direction: '' } as Sort)
	isSearchDisabled = false

	reloadTrigger$!: Observable<DataQuery<any>>
	subscriptions: Subscription[] = []

    @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>
    @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>
    @ContentChildren(GenericTableColumn) columnDefs!: QueryList<GenericTableColumn>
    // @ContentChild(TemplateRef) templateRef!: TemplateRef<any>
    @ViewChild(MatTable, { static: true }) table!: MatTable<T>
    @ViewChild(MatSort, { static: true }) sort!: MatSort
    @ViewChild('tableDiv') tableDivEl!: ElementRef<HTMLDivElement>
	@ViewChild(MatPaginator) paginator?: MatPaginator
	@ViewChild('pageNumberInput') pageNumberInputEl!: ElementRef<HTMLInputElement>

	configurations: TableViewConfigurations = {
		defaultView: '',
		views: {}
	}
	currentView = ''
	queryString = ''
  
	showColumns!: string[]
	showFilterColumns!: string[]
	filterMentionConfig: MentionConfig = { triggerChar: '#' }
	hiddenColumns: string[] = []
	isOverlayOpen = false
	isViewDirty = false

	isViewReady = false

	constructor(
		private cdRef: ChangeDetectorRef,
	) {}
  
	async ngOnInit() {
		this.entityClass = this.entitySubsetName ? (this.entity as any)?.[this.entitySubsetName] : this.entity
		if(!this.columns) this.columns = [...this.entityClass[EntityInfoSymbol].propertyNames]

		if(this.userDefinedConfiguration) {
			const configurations = await this.userDefinedConfiguration.get()
			if(configurations) {
				this.configurations = configurations
				this.currentView = configurations.defaultView ?? ''
			}
		}
		this.reloadView()

		matSort = new Proxy(this.sort, { // wrap MatSort with a proxy that effectively disables registration of MatSortHeaders that are already registered. Otherwise the overlay's draggable would re-register the column sorter, leading to an error
			get: (target, p, receiver) => {
				if(p == 'register') {
					return (sortable: MatSortHeader) => {
						if(target.sortables.has(sortable.id)) return
						target.register(sortable)
					}
				}
				return Reflect.get(target, p, receiver)
			},
		})
	}
	
	async ngAfterViewInit() {
		this.isViewReady = true
		return this.reInit()
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe())
	}

	private async reInit() {
		if(!this.isViewReady) return

		(globalThis as any).table = this.table;
		(globalThis as any).sort = this.sort

		const dataTriggers$ = combineLatest([
			this.filter$,
			this.columnFilters$,
			this.pageSubject,
			this.sortSubject,
		]).pipe(
			debounceTime(1), // prevent glitches when changing sorting and simultaneously resetting paging to page 1
			filter(() => !this.isSearchDisabled),
			map(([filter, columnFilters, page, sort]) => {
				const mergedFilter = this.mergeFilters(filter, columnFilters)
				return [mergedFilter, page, sort] as const
			}),
			distinctUntilChanged((prev, curr) => {
				if(prev[0] != curr[0]) return false
				if(prev[1].pageIndex != curr[1].pageIndex) return false
				if(prev[1].pageSize != curr[1].pageSize) return false
				if(prev[2].active != curr[2].active) return false
				if(prev[2].direction != curr[2].direction) return false
				return true
			}),
		)


		if(this.entityClass && this.serverDataStore) {
			const pagedData$ = dataTriggers$.pipe(
				switchMap(([mergedFilter, page, sort]) => {
					const propertyInfo = EntityUtils.getNestedPropertyInfo(this.entityClass, sort.active, this.allClasses)
					const sortObj = sort.active ? assocPath(propertyInfo?.chain ?? [sort.active], sort.direction, {}) : {}
					const order = {
						...sortObj, // execute twice to ensure it is the first property inside order and it overrides other sort properties
						...this._additionalFindOptions?.order,
						...sortObj, // execute twice to ensure it is the first property inside order and it overrides other sort properties
					}

					const pagedDataPromise = this.serverDataStore!.loadPageByQuery(this.entityClass, page, {
						query: mergedFilter,
					}, {
						...this._additionalFindOptions,
						order,
					})
					return from(pagedDataPromise).pipe(
						catchError(err => EMPTY),
					)
				}),
				shareReplay(1),
			)
			this.subscriptions.push(pagedData$.subscribe(pagedData => {
				this.dataSource.data = pagedData.list
				if(this.paginator) {
					this.paginator.length = pagedData.length
				}
			}))
			this.pageSubject.next({ pageIndex: 0, pageSize: this.pageSize, length: 0 })

		} else {
			if(typeof this._data == 'function') {
				const pagedData$ = dataTriggers$.pipe(
					switchMap(([mergedFilter, page, sort]) => {
						// const propertyInfo = EntityUtils.getNestedPropertyInfo(this.entityClass, sort.active, this.allClasses)
						// const sortObj = sort.active ? assocPath(propertyInfo?.propertyChain ?? [sort.active], sort.direction, {}) : {}
						// const order = {
						// 	...sortObj, // execute twice to ensure it is the first property inside order and it overrides other sort properties
						// 	...this._additionalFindOptions?.order,
						// 	...sortObj, // execute twice to ensure it is the first property inside order and it overrides other sort properties
						// }
	
						if(typeof this._data != 'function') return from([]) // should never occur
						let pagedDataPromise = this._data({
							pageSize: page.pageSize,
							pageIndex: page.pageIndex,
						})
						if(!(pagedDataPromise instanceof Promise)) pagedDataPromise = Promise.resolve(pagedDataPromise)

						return from(pagedDataPromise).pipe(
							map(data => {
								if(!Array.isArray(data)) return data
								return {
									list: data,
									length: data.length,
									pageStartIndex: 0,
									pageEndIndex: data.length - 1,
									pageSize: data.length,
									pageIndex: 0,
								} as PagedData<T>
							}),
							catchError(err => {
								console.error(err)
								return EMPTY
							}),
						)
					}),
					shareReplay(1),
				)
				this.subscriptions.push(pagedData$.subscribe(pagedData => {
					this.dataSource.data = pagedData.list
					if(this.paginator) {
						this.paginator.length = pagedData.length
					}
				}))
				this.pageSubject.next({ pageIndex: 0, pageSize: this.pageSize, length: 0 })

			} else {
				this.dataSource.paginator = this.paginator ?? null
				this.dataSource.sort = this.sort

				const columnValueFns = Object.fromEntries(this.columnDefs.map(colDef => [colDef.name, colDef.columnValueFn]))
				let cachedFilter: string
				let queryFilter: DataQueryFilter<EntityClassOnDb>
				
				this.dataSource.filterPredicate = (item, filter) => {
					if(filter !== cachedFilter || !queryFilter) {
						// cachedFilter = filter
						queryFilter = new DataQueryFilter(this.entity, {
							query: filter
						}, this.allClasses, columnValueFns)
					}
					return queryFilter.filterPredicate(item)
				}
	
				const mergedFilter$ = combineLatest([
					this.filter$,
					this.columnFilters$,
				]).pipe(
					filter(() => !this.isSearchDisabled),
					map(([filter, columnFilters]) => {
						const mergedFilter = this.mergeFilters(filter, columnFilters)
						return mergedFilter
					}),
					distinctUntilChanged(),
					shareReplay(1),
				)
				this.subscriptions.push(mergedFilter$.subscribe(mergedFilter => {
					this.dataSource.filter = mergedFilter
				}))
				this.pageSubject.next({ pageIndex: 0, pageSize: this.pageSize, length: 0 })
	
				this.dataSource.sortData = (items, sort) => {
					if(!sort.active || !sort.direction) return items
	
					const columnDef = this.columnDefs.find(cd => cd.name == sort.active)!
					let sortFn = columnDef?.columnValueFn
					if(!sortFn) sortFn = item => Reflect.get(item, columnDef.name)
	
					const valueMap = new Map<T, any>()
					for(const item of items) {
						valueMap.set(item, sortFn(item))
					}
	
					const directionFactor = sort.direction == 'desc' ? -1 : 1
	
					return items.sort((a, b) => {
						const aValue = valueMap.get(a)
						const bValue = valueMap.get(b)
						if(typeof aValue == 'string' && typeof bValue == 'string') {
							return aValue.localeCompare(bValue) * directionFactor
						}
	
						if(aValue?.[BoTypeSymbol] == 'StaticEntity' && bValue?.[BoTypeSymbol] == 'StaticEntity' && aValue.__type == bValue.__type) {
							const entryList = aValue.constructor.getEntryList() as any[]
							const aIdx = entryList.findIndex(item => item.id == aValue.id)
							const bIdx = entryList.findIndex(item => item.id == bValue.id)
							return (aIdx - bIdx) * directionFactor
						}
	
						if(aValue > bValue) return directionFactor
						if(aValue < bValue) return -directionFactor
						return 0
					})
				}
			}
			
		}

		// move page number to correct place (not implemented by MatPaginator yet)
		setTimeout(() => {
			const nextButtonEl = this.tableDivEl.nativeElement.querySelector('.mat-mdc-paginator-navigation-next')
			nextButtonEl?.before(this.pageNumberInputEl.nativeElement)
		})
	}

    ngAfterContentInit() {
		this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
		for(const col of this.columns) {
			let colDef = this.columnDefs.find(cd => cd.name == col)
			if(!colDef) {
				colDef = new GenericTableColumn(this.table)
				colDef.name = col
				this.table.addColumnDef(colDef)
			}
		}

		this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
		this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
    }

	onFilterRowChanged(event: Event) {
		if(event.target instanceof HTMLInputElement || event.target instanceof HTMLSelectElement) {
			const { filterForColumn } = event.target.dataset
			if(filterForColumn) {
				event.stopPropagation()
				this.columnFiltersSubject.next({
					...this.columnFiltersSubject.value,
					[filterForColumn]: event.target.value,
				})
			}
		}
	}

	async onPage(event: PageEvent) {
		this.pageSubject.next(event)
	}

	async jumpToPageNumber(pageNumber: number) {
		let pageIndex = pageNumber - 1
		if(Number.isNaN(pageIndex) || pageIndex < 0) pageIndex = 0

		this.paginator!.pageIndex = pageIndex
		this.onPage({
			pageIndex,
			pageSize: this.paginator!.pageSize,
			length: this.paginator!.length,
		})
	}

	onSort(sort: Sort) {
		if(!sort.direction) sort.active = ''
		this.paginator?.firstPage()
		this.sortSubject.next(sort)
		this.updateViewDirtyFlag()
	}


	toggleOverlay() {
		this.isOverlayOpen = !this.isOverlayOpen
		if(this.isOverlayOpen) this.updateViewDirtyFlag()
	}

	setSearchDisabled(disabled: boolean) {
		if(disabled) {
			this.isSearchDisabled = true
		} else {
			setTimeout(() => this.isSearchDisabled = false, 10) // make sure keyup event from selection of #columnName mention does not trigger search
		}
	}

	dropOverlayColumn(event: CdkDragDrop<string[]>) {
		if(event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
		} else {
			transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
		}
		
		this.showFilterColumns = this.showColumns.map(colName => `${colName}$filter`)
		this.updateViewDirtyFlag()
	}

	updateViewDirtyFlag() {
		const currentView: TableViewConfiguration = this.configurations.views[this.currentView] ?? {}
		const currentSorting = this.getCurrentSorting()

		this.isViewDirty = currentSorting.sortColumn != currentView.sortColumn
			|| currentSorting.sortDirection != currentView.sortDirection
			|| JSON.stringify(this.showColumns) != JSON.stringify(currentView.columns ?? this.columns)
			|| this.mergeFilters(this.filterSubject.value, this.columnFiltersSubject.value) != (currentView.filter ?? '')
	}

	getHeaderTemplate(columnName: string) {
		const colDefs = [...this.columnDefs]
		const colDef = colDefs.find(cd => cd.name == columnName)
		return colDef?.headerCell.template ?? null
	}

	private getCurrentSorting() {
		return {
			sortColumn: (this.sort.direction && this.sort.active) || undefined,
			sortDirection: this.sort.direction || undefined,
		} as const
	}

	async saveView() {
		await this.updateConfigAndSave(config => config.views[this.currentView] = {
			...config.views[this.currentView],
			columns: this.showColumns.slice(),
			...this.getCurrentSorting(),
			filter: this.mergeFilters(this.filterSubject.value, this.columnFiltersSubject.value),
		})
		this.isViewDirty = false
		this.cdRef.markForCheck()
	}

	async saveViewAs() {
		const viewName = prompt('View name', this.configurations?.defaultView ?? '')
		if(viewName) {
			this.currentView = viewName
			await this.saveView()
		}
	}

	async deleteView() {
		if(confirm(`Delete view "${this.currentView}"?`)) {
			await this.updateConfigAndSave(config => {
				delete config.views[this.currentView]
				if(config.defaultView == this.currentView) config.defaultView = ''
			})
			this.currentView = this.configurations.defaultView
			this.reloadView()
			this.cdRef.markForCheck()
		}
	}

	async makeDefaultView() {
		await this.updateConfigAndSave(config => config.defaultView = this.currentView)
	}

	reloadView() {
		const currentView = this.configurations?.views?.[this.currentView]
		this.showColumns = currentView?.columns?.slice() ?? this.columns.slice()
		this.showFilterColumns = this.showColumns.map(colName => `${colName}$filter`)
		this.hiddenColumns = this.columns.filter(c => !this.showColumns.includes(c))
		this.sort.active = currentView?.sortColumn ?? ''
		this.sort.direction = currentView?.sortDirection ?? ''
		this.sort.sortChange.emit({
			active: this.sort.active,
			direction: this.sort.direction,
		})
		this.filterSubject.next(currentView?.filter || '')
		this.isViewDirty = false
		this.cdRef.markForCheck()
	}

	protected mergeFilters(filter: string, columnFilters: Record<string, string>) {
		const filters = [
			filter,
			...Object.entries(columnFilters).filter(e => e[1]?.trim()).map(e => {
				let operator = DataQueryFilter.findOperatorAtStart(e[1])
				if(operator) {
					e[1] = e[1].substring(operator.length)
				} else {
					operator = ':'
				}
				return `${e[0]}${operator}"${e[1]}"`
			})
		]

		return filters.filter(Boolean).join(' ')
	}

	private async updateConfigAndSave(updateFn: (config: TableViewConfigurations) => void) {
		updateFn(this.configurations)

		if(this.userDefinedConfiguration) {
			const config = await this.userDefinedConfiguration.get()
			if(config != null) {
				updateFn(config)
				await this.userDefinedConfiguration.set(config)
			} else {
				await this.userDefinedConfiguration.set(this.configurations)
			}
		}
	}
}

import { Pipe, PipeTransform, SecurityContext } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { encode } from 'html-entities'
import { DevBarService } from '../services/dev-bar.service'
import { HtmlMode } from '@shared/blocks/other/text-block'

const cache = new Map<string, SafeHtml>()

@Pipe({
	name: 'escapeHtml'
})
export class EscapeHtmlPipe implements PipeTransform {
	constructor(
		private domSanitizer: DomSanitizer,
		private devBarService: DevBarService,
	) {}

	transform(value: any, htmlMode: HtmlMode = 'none') {
		if(!value) return value
		if(typeof value != 'string') value = String(value)
		const cacheKey = `${value}|${htmlMode}`
		if(cache.has(cacheKey)) return cache.get(cacheKey)!

		let sanitizedValue = ''

		if(htmlMode === 'unsafe') {
			return this.domSanitizer.bypassSecurityTrustHtml(value)
		}
		
		if(this.devBarService.canUseDevBar !== false) {
			// try to allow <span class="translation... without opening security holes
			// memorized tightly constrained onclick handler, encode the rest, then re-introduce handler
			interface Span {
				opening: string
				text: string
				closing: string
			}
			const translationSpans: Span[] = []
			const withoutTranslationSpans = (value as string).replace(
				// regex needs to closely match what the AngularLabelCompiler produces (see getLabel())
				/(?<opening><span class="translation[^"]*" title="[^"]*" onclick="event\.preventDefault\(\); event\.stopPropagation\(\); document\.dispatchEvent\(new CustomEvent\('translateLabel', [^\)]*\)\)">)(?<text>.*?)(?<closing><\/span>)/,
				(substring, ...args) => {
					const span = args.at(-1) as Span
					const idx = translationSpans.length
					translationSpans.push(span)

					return `###${idx}{###${span.text}###}${idx}###`
				}
			)

			const encoded = encode(withoutTranslationSpans)
			const withSpansReintroduced = encoded.replace(
				/###(?<idx>\d+)\{###(?<text>.*)###\}\1###/,
				(substring, ...args) => {
					const span = translationSpans[args[0]]
					return `${span.opening}${args[1]}${span.closing}`
				}
			)
			sanitizedValue = withSpansReintroduced
		} else {
			sanitizedValue = encode(value)
		}

		if(htmlMode === 'safe') {
			// subset of safe tags taken from https://github.com/cure53/DOMPurify/blob/main/src/tags.js
			sanitizedValue = sanitizedValue.replace(/&lt;(\/?)(b|i|u|br|code|h1|h2|h3|h4|h5|h6|hr|ins|ul|ol|li|p|pre|small|big|span|strike|strong|sub)&gt;/g, (_, slash, tag) => `<${slash}${tag}>`)
		}

		const safeHtml = this.domSanitizer.bypassSecurityTrustHtml(sanitizedValue)
		cache.set(cacheKey, safeHtml)
		return safeHtml
	}
}
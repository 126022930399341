// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    showPoSelection: boolean;
    ownedRequests: This.PurchaseRequest[];
    selectedRequest: This.PurchaseRequest;
    selectedRequestIdx: number;
    selectedPo: This.PurchaseOrder;
    invoice: This.PurchaseInvoice;
    pos: This.PurchaseOrder[];
    pdfPreviewUrl: string;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-EnterInvoice',
    templateUrl: './EnterInvoice.html',
    styleUrls: ['./EnterInvoice.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnterInvoice extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.EnterInvoice';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    @Output()
    submit = new EventEmitter<{
        invoice: This.PurchaseInvoice
        processInstanceId: number
    }>();
    @Output()
    save = new EventEmitter<This.PurchaseInvoice>();
    $output = {
        submit: async (data: {
            invoice: This.PurchaseInvoice
            processInstanceId: number
        }) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('submit', data)
            this.submit.emit(data)
        },
        save: async (data: This.PurchaseInvoice) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('save', data)
            this.save.emit(data)
        },
    };
    _DataTableBlock_columns_8 = (screen: Properties) => ['item', 'description', 'quantityOrdered', 'quantityInvoiced', 'unitPrice', 'totalPrice', 'comment'];
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        showPoSelection: this.showPoSelection !== undefined ? this.showPoSelection : (true) as boolean,
        ownedRequests: this.ownedRequests !== undefined ? this.ownedRequests : (undefined) as This.PurchaseRequest[],
        selectedRequest: this.selectedRequest !== undefined ? this.selectedRequest : (undefined) as This.PurchaseRequest,
        selectedRequestIdx: this.selectedRequestIdx !== undefined ? this.selectedRequestIdx : (undefined) as number,
        selectedPo: this.selectedPo !== undefined ? this.selectedPo : (undefined) as This.PurchaseOrder,
        invoice: this.invoice !== undefined ? this.invoice : (undefined) as This.PurchaseInvoice,
        pos: this.pos !== undefined ? this.pos : (undefined) as This.PurchaseOrder[],
        pdfPreviewUrl: this.pdfPreviewUrl !== undefined ? this.pdfPreviewUrl : (undefined) as string
    });

    get $inputs() {
        return {
            showPoSelection: this.showPoSelection,
            selectedPo: this.selectedPo,
            invoice: this.invoice
        }
    }

    @Input()
    get showPoSelection() {
        return this.screen?.showPoSelection
    }

    set showPoSelection(showPoSelection: boolean) {
        showPoSelection = (showPoSelection as any)?.[ProxyTargetSymbol] ?? showPoSelection
        this.storeService.setProperty([this.componentStore.name], 'showPoSelection', showPoSelection)
    }

    get ownedRequests() {
        return this.screen?.ownedRequests
    }

    set ownedRequests(ownedRequests: This.PurchaseRequest[]) {
        ownedRequests = (ownedRequests as any)?.[ProxyTargetSymbol] ?? ownedRequests
        this.storeService.setProperty([this.componentStore.name], 'ownedRequests', ownedRequests)
    }

    get selectedRequest() {
        return this.screen?.selectedRequest
    }

    set selectedRequest(selectedRequest: This.PurchaseRequest) {
        selectedRequest = (selectedRequest as any)?.[ProxyTargetSymbol] ?? selectedRequest
        this.storeService.setProperty([this.componentStore.name], 'selectedRequest', selectedRequest)
    }

    get selectedRequestIdx() {
        return this.screen?.selectedRequestIdx
    }

    set selectedRequestIdx(selectedRequestIdx: number) {
        selectedRequestIdx = (selectedRequestIdx as any)?.[ProxyTargetSymbol] ?? selectedRequestIdx
        this.storeService.setProperty([this.componentStore.name], 'selectedRequestIdx', selectedRequestIdx)
    }

    @Input()
    get selectedPo() {
        return this.screen?.selectedPo
    }

    set selectedPo(selectedPo: This.PurchaseOrder) {
        selectedPo = (selectedPo as any)?.[ProxyTargetSymbol] ?? selectedPo
        this.storeService.setProperty([this.componentStore.name], 'selectedPo', selectedPo)
    }

    @Input()
    get invoice() {
        return this.screen?.invoice
    }

    set invoice(invoice: This.PurchaseInvoice) {
        invoice = (invoice as any)?.[ProxyTargetSymbol] ?? invoice
        this.storeService.setProperty([this.componentStore.name], 'invoice', invoice)
    }

    get pos() {
        return this.screen?.pos
    }

    set pos(pos: This.PurchaseOrder[]) {
        pos = (pos as any)?.[ProxyTargetSymbol] ?? pos
        this.storeService.setProperty([this.componentStore.name], 'pos', pos)
    }

    get pdfPreviewUrl() {
        return this.screen?.pdfPreviewUrl
    }

    set pdfPreviewUrl(pdfPreviewUrl: string) {
        pdfPreviewUrl = (pdfPreviewUrl as any)?.[ProxyTargetSymbol] ?? pdfPreviewUrl
        this.storeService.setProperty([this.componentStore.name], 'pdfPreviewUrl', pdfPreviewUrl)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.showPoSelection = this.dialogData.showPoSelection ?? this.showPoSelection
            this.selectedPo = this.dialogData.selectedPo ?? this.selectedPo
            this.invoice = this.dialogData.invoice ?? this.invoice
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.selectedRequestIdx = undefined
                θstate.screen.pos = undefined

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_DropdownBlock_0_0_0_0_0_0_0_0_selectionChange_onRequestSelected_0($event: any, $scope: any): Promise<void> {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let selection: any = $event
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onRequestSelected($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_DropdownBlock_0_0_0_0_0_1_0_0_selectionChange_onPoSelected_0($event: any, $scope: any): Promise<void> {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let selection: any = $event
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onPoSelected($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_LinkBlock_0_0_1_0_0_0_0_0_click_removeInvoiceDocument_0($event: any, $scope: any): Promise<void> {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_removeInvoiceDocument($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_FileUploadBlock_0_0_1_0_0_0_2_0_filesSelected_onInvoiceUploaded_0($event: any, $scope: any): Promise<void> {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        let files: File[] = [...$event]
        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_onInvoiceUploaded(files, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_8($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return (screen.invoice.items)
    }

    _DataTableBlock_additionalFindOptions_8($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_9_9($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_72_72($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return (invoiceItem.description)
    }

    _DataTableColumnBlock_cellText_10_10($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return (invoiceItem.purchaseOrderItem.quantity + ' ' + invoiceItem.purchaseOrderItem.unit.label)
    }

    _DataTableColumnBlock_cellText_11_11($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_69_69($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return ''
    }

    _DataTableColumnBlock_cellText_82_82($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        'Cell content'
    }

    _DataTableColumnBlock_cellText_21_21($scope: any): any {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled, invoiceItem, invoiceItemIdx }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIdx: number } = $scope ?? {}

        return ''
    }

    async handler_ButtonBlock_0_0_1_2_0_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { showPoSelection, ownedRequests, selectedRequest, selectedRequestIdx, selectedPo, invoice, pos, pdfPreviewUrl, app, screen, $pathSegments, $disabled }: { showPoSelection: boolean, ownedRequests: This.PurchaseRequest[], selectedRequest: This.PurchaseRequest, selectedRequestIdx: number, selectedPo: This.PurchaseOrder, invoice: This.PurchaseInvoice, pos: This.PurchaseOrder[], pdfPreviewUrl: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_submit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.invoice.items.forEach(item => {
                item.purchaseOrderItemId = item.purchaseOrderItem.id
                item.purchaseOrderItem = undefined
            })

            await output.submit({
                invoice: screen.invoice,
                processInstanceId: screen.selectedRequest.processInstanceId,
            })
        }
    }

    async action_save($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            output.save(screen.invoice)
        }
    }

    async action_onRequestSelected($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.selectedRequest = await This.Db.loadById(This.PurchaseRequest, screen.ownedRequests[screen.selectedRequestIdx].id, {
                relations: {
                    items: true,
                    vendorOffers: {
                        purchaseOrders: {
                            items: true,
                        }
                    }
                }
            })
            screen.pos = screen.selectedRequest.vendorOffers.flatMap(offer => offer.purchaseOrders)
            screen.selectedPo = undefined
            screen.invoice = undefined
        }
    }

    async action_onPoSelected($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            // const items = screen.selectedPo.items.map(poItem => new This.PurchaseInvoiceItem({
            //     purchaseOrderItem: poItem,
            //     quantity: poItem.quantity,
            //     unitPrice: poItem.unitPrice,
            //     description: poItem.description,
            // }))

            // const invoiceNumber = Sys.Text.calculateNextFreeName(screen.selectedPo.poNumber.replace('PO', 'INV') + '-', screen.selectedPo.invoices ?? [], inv => inv.invoiceNumber, 2, true)
            // screen.invoice = new This.PurchaseInvoice({
            //     purchaseOrderId: screen.selectedPo.id,
            //     date: new Date(),
            //     invoiceNumber,
            //     items,
            // })
        }
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            if (screen.showPoSelection) {
                screen.ownedRequests = await This.Db.loadByCondition(This.PurchaseRequest, {
                    where: {
                        requestorUserId: Sys.User.currentUser?.id,
                    },
                })
            }

        }
    }

    async action_onInvoiceUploaded(files: File[], $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        files = this.storeService.replaceWithStateVersion(files, $context)
        {
            const [file] = files
            if (file) {
                const base64 = Sys.Text.convert(await file.arrayBuffer(), 'binary', 'base64')
                try {
                    Sys.App.isSpinnerVisible = true

                    screen.invoice = await This.PurchaseInvoice.parseUpload(base64, screen.selectedPo)
                    for (const invoiceItem of screen.invoice.items) {
                        invoiceItem.purchaseOrderItem = screen.selectedPo.items.find(poItem => poItem.id == invoiceItem.purchaseOrderItemId)
                    }

                    screen.invoice.fileContent = base64
                    screen.invoice.fileName = file.name
                    screen.invoice.invoiceNumber = Sys.Text.calculateNextFreeName(screen.selectedPo.poNumber.replace('PO', 'INV') + '-', screen.selectedPo.invoices ?? [], inv => inv.invoiceNumber, 2, true)
                    screen.pdfPreviewUrl = URL.createObjectURL(file)
                } finally {
                    Sys.App.isSpinnerVisible = false
                }
            }

        }
    }

    async action_removeInvoiceDocument($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.invoice.fileContent = undefined
            screen.invoice.fileName = ''
        }
    }
}

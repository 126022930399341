// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseInvoiceItem {
    @jsonMember(() => PurchaseToPay__PurchaseInvoiceItem.Id)
    id: PurchaseToPay__PurchaseInvoiceItem.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "comment", "quantity", "unitPrice", "description", "invoice", "invoiceId", "purchaseOrderItem", "purchaseOrderItemId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "comment": "string", "quantity": "number", "unitPrice": "number", "description": "string", "invoice": "PurchaseToPay.PurchaseInvoice", "invoiceId": "PurchaseToPay.PurchaseInvoice.Id", "purchaseOrderItem": "PurchaseToPay.PurchaseOrderItem", "purchaseOrderItemId": "PurchaseToPay.PurchaseOrderItem.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ invoice: 'PurchaseToPay.PurchaseInvoice' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseOrderItem: 'PurchaseToPay.PurchaseOrderItem' } as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θcomment?: string = undefined;
    private θquantity?: number = undefined;
    private θunitPrice?: number = undefined;
    private θdescription?: string = undefined;
    private θinvoice?: PurchaseToPay.PurchaseInvoice = undefined;
    private θinvoiceId?: number = null;
    private θpurchaseOrderItem?: PurchaseToPay.PurchaseOrderItem = undefined;
    private θpurchaseOrderItemId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        comment: 0,
        quantity: 0,
        unitPrice: 0,
        description: 0,
        invoice: 0,
        invoiceId: 0,
        purchaseOrderItem: 0,
        purchaseOrderItemId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        comment: () => {
            return false
        }
        , quantity: () => {
            return false
        }
        , unitPrice: () => {
            return false
        }
        , description: () => {
            return false
        }
        , invoice: () => {
            return false
        }
        , invoiceId: () => {
            return false
        }
        , purchaseOrderItem: () => {
            return false
        }
        , purchaseOrderItemId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseInvoiceItem' {
        return 'PurchaseToPay.PurchaseInvoiceItem'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseInvoiceItem' {
        return 'PurchaseToPay.PurchaseInvoiceItem'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseInvoiceItem' {
        return 'PurchaseToPay.PurchaseInvoiceItem'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseInvoiceItem' {
        return 'PurchaseToPay.PurchaseInvoiceItem'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseInvoiceItem[EntityInfoSymbol]
    }

    get comment() {
        return this.θcomment
    }

    set comment(comment: string) {
        this.θcomment = LowgileDataUtil.coerceDataType(comment, 'string', false, true)
    }

    get quantity() {
        return this.θquantity
    }

    set quantity(quantity: number) {
        this.θquantity = LowgileDataUtil.coerceDataType(quantity, 'number', false, true)
    }

    get unitPrice() {
        return this.θunitPrice
    }

    set unitPrice(unitPrice: number) {
        this.θunitPrice = LowgileDataUtil.coerceDataType(unitPrice, 'number', false, true)
    }

    get description() {
        return this.θdescription
    }

    set description(description: string) {
        this.θdescription = LowgileDataUtil.coerceDataType(description, 'string', false, true)
    }

    get invoice() {
        return this.θinvoice
    }

    set invoice(invoice: PurchaseToPay.PurchaseInvoice) {
        this.θinvoice = LowgileDataUtil.coerceDataType(invoice, PurchaseToPay.PurchaseInvoice, false, true)
    }

    get invoiceId() {
        return this.θinvoiceId
    }

    set invoiceId(invoiceId: number) {
        this.θinvoiceId = LowgileDataUtil.coerceDataType(invoiceId, 'number', false, true)
    }

    get purchaseOrderItem() {
        return this.θpurchaseOrderItem
    }

    set purchaseOrderItem(purchaseOrderItem: PurchaseToPay.PurchaseOrderItem) {
        this.θpurchaseOrderItem = LowgileDataUtil.coerceDataType(purchaseOrderItem, PurchaseToPay.PurchaseOrderItem, false, true)
    }

    get purchaseOrderItemId() {
        return this.θpurchaseOrderItemId
    }

    set purchaseOrderItemId(purchaseOrderItemId: number) {
        this.θpurchaseOrderItemId = LowgileDataUtil.coerceDataType(purchaseOrderItemId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseInvoiceItem.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseInvoiceItem>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseInvoiceItem(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseInvoiceItem>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseInvoiceItem>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('comment', 'quantity', 'unitPrice', 'description', 'invoice', 'invoiceId', 'purchaseOrderItem', 'purchaseOrderItemId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseInvoiceItem { }

export import PurchaseInvoiceItem = PurchaseToPay__PurchaseInvoiceItem
setTimeout(() => {
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'comment')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'quantity')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'unitPrice')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'description')
    jsonMember(() => PurchaseToPay.PurchaseInvoice)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'invoice')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'invoiceId')
    jsonMember(() => PurchaseToPay.PurchaseOrderItem)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'purchaseOrderItem')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'purchaseOrderItemId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseInvoiceItem.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseInvoiceItem {
}

export const PurchaseInvoiceItemθId = Number;

export type PurchaseInvoiceItemθId = number;

namespace PurchaseToPay__PurchaseInvoiceItem {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseInvoiceItem'>
    export const Id = Number
}

import { Injectable, Injector, inject } from '@angular/core'
import * as Types from '@shared/types'
import { BehaviorSubject, Observable, ReplaySubject, Subject, filter, from, map, merge, of, shareReplay, tap } from 'rxjs'
import { AppTrpcServiceInjectionToken } from './app-trpc.service'
import { BaseTrpcService } from './base-trpc.service'
import { toSignal } from '@angular/core/rxjs-interop'

@Injectable({
	providedIn: 'root',
})
export class CsrfService {
	private forgetCsrfTokenSubject = new Subject<void>()
	
	readonly csrfToken$ = merge(
		from(fetch('/__csrfToken')).pipe(
			map(response => response.headers.get(Types.CsrfHeaderName) ?? ''),
		),
		this.forgetCsrfTokenSubject.pipe(map(() => '')),
	).pipe(
		shareReplay(1),
	)
	readonly csrfToken = toSignal(this.csrfToken$)
	readonly isLoaded$ = this.csrfToken$.pipe(
		filter(token => !!token),
		map(() => {
			// console.log('CSRF token is loaded')
			return undefined
		}),
		shareReplay(1),
	)

	forgetCsrfToken() {
		this.forgetCsrfTokenSubject.next()
	}
}
export function escapeQuotes(expression: any) {
	if (expression === undefined) return ''
	return expression.toString().replace(/(["])/g, '\\$1')
}

export function capitalizeFirstLetter(text: string) {
	if(!text) return text
	return text.charAt(0).toUpperCase() + text.slice(1)
}


export function uncapitalizeFirstLetter(text: string) {
	if(!text) return text
	return text.charAt(0).toLowerCase() + text.slice(1)
}

export function sentenceCase(text: string, capitalizeWords = false, capitalizeFirstWord = true) {
	text = text.replace(/[A-Z]/g, c => capitalizeWords ? c : uncapitalizeFirstLetter(c))
	text = text.replace(/(^| ) +/g, '')
	
	if(capitalizeFirstWord) {
		return capitalizeFirstLetter(text)
	}
	return text
}

export function parseRegExp(regexpAsString: string) {
	const matches = regexpAsString.match(/^\/(.*)\/(.*?)$/)
	return new RegExp(matches?.[1]!, matches?.[2])
}

export function calculateNextFreeName<T>(desiredName: string | ((suffix: string) => string), existingItems: T[], nameMapping: (item: T) => string, suffixWithAtLeastDigits = 0, needsToBeLargerThanAllExisting = false) {
	const nameFn = typeof desiredName == 'string'
		? (suffix: string) => `${desiredName}${suffix}`
		: desiredName
	const suffixFn = suffixWithAtLeastDigits
		? (suffixNumber: number) => String(suffixNumber).padStart(suffixWithAtLeastDigits, '0')
		: (suffixNumber: number) => String(suffixNumber)

	const existingNames = existingItems.map(nameMapping)
	
	let counter = 1
	if(needsToBeLargerThanAllExisting) {
		const largestName = existingNames.sort().at(-1)
		if(largestName !== undefined) {
			const largestNumber = parseInt('0' + largestName.replace(/.*?([\d]*)$/, '$1'))
			if(!isNaN(largestNumber)) counter = largestNumber + 1
		}
	}
	
	let name = nameFn(suffixWithAtLeastDigits ? suffixFn(1) : '')
	if(!suffixWithAtLeastDigits && !existingNames.includes(name)) {
		return name
	}

	do {
		name = nameFn(suffixFn(counter++))
	} while(existingItems.find(item => nameMapping(item) == name))

	return name
}

interface RandomStringOptions {
	/** A string containing all allowed characters or an object specifying allowed character classes */
	alphabet?: string | {
		/** If true, lower-case characters are included (default: false) */
		lowerCase?: boolean,
		/** If true, upper-case characters are included (default: false) */
		upperCase?: boolean,
		/** If true, digits are included (default: false) */
		digits?: boolean,
		/** If true, special characters -+"*%&/()=?@#$<> are included (default: false). If a string, these exact characters are included */
		specialCharacters?: boolean | string,
	}
	/** If true, ambiguous characters `iIlLoO01` are excluded (default: true if options.alphabet.lowerCase or options.alphabet.lowerCase is true) */
	removeAmbiguousCharacters?: boolean | string
	/** A string containing specific characters that should be removed from the alphabet (e.g. if you're happy with the alphabet but the $ sign is not allowed in passwords) */
	removeCharactersFromAlphabet?: string
	/** If true and options.alphabet is an object, the generated string will always contain all selected character classes, which is important to satisfy password complexity rules (default: true) */
	ensureAllAlphabetTypesAreUsed?: boolean
	/** Object to specify if the generated string should be arranged in groups (e.g. `555-123-4567`) */
	group?: {
		/** If a number, a separator will be inserted after each group of {size} characters, e.g. for 2: `12-34-56-78-90-12-34`. If an array of numbers, these exact groups will be created, e.g. for [2,2,4]: `12-34-5678-901234`. The separators do not count towards the desired length of the string */
		size: number | number[]
		/** Separator to be used; can be one of more characters */
		separator: string
	}
}

/** Generates a random string, which can be used for default passwords, random data, download tokens, etc.
 * @param length - Desired length of string
 * @param options - Options for string generation. If omitted, upper/lower case, digits and special characters are used; ambiguous characters are excluded
 */
export function generateRandomString(length: number, options?: RandomStringOptions) {
	length = Number(length)
	const alphabet: RandomStringOptions['alphabet'] = typeof options?.alphabet == 'string' ? options.alphabet
		: options?.alphabet ? options.alphabet
		: {
			lowerCase: true,
			upperCase: true,
			digits: true,
			specialCharacters: true,
		}

	options = {
		removeAmbiguousCharacters: typeof options?.alphabet == 'object' && (options?.alphabet.lowerCase || options?.alphabet.upperCase),
		ensureAllAlphabetTypesAreUsed: true,
		...options,
		alphabet
	}
	let characterClasses: string[] = []

	if(typeof alphabet == 'object') {
		if(alphabet.lowerCase) characterClasses.push('abcdefghijklmnopqrstuvwxyz')
		if(alphabet.upperCase) characterClasses.push('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
		if(alphabet.digits) characterClasses.push('0123456789')
		if(alphabet.specialCharacters === true) characterClasses.push(`-+"*%&/()=?@#$<>`)
		if(typeof alphabet.specialCharacters == 'string') characterClasses.push(alphabet.specialCharacters)

	} else {
		characterClasses.push(alphabet)
	}

	let charactersToRemove = options.removeCharactersFromAlphabet ?? ''
	if(options.removeAmbiguousCharacters) {
		if(options.removeAmbiguousCharacters === true) {
			charactersToRemove += 'iIlLoO01'
		} else {
			charactersToRemove += options.removeAmbiguousCharacters
		}
	}
	for(const charToRemove of charactersToRemove) {
		characterClasses = characterClasses.map(characters => characters.replaceAll(charToRemove, ''))
	}

	for(const characterClass of characterClasses) {
		if(!characterClass && options.ensureAllAlphabetTypesAreUsed) {
			throw new Error('Cannot ensure all character classes are present in random string because at least one character class is empty')
		}
	}
	if(length < characterClasses.length) throw new Error('Cannot create a random string with a shorter length than the number of guaranteed character classes')
	
	const alphabetStr = [...new Set(characterClasses.join('').split(''))].join('') // merge character classes and remove duplicates
	if(alphabetStr.length < 2) throw new Error('Cannot create a random string using an alphabet of size <2')

	let str = ''
	let isValid = false

	untilIsValid:
	while(!isValid) {
		str = Array(length).fill(0).map(_ => {
			const charIdx = Math.floor(Math.random() * alphabetStr.length)
			return alphabetStr[charIdx]
		}).join('')

		if(options.ensureAllAlphabetTypesAreUsed) {
			for(const characterClass of characterClasses) {
				const regex = new RegExp(`[${characterClass}]`)
				if(!regex.test(str)) continue untilIsValid
			}
		}
		isValid = true
	}

	if(options.group) {
		const characters = str.split('')

		if(options.group.size instanceof Array) {
			let cumulatedSize = 0
			options.group.size.forEach((size, idx) => {
				cumulatedSize += size
				if(cumulatedSize + idx < length) {
					characters.splice(cumulatedSize + idx, 0, options!.group!.separator)
				}
			})
		} else {
			for(let i=options.group.size; i<characters.length; i += options.group.size + 1) {
				characters.splice(i, 0, options.group.separator)
			}
		}
		str = characters.join('')
	}

	return str
}

export function stringifyForLog(args: any[]) {
	return args.map(arg => {
		if(typeof arg == 'string') return arg
		if(typeof arg == 'object') return JSON.stringify(arg)
		return String(arg)
	}).join(' ')
}
// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    request: This.PurchaseRequest;
    grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>;
    invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>;
    invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>;
    poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>;
    isAllPosClosed: boolean;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-AwaitDeliveryAndPayment',
    templateUrl: './AwaitDeliveryAndPayment.html',
    styleUrls: ['./AwaitDeliveryAndPayment.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AwaitDeliveryAndPayment extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.AwaitDeliveryAndPayment';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    @Output()
    submit = new EventEmitter<This.PurchaseRequest>();
    @Output()
    save = new EventEmitter<This.PurchaseRequest>();
    $output = {
        submit: async (data: This.PurchaseRequest) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('submit', data)
            this.submit.emit(data)
        },
        save: async (data: This.PurchaseRequest) => {
            data = (data as any)?.θclone ? (data as any).θclone() : data
            await Sys.Process.driveProcessAndFollowNextUserTask('save', data)
            this.save.emit(data)
        },
    };
    _DataTableBlock_columns_57 = (screen: Properties) => ['lineNumber', 'description', 'quantityOrdered', 'quantityReceived', 'quantityRejected', 'quantityOpen'];
    _DataTableBlock_columns_64 = (screen: Properties) => ['lineNumber', 'description', 'quantityReceived', 'quantityRejected', 'comment', 'matchStatus'];
    _DataTableBlock_columns_101 = (screen: Properties) => ['id', 'description', 'quantity', 'unitPrice', 'matchStatus'];
    $validators: Record<number, ValidationRule<any>[]> = {

    };
    componentStore = this.storeService.createComponentStore<Properties>({
        request: this.request !== undefined ? this.request : (undefined) as This.PurchaseRequest,
        grnItemMatchMap: this.grnItemMatchMap !== undefined ? this.grnItemMatchMap : (undefined) as Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>,
        invoiceItemMatchMap: this.invoiceItemMatchMap !== undefined ? this.invoiceItemMatchMap : (undefined) as Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>,
        invoiceFullyMatchedMap: this.invoiceFullyMatchedMap !== undefined ? this.invoiceFullyMatchedMap : (undefined) as Record<This.PurchaseInvoice.Id, boolean>,
        poFullyMatchedMap: this.poFullyMatchedMap !== undefined ? this.poFullyMatchedMap : (undefined) as Record<This.PurchaseOrder.Id, boolean>,
        isAllPosClosed: this.isAllPosClosed !== undefined ? this.isAllPosClosed : (undefined) as boolean
    });

    get $inputs() {
        return {
            request: this.request
        }
    }

    @Input()
    get request() {
        return this.screen?.request
    }

    set request(request: This.PurchaseRequest) {
        request = (request as any)?.[ProxyTargetSymbol] ?? request
        this.storeService.setProperty([this.componentStore.name], 'request', request)
    }

    get grnItemMatchMap() {
        return this.screen?.grnItemMatchMap
    }

    set grnItemMatchMap(grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>) {
        grnItemMatchMap = (grnItemMatchMap as any)?.[ProxyTargetSymbol] ?? grnItemMatchMap
        this.storeService.setProperty([this.componentStore.name], 'grnItemMatchMap', grnItemMatchMap)
    }

    get invoiceItemMatchMap() {
        return this.screen?.invoiceItemMatchMap
    }

    set invoiceItemMatchMap(invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>) {
        invoiceItemMatchMap = (invoiceItemMatchMap as any)?.[ProxyTargetSymbol] ?? invoiceItemMatchMap
        this.storeService.setProperty([this.componentStore.name], 'invoiceItemMatchMap', invoiceItemMatchMap)
    }

    get invoiceFullyMatchedMap() {
        return this.screen?.invoiceFullyMatchedMap
    }

    set invoiceFullyMatchedMap(invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>) {
        invoiceFullyMatchedMap = (invoiceFullyMatchedMap as any)?.[ProxyTargetSymbol] ?? invoiceFullyMatchedMap
        this.storeService.setProperty([this.componentStore.name], 'invoiceFullyMatchedMap', invoiceFullyMatchedMap)
    }

    get poFullyMatchedMap() {
        return this.screen?.poFullyMatchedMap
    }

    set poFullyMatchedMap(poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>) {
        poFullyMatchedMap = (poFullyMatchedMap as any)?.[ProxyTargetSymbol] ?? poFullyMatchedMap
        this.storeService.setProperty([this.componentStore.name], 'poFullyMatchedMap', poFullyMatchedMap)
    }

    get isAllPosClosed() {
        return this.screen?.isAllPosClosed
    }

    set isAllPosClosed(isAllPosClosed: boolean) {
        isAllPosClosed = (isAllPosClosed as any)?.[ProxyTargetSymbol] ?? isAllPosClosed
        this.storeService.setProperty([this.componentStore.name], 'isAllPosClosed', isAllPosClosed)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {
            this.request = this.dialogData.request ?? this.request
        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }


                θstate.screen.grnItemMatchMap = undefined
                θstate.screen.invoiceItemMatchMap = undefined
                θstate.screen.invoiceFullyMatchedMap = undefined
                θstate.screen.poFullyMatchedMap = undefined
                θstate.screen.isAllPosClosed = undefined

                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_ButtonBlock_0_0_0_0_0_0_0_0_0_0_0_1_0_click_togglePoClosed_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_togglePoClosed(po.id, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_0_0_0_0_0_0_0_0_1_1_0_click_togglePoClosed_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_togglePoClosed(po.id, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_57($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}

        return (po.items)
    }

    _DataTableBlock_additionalFindOptions_57($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_61_61($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return (poItem.lineNumber)
    }

    _DataTableColumnBlock_cellText_58_58($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return (poItem.description)
    }

    _DataTableColumnBlock_cellText_59_59($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return (poItem.quantity + ' ' + poItem.unit.label)
    }

    _DataTableColumnBlock_cellText_60_60($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return (Sys.Math.sumMap(po.purchaseGoodsReceiptNotes, grn => grn.items.find(grnItem => grnItem.purchaseOrderItemId == poItem.id)?.quantityReceived) + ' ' + poItem.unit.label)
    }

    _DataTableColumnBlock_cellText_62_62($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return (Sys.Math.sumMap(po.purchaseGoodsReceiptNotes, grn => grn.items.find(grnItem => grnItem.purchaseOrderItemId == poItem.id)?.quantityRejected) + ' ' + poItem.unit.label)
    }

    _DataTableColumnBlock_cellText_159_159($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, poItem, poItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, poItem: This.PurchaseOrderItem, poItemIndex: number } = $scope ?? {}

        return ((poItem.quantity - Sys.Math.sumMap(po.purchaseGoodsReceiptNotes, grn => grn.items.find(grnItem => grnItem.purchaseOrderItemId == poItem.id)?.getQuantityAccepted())) + ' ' + poItem.unit.label)
    }

    _DataTableBlock_collectionCode_64($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number } = $scope ?? {}

        return (grn.items)
    }

    _DataTableBlock_additionalFindOptions_64($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_65_65($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return (grnItem.lineNumber)
    }

    _DataTableColumnBlock_cellText_66_66($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return (grnItem.description)
    }

    _DataTableColumnBlock_cellText_68_68($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return (grnItem.quantityReceived + ' ' + grnItem.unit.label)
    }

    _DataTableColumnBlock_cellText_69_69($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return (grnItem.quantityRejected + ' ' + grnItem.unit.label)
    }

    _DataTableColumnBlock_cellText_75_75($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return (grnItem.comment)
    }

    _DataTableColumnBlock_cellText_119_119($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, grn, grnIndex, grnItem, grnItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, grn: This.PurchaseGoodsReceiptNote, grnIndex: number, grnItem: This.PurchaseGoodsReceiptNoteItem, grnItemIndex: number } = $scope ?? {}

        return ''
    }

    async handler_ButtonBlock_0_0_0_0_0_0_0_0_0_0_1_2_0_0_0_0_1_0_0_click_toggleInvoiceRevoked_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_toggleInvoiceRevoked(invoice.id, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_0_0_0_0_0_0_0_1_2_0_0_0_0_1_0_1_0_click_toggleInvoiceRevoked_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_toggleInvoiceRevoked(invoice.id, $context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    _DataTableBlock_collectionCode_101($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number } = $scope ?? {}

        return (invoice.items)
    }

    _DataTableBlock_additionalFindOptions_101($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number } = $scope ?? {}

        return ({})
    }

    _DataTableColumnBlock_cellText_109_109($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex, invoiceItem, invoiceItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIndex: number } = $scope ?? {}

        return (invoiceItemIndex + 1)
    }

    _DataTableColumnBlock_cellText_103_103($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex, invoiceItem, invoiceItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIndex: number } = $scope ?? {}

        return (invoiceItem.description)
    }

    _DataTableColumnBlock_cellText_104_104($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex, invoiceItem, invoiceItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIndex: number } = $scope ?? {}

        return (invoiceItem.quantity)
    }

    _DataTableColumnBlock_cellText_106_106($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex, invoiceItem, invoiceItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIndex: number } = $scope ?? {}

        return (invoiceItem.unitPrice + ' ' + invoice.currency.label)
    }

    _DataTableColumnBlock_cellText_114_114($scope: any): any {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled, offer, offerIndex, po, poIndex, invoice, invoiceIndex, invoiceItem, invoiceItemIndex }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean, offer: This.PurchaseVendorOffer, offerIndex: number, po: This.PurchaseOrder, poIndex: number, invoice: This.PurchaseInvoice, invoiceIndex: number, invoiceItem: This.PurchaseInvoiceItem, invoiceItemIndex: number } = $scope ?? {}

        return ''
    }

    async handler_ButtonBlock_0_0_0_1_0_0_0_click_submit_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_submit($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async handler_ButtonBlock_0_0_0_1_1_click_save_0($event: any, $scope: any): Promise<void> {
        let { request, grnItemMatchMap, invoiceItemMatchMap, invoiceFullyMatchedMap, poFullyMatchedMap, isAllPosClosed, app, screen, $pathSegments, $disabled }: { request: This.PurchaseRequest, grnItemMatchMap: Record<This.PurchaseGoodsReceiptNoteItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceItemMatchMap: Record<This.PurchaseInvoiceItem.Id, { type: 'partial' | 'full', quantity: number, of: number }>, invoiceFullyMatchedMap: Record<This.PurchaseInvoice.Id, boolean>, poFullyMatchedMap: Record<This.PurchaseOrder.Id, boolean>, isAllPosClosed: boolean, app: any, screen: any, $pathSegments: string[], $disabled: boolean } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_save($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_submit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            await output.submit(screen.request)
        }
    }

    async action_save($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            output.save(screen.request)
        }
    }

    async action_doMatching($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.grnItemMatchMap = {}
            screen.invoiceItemMatchMap = {}
            screen.invoiceFullyMatchedMap = {}
            screen.poFullyMatchedMap = {}

            for (const offer of screen.request.vendorOffers) {
                for (const po of offer.purchaseOrders) {
                    const grnItems = po.purchaseGoodsReceiptNotes.flatMap(grn => grn.items)
                    const invoiceItems = po.invoices.flatMap(invoice => invoice.isRevoked ? [] : invoice.items)

                    type Availability = { received: number, receivedOf?: number, invoiced: number, invoicedOf?: number }
                    const availabilityMap = new Map<This.PurchaseOrderItem.Id, Availability>()
                    for (const poItem of po.items) {
                        const matchingGrnItems = grnItems.filter(grnItem => grnItem.purchaseOrderItemId == poItem.id)
                        const matchingInvoiceItems = invoiceItems.filter(invoiceItem => invoiceItem.purchaseOrderItemId == poItem.id)
                        const availability: Availability = {
                            received: matchingGrnItems.reduce((acc, item) => acc + item.getQuantityAccepted(), 0),
                            invoiced: matchingInvoiceItems.reduce((acc, item) => acc + item.quantity, 0),
                        }
                        availability.receivedOf = availability.received
                        availability.invoicedOf = availability.invoiced

                        availabilityMap.set(poItem.id, availability)
                    }

                    for (const invoiceItem of invoiceItems) {
                        const availability = availabilityMap.get(invoiceItem.purchaseOrderItemId)
                        if (!availability) {
                            console.error('Availability not found for invoice item', invoiceItem)
                        }
                        const claimedQuantity = Math.min(invoiceItem.quantity, availability.received)
                        screen.invoiceItemMatchMap[invoiceItem.id] = { quantity: claimedQuantity, type: claimedQuantity == invoiceItem.quantity ? 'full' : 'partial', of: invoiceItem.quantity }
                        availability.received -= claimedQuantity
                    }
                    for (const invoice of po.invoices) {
                        const fullyMatched = invoice.items.every(invoiceItem => screen.invoiceItemMatchMap[invoiceItem.id]?.type == 'full')
                        screen.invoiceFullyMatchedMap[invoice.id] = fullyMatched
                        if (fullyMatched) {
                            invoice.paymentStatus?.setApproved()
                        }
                    }

                    for (const grnItem of grnItems) {
                        const availability = availabilityMap.get(grnItem.purchaseOrderItemId)
                        if (!availability) {
                            console.error('Availability not found for goods receipt item', grnItem)
                        }
                        const claimedQuantity = Math.min(grnItem.getQuantityAccepted(), availability.invoiced)
                        screen.grnItemMatchMap[grnItem.id] = { quantity: claimedQuantity, type: claimedQuantity == grnItem.getQuantityAccepted() ? 'full' : 'partial', of: grnItem.getQuantityAccepted() }
                        availability.invoiced -= claimedQuantity
                    }

                    screen.poFullyMatchedMap[po.id] =
                        po.invoices.every(invoice => screen.invoiceFullyMatchedMap[invoice.id])
                        && grnItems.every(grnItem => screen.grnItemMatchMap[grnItem.id]?.type == 'full')
                        && po.items.every(poItem => poItem.quantity == availabilityMap.get(poItem.id)?.receivedOf)
                }
            }

        }
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            this.action_doMatching($context)
        }
    }

    async action_toggleInvoiceRevoked(invoiceId: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        invoiceId = this.storeService.replaceWithStateVersion(invoiceId, $context)
        {
            for (const offer of screen.request.vendorOffers) {
                for (const po of offer.purchaseOrders) {
                    const invoice = po.invoices.find(i => i.id == invoiceId)
                    if (invoice) {
                        invoice.isRevoked = !invoice.isRevoked
                    }
                }
                this.action_doMatching($context)
            }

        }
    }

    async action_togglePoClosed(poId: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        poId = this.storeService.replaceWithStateVersion(poId, $context)
        {
            const allPos = screen.request.vendorOffers.flatMap(offer => offer.purchaseOrders)
            const po = allPos.find(po => po.id == poId)
            if (po) {
                po.isClosed = !po.isClosed
            }
            screen.isAllPosClosed = allPos.every(po => po.isClosed)
        }
    }

    async action_approveInvoicePayment(invoiceId: number, $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        invoiceId = this.storeService.replaceWithStateVersion(invoiceId, $context)
        {
            for (const offer of screen.request.vendorOffers) {
                for (const po of offer.purchaseOrders) {
                    const invoice = po.invoices.find(i => i.id == invoiceId)
                    if (invoice) {
                        invoice.paymentStatus?.setApproved()
                    }
                }
            }

        }
    }
}

// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => ArScheduleEventType.loadInternal(), entryList => {
        ArScheduleEventType.θentryList = entryList
        ArScheduleEventType.θentryMap = undefined
        ArScheduleEventType.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class ArScheduleEventType {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: ArScheduleEventType.Id;
    static θids: ReadonlyArray<ArScheduleEventType.Id> = Object.freeze(['1stReminder', '2ndReminder', '3rdReminder']);
    static θentryMap: Readonly<Record<ArScheduleEventType.Id, Readonly<ArScheduleEventType.Entry>>>;
    static θentryList: ReadonlyArray<ArScheduleEventType.Entry>;
    static θdropdownOptionsCache = new Map<keyof ArScheduleEventType.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.ArScheduleEventType' {
        return 'PurchaseToPay.ArScheduleEventType'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.ArScheduleEventType' {
        return 'PurchaseToPay.ArScheduleEventType'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return ArScheduleEventType.getEntryMap()[this.id]?.label
    }

    get isFirstReminder() {
        return this.id == '1stReminder'
    }

    static get FirstReminder() {
        return new this('1stReminder')
    }

    get isSecondReminder() {
        return this.id == '2ndReminder'
    }

    static get SecondReminder() {
        return new this('2ndReminder')
    }

    get isThirdReminder() {
        return this.id == '3rdReminder'
    }

    static get ThirdReminder() {
        return new this('3rdReminder')
    }

    constructor(id: ArScheduleEventType.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: ArScheduleEventType.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof ArScheduleEventType.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<ArScheduleEventType.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "1stReminder", get label() { return This.$T.firstReminder } }),
            Object.freeze({ id: "2ndReminder", get label() { return This.$T.secondReminder } }),
            Object.freeze({ id: "3rdReminder", get label() { return This.$T.thirdReminder } })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new ArScheduleEventType(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: ArScheduleEventType.Id | ArScheduleEventType.Option | ArScheduleEventType) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.ArScheduleEventType yet:', err.message)
            //throw err
        })
    }

    setFirstReminder() {
        this.id = '1stReminder'

    }

    setSecondReminder() {
        this.id = '2ndReminder'

    }

    setThirdReminder() {
        this.id = '3rdReminder'

    }
}

export const ArScheduleEventTypeθId = String;

export type ArScheduleEventTypeθId = string;

export namespace ArScheduleEventType {
    export type Id = '1stReminder' | '2ndReminder' | '3rdReminder'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
    }

    export enum Option {
        FirstReminder = '1stReminder',
        SecondReminder = '2ndReminder',
        ThirdReminder = '3rdReminder'
    }
}
Sys.Language.languageChanged$.subscribe(() => ArScheduleEventType.load(true))

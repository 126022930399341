// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => VendorContract.loadInternal(), entryList => {
        VendorContract.θentryList = entryList
        VendorContract.θentryMap = undefined
        VendorContract.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class VendorContract {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: VendorContract.Id;
    static θids: ReadonlyArray<VendorContract.Id> = Object.freeze(['Pfister_Rahmenvertrag', 'Pfister_Ad1', 'Digitec_Rahmenvertrag', 'Digitec_Ad1']);
    static θentryMap: Readonly<Record<VendorContract.Id, Readonly<VendorContract.Entry>>>;
    static θentryList: ReadonlyArray<VendorContract.Entry>;
    static θdropdownOptionsCache = new Map<keyof VendorContract.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.VendorContract' {
        return 'PurchaseToPay.VendorContract'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.VendorContract' {
        return 'PurchaseToPay.VendorContract'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return VendorContract.getEntryMap()[this.id]?.label
    }

    get vendorId() {
        return VendorContract.getEntryMap()[this.id]?.vendorId
    }

    get isRC900() {
        return this.id == 'Pfister_Rahmenvertrag'
    }

    static get RC900() {
        return new this('Pfister_Rahmenvertrag')
    }

    get isRC901() {
        return this.id == 'Pfister_Ad1'
    }

    static get RC901() {
        return new this('Pfister_Ad1')
    }

    get isDC1000() {
        return this.id == 'Digitec_Rahmenvertrag'
    }

    static get DC1000() {
        return new this('Digitec_Rahmenvertrag')
    }

    get isDC1001() {
        return this.id == 'Digitec_Ad1'
    }

    static get DC1001() {
        return new this('Digitec_Ad1')
    }

    constructor(id: VendorContract.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: VendorContract.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof VendorContract.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<VendorContract.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "Pfister_Rahmenvertrag", label: "RC900", vendorId: "5" }),
            Object.freeze({ id: "Pfister_Ad1", label: "RC901", vendorId: "5" }),
            Object.freeze({ id: "Digitec_Rahmenvertrag", label: "DC1000", vendorId: "3" }),
            Object.freeze({ id: "Digitec_Ad1", label: "DC1001", vendorId: "3" })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new VendorContract(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: VendorContract.Id | VendorContract.Option | VendorContract) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.VendorContract yet:', err.message)
            //throw err
        })
    }

    setRC900() {
        this.id = 'Pfister_Rahmenvertrag'

    }

    setRC901() {
        this.id = 'Pfister_Ad1'

    }

    setDC1000() {
        this.id = 'Digitec_Rahmenvertrag'

    }

    setDC1001() {
        this.id = 'Digitec_Ad1'

    }
}

export const VendorContractθId = String;

export type VendorContractθId = string;

export namespace VendorContract {
    export type Id = 'Pfister_Rahmenvertrag' | 'Pfister_Ad1' | 'Digitec_Rahmenvertrag' | 'Digitec_Ad1'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
        vendorId: string;
    }

    export enum Option {
        RC900 = 'Pfister_Rahmenvertrag',
        RC901 = 'Pfister_Ad1',
        DC1000 = 'Digitec_Rahmenvertrag',
        DC1001 = 'Digitec_Ad1'
    }
}
Sys.Language.languageChanged$.subscribe(() => VendorContract.load(true))

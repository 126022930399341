import { JsonMappable } from './json-mappable'
import { BaseJsonMapper } from './base-json-mapper'
import { Class } from '@shared/types'
import { MappableSharedRootClasses } from '@shared/shared-classes'

export class SharedJsonMapper<T extends object> extends BaseJsonMapper<T> {
	constructor(prettyPrint: boolean, private additionalKnownTypes?: Record<string, Class<object>>) {
		super(prettyPrint, {
			...MappableSharedRootClasses,
			...additionalKnownTypes,
		})
	}
}

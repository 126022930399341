// Auto-generated by Lowgile
import { jsonObject as LowgileJsonObject } from "typedjson";
import { BoTypeSymbol, OnAfterCodeLoad } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import { DataLoader } from "@shared/data/data-loader";
import { AppContext } from "@ng-shared/lib/app-context";
import { ChangeDetectorRef } from "@angular/core";

const θdataLoader =
    new DataLoader(() => PurchaseVendorOfferStatus.loadInternal(), entryList => {
        PurchaseVendorOfferStatus.θentryList = entryList
        PurchaseVendorOfferStatus.θentryMap = undefined
        PurchaseVendorOfferStatus.θonEntryListUpdated()
    }, { doCache: true, expireAfterMs: 0 })
    ;

// Auto-generated by Lowgile
@LowgileJsonObject
export class PurchaseVendorOfferStatus {
    static readonly [BoTypeSymbol] = 'StaticEntity';
    readonly [BoTypeSymbol] = 'StaticEntity';
    id: PurchaseVendorOfferStatus.Id;
    static θids: ReadonlyArray<PurchaseVendorOfferStatus.Id> = Object.freeze(['notYetRequested', 'notShortlisted', 'requested', 'received', 'wontOffer', 'timedOut']);
    static θentryMap: Readonly<Record<PurchaseVendorOfferStatus.Id, Readonly<PurchaseVendorOfferStatus.Entry>>>;
    static θentryList: ReadonlyArray<PurchaseVendorOfferStatus.Entry>;
    static θdropdownOptionsCache = new Map<keyof PurchaseVendorOfferStatus.Entry, Sys.Types.DropdownOption[]>();
    readonly θhasOthers = false;
    [immerable] = true;

    get __type(): 'PurchaseToPay.PurchaseVendorOfferStatus' {
        return 'PurchaseToPay.PurchaseVendorOfferStatus'
    }

    set __type(_) {
    }

    static get __type(): 'PurchaseToPay.PurchaseVendorOfferStatus' {
        return 'PurchaseToPay.PurchaseVendorOfferStatus'
    }

    static set __type(_) {
    }

    get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    static get __boType(): 'StaticEntity' {
        return 'StaticEntity'
    }

    get label() {
        return PurchaseVendorOfferStatus.getEntryMap()[this.id]?.label
    }

    get isNotYetRequested() {
        return this.id == 'notYetRequested'
    }

    static get NotYetRequested() {
        return new this('notYetRequested')
    }

    get isNotShortlisted() {
        return this.id == 'notShortlisted'
    }

    static get NotShortlisted() {
        return new this('notShortlisted')
    }

    get isRequested() {
        return this.id == 'requested'
    }

    static get Requested() {
        return new this('requested')
    }

    get isReceived() {
        return this.id == 'received'
    }

    static get Received() {
        return new this('received')
    }

    get isWontOffer() {
        return this.id == 'wontOffer'
    }

    static get WontOffer() {
        return new this('wontOffer')
    }

    get isTimedOut() {
        return this.id == 'timedOut'
    }

    static get TimedOut() {
        return new this('timedOut')
    }

    constructor(id: PurchaseVendorOfferStatus.Id = null) {
        this.id = id

    }

    static getEntryList() {
        θdataLoader.waitAndGetPossiblyExpiredData()
        return this.θentryList
    }

    static getEntryMap() {
        if (!this.θentryMap) {
            const entryList = this.getEntryList() ?? []
            const objEntries = entryList.map(e => [e.id, e])
            this.θentryMap = Object.freeze(Object.fromEntries(objEntries))
        }

        return this.θentryMap
    }

    static getEntry(id: PurchaseVendorOfferStatus.Id) {
        const entryMap = this.getEntryMap()
        return entryMap[id]
    }

    static getDropdownOptions(labelColumn: keyof PurchaseVendorOfferStatus.Entry): Sys.Types.DropdownOption[] {
        let options = this.θdropdownOptionsCache.get(labelColumn)
        if (!options) {
            const entryList = this.getEntryList() ?? []
            options = entryList.map(entry => ({ id: entry.id, label: String(entry[labelColumn]), isOther: false }))
            this.θdropdownOptionsCache.set(labelColumn, options)
        }

        return options
    }

    static async load(forceReload = false) {
        if (forceReload) {
            this.θentryList = await θdataLoader.forceReloadAndGetData()
        } else {
            this.θentryList = await θdataLoader.waitAndGetValidData()
        }

        this.θonEntryListUpdated()
        return this.θentryList
    }

    static async loadInternal(): Promise<PurchaseVendorOfferStatus.Entry[]> {
        const dynamicEntryList = []
        const staticEntryList = Object.freeze([
            Object.freeze({ id: "notYetRequested", label: "Not yet requested" }),
            Object.freeze({ id: "notShortlisted", label: "Not shortlisted" }),
            Object.freeze({ id: "requested", label: "Requested" }),
            Object.freeze({ id: "received", label: "Received" }),
            Object.freeze({ id: "wontOffer", label: "Vendor will not offer" }),
            Object.freeze({ id: "timedOut", label: "Timed out" })
        ])
        this.θentryMap = null
        this.θdropdownOptionsCache.clear()
        return LowgileDataUtil.createReadonlyProxy(true, [...staticEntryList, ...dynamicEntryList])
    }

    toJSON() {
        return this.id
    }

    θclone() {
        return new PurchaseVendorOfferStatus(this.id)
    }

    θtoPlainObject() {
        return this.id
    }

    θupdate(idOrObject: PurchaseVendorOfferStatus.Id | PurchaseVendorOfferStatus.Option | PurchaseVendorOfferStatus) {
        if (typeof idOrObject == 'object') {
            this.id = idOrObject.id

        } else {
            this.id = idOrObject as any
        }
    }

    static θonEntryListUpdated() {
        AppContext.resolve(ChangeDetectorRef).detectChanges()
    }

    static async θafterCodeLoad() {
        return this.load().catch(err => {
            console.log('Could not load entries of StaticEntity PurchaseToPay.PurchaseVendorOfferStatus yet:', err.message)
            //throw err
        })
    }

    setNotYetRequested() {
        this.id = 'notYetRequested'

    }

    setNotShortlisted() {
        this.id = 'notShortlisted'

    }

    setRequested() {
        this.id = 'requested'

    }

    setReceived() {
        this.id = 'received'

    }

    setWontOffer() {
        this.id = 'wontOffer'

    }

    setTimedOut() {
        this.id = 'timedOut'

    }
}

export const PurchaseVendorOfferStatusθId = String;

export type PurchaseVendorOfferStatusθId = string;

export namespace PurchaseVendorOfferStatus {
    export type Id = 'notYetRequested' | 'notShortlisted' | 'requested' | 'received' | 'wontOffer' | 'timedOut'
    export const Id = String

    export interface Entry {
        id: string;
        label: string;
    }

    export enum Option {
        NotYetRequested = 'notYetRequested',
        NotShortlisted = 'notShortlisted',
        Requested = 'requested',
        Received = 'received',
        WontOffer = 'wontOffer',
        TimedOut = 'timedOut'
    }
}
Sys.Language.languageChanged$.subscribe(() => PurchaseVendorOfferStatus.load(true))

import { jsonObject, jsonMember, toJson } from "typedjson";
// Auto-generated by Lowgile
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
import type { HasTokenAssignment, LaneAssigneeType } from "@shared/types";

@jsonObject({ preserveNull: true })
@toJson
class $OrderToCash {
    static get __type(): 'PurchaseToPay.OrderToCash' {
        return 'PurchaseToPay.OrderToCash'
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.OrderToCash' {
        return 'PurchaseToPay.OrderToCash'
    }

    static async start(inputData: $OrderToCash.ProcessInputData, startBy?: 'normal') {
        const instanceId = await ExecutionService.executeMethodAndReturnData<string>('Process', 'PurchaseToPay', 'OrderToCash', this, 'start', [inputData, startBy])
        return instanceId
    }

    static async startAndGetFirstUserTaskToken(inputData: $OrderToCash.ProcessInputData, startBy?: 'normal', taskTimeoutMs?: number, taskDebounceMs?: number) {
        const obj = await ExecutionService.executeMethodAndReturnData<{ instanceId: string, taskToken: Sys.Types.UserTaskToken }>('Process', 'PurchaseToPay', 'OrderToCash', this, 'startAndGetFirstUserTaskToken', [inputData, startBy, taskTimeoutMs, taskDebounceMs])
        if (obj?.taskToken) obj.taskToken = new Sys.Types.UserTaskToken(obj.taskToken)
        return obj
    }

    static async startAndNavigateToFirstUserTask(inputData: $OrderToCash.ProcessInputData, isNewWindow: boolean | string, startBy?: 'normal', failIfNoUserTask: boolean = true, taskTimeoutMs?: number, taskDebounceMs?: number) {
        const obj = await this.startAndGetFirstUserTaskToken(inputData, startBy, taskTimeoutMs, taskDebounceMs)
        if (obj.taskToken) {
            obj.taskToken = new Sys.Types.UserTaskToken(obj.taskToken)
            await Sys.Process.navigateToTask(obj.taskToken, isNewWindow)
        } else {
            if (failIfNoUserTask) throw new Error(`No user task found for process instance ${obj.instanceId}`)
        }

        return obj
    }
}

namespace $OrderToCash {
    import PurchaseToPay = $Modules.PurchaseToPay
    import System = $Modules.System
    import Common = $Modules.Common

    export interface ProcessInputData {
    }
}

export { $OrderToCash as OrderToCash };

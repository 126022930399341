// Auto-generated by Lowgile
import { jsonObject, jsonMember, jsonArrayMember, toJson } from "typedjson";
import { BoTypeSymbol, EntityInfoSymbol } from "@shared/types";
import { immerable } from "immer";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";
@jsonObject({ preserveNull: true })
@toJson
class PurchaseToPay__PurchaseGoodsReceiptNote {
    @jsonMember(() => PurchaseToPay__PurchaseGoodsReceiptNote.Id)
    id: PurchaseToPay__PurchaseGoodsReceiptNote.Id;
    static readonly [BoTypeSymbol] = 'Entity';
    readonly [BoTypeSymbol] = 'Entity';
    static readonly [EntityInfoSymbol] = Object.freeze({
        idType: "number" as const,
        propertyNames: LowgileDataUtil.unwrapReadonly(Object.freeze(["id", "postingDate", "number", "receiptNumber", "items", "purchaseOrder", "purchaseOrderId", "createdOn", "createdBy", "updatedOn", "updatedBy", "deletedOn", "deletedBy", "versionNumber"] as const)),
        propertyTypes: LowgileDataUtil.unwrapReadonly(Object.freeze({ "postingDate": "Date", "number": "string", "receiptNumber": "string", "items": "PurchaseToPay.PurchaseGoodsReceiptNoteItem[]", "purchaseOrder": "PurchaseToPay.PurchaseOrder", "purchaseOrderId": "PurchaseToPay.PurchaseOrder.Id", "createdOn": "Date", "createdBy": "string", "updatedOn": "Date", "updatedBy": "string", "deletedOn": "Date", "deletedBy": "string", "versionNumber": "number", "id": "number" } as const)),
        staticEntityProperties: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        searchInProperties: LowgileDataUtil.unwrapReadonly(Object.freeze(["id"] as const)),
        subsetNames: LowgileDataUtil.unwrapReadonly(Object.freeze([] as const)),
        manyToOneRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ purchaseOrder: 'PurchaseToPay.PurchaseOrder' } as const)),
        oneToManyRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({ items: 'PurchaseToPay.PurchaseGoodsReceiptNoteItem' } as const)),
        oneToOneOwningRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
        oneToOneSecondaryRelationships: LowgileDataUtil.unwrapReadonly(Object.freeze({} as const)),
    });
    [immerable] = true;
    private θpostingDate?: Date = undefined;
    private θnumber?: string = undefined;
    private θreceiptNumber?: string = undefined;
    private θitems?: PurchaseToPay.PurchaseGoodsReceiptNoteItem[] = undefined;
    private θpurchaseOrder?: PurchaseToPay.PurchaseOrder = undefined;
    private θpurchaseOrderId?: number = null;
    private θcreatedOn?: Date = undefined;
    private θcreatedBy?: string = undefined;
    private θupdatedOn?: Date = undefined;
    private θupdatedBy?: string = undefined;
    private θdeletedOn?: Date = undefined;
    private θdeletedBy?: string = undefined;
    private θversionNumber?: number = undefined;
    static θmaxLength = {
        postingDate: 0,
        number: 0,
        receiptNumber: 0,
        items: 0,
        purchaseOrder: 0,
        purchaseOrderId: 0,
        createdOn: 0,
        createdBy: 0,
        updatedOn: 0,
        updatedBy: 0,
        deletedOn: 0,
        deletedBy: 0,
        versionNumber: 0,

    } as const;
    θisMandatory = {
        postingDate: () => {
            return false
        }
        , number: () => {
            return false
        }
        , receiptNumber: () => {
            return false
        }
        , items: () => {
            return false
        }
        , purchaseOrder: () => {
            return false
        }
        , purchaseOrderId: () => {
            return false
        }
        , createdOn: () => {
            return false
        }
        , createdBy: () => {
            return false
        }
        , updatedOn: () => {
            return false
        }
        , updatedBy: () => {
            return false
        }
        , deletedOn: () => {
            return false
        }
        , deletedBy: () => {
            return false
        }
        , versionNumber: () => {
            return false
        }
        ,
    } as const;

    static get __type(): 'PurchaseToPay.PurchaseGoodsReceiptNote' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNote'
    }

    set __type(_) {
    }

    @jsonMember(String)
    get __type(): 'PurchaseToPay.PurchaseGoodsReceiptNote' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNote'
    }

    static get __baseType(): 'PurchaseToPay.PurchaseGoodsReceiptNote' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNote'
    }

    set __baseType(_) {
    }

    get __baseType(): 'PurchaseToPay.PurchaseGoodsReceiptNote' {
        return 'PurchaseToPay.PurchaseGoodsReceiptNote'
    }

    get [EntityInfoSymbol]() {
        return PurchaseToPay__PurchaseGoodsReceiptNote[EntityInfoSymbol]
    }

    get postingDate() {
        return this.θpostingDate
    }

    set postingDate(postingDate: Date) {
        this.θpostingDate = LowgileDataUtil.coerceDataType(postingDate, 'Date', false, true)
    }

    get number() {
        return this.θnumber
    }

    set number(number: string) {
        this.θnumber = LowgileDataUtil.coerceDataType(number, 'string', false, true)
    }

    get receiptNumber() {
        return this.θreceiptNumber
    }

    set receiptNumber(receiptNumber: string) {
        this.θreceiptNumber = LowgileDataUtil.coerceDataType(receiptNumber, 'string', false, true)
    }

    get items() {
        return this.θitems
    }

    set items(items: PurchaseToPay.PurchaseGoodsReceiptNoteItem[]) {
        this.θitems = LowgileDataUtil.coerceDataType(items, PurchaseToPay.PurchaseGoodsReceiptNoteItem, true, true)
    }

    get purchaseOrder() {
        return this.θpurchaseOrder
    }

    set purchaseOrder(purchaseOrder: PurchaseToPay.PurchaseOrder) {
        this.θpurchaseOrder = LowgileDataUtil.coerceDataType(purchaseOrder, PurchaseToPay.PurchaseOrder, false, true)
    }

    get purchaseOrderId() {
        return this.θpurchaseOrderId
    }

    set purchaseOrderId(purchaseOrderId: number) {
        this.θpurchaseOrderId = LowgileDataUtil.coerceDataType(purchaseOrderId, 'number', false, true)
    }

    get createdOn() {
        return this.θcreatedOn
    }

    set createdOn(createdOn: Date) {
        this.θcreatedOn = LowgileDataUtil.coerceDataType(createdOn, 'Date', false, true)
    }

    get createdBy() {
        return this.θcreatedBy
    }

    set createdBy(createdBy: string) {
        this.θcreatedBy = LowgileDataUtil.coerceDataType(createdBy, 'string', false, true)
    }

    get updatedOn() {
        return this.θupdatedOn
    }

    set updatedOn(updatedOn: Date) {
        this.θupdatedOn = LowgileDataUtil.coerceDataType(updatedOn, 'Date', false, true)
    }

    get updatedBy() {
        return this.θupdatedBy
    }

    set updatedBy(updatedBy: string) {
        this.θupdatedBy = LowgileDataUtil.coerceDataType(updatedBy, 'string', false, true)
    }

    get deletedOn() {
        return this.θdeletedOn
    }

    set deletedOn(deletedOn: Date) {
        this.θdeletedOn = LowgileDataUtil.coerceDataType(deletedOn, 'Date', false, true)
    }

    get deletedBy() {
        return this.θdeletedBy
    }

    set deletedBy(deletedBy: string) {
        this.θdeletedBy = LowgileDataUtil.coerceDataType(deletedBy, 'string', false, true)
    }

    get versionNumber() {
        return this.θversionNumber
    }

    set versionNumber(versionNumber: number) {
        this.θversionNumber = LowgileDataUtil.coerceDataType(versionNumber, 'number', false, true)
    }

    get θmaxLength() {
        return PurchaseToPay__PurchaseGoodsReceiptNote.θmaxLength
    }

    constructor(init?: Partial<PurchaseToPay__PurchaseGoodsReceiptNote>) {
        LowgileDataUtil.assignCommonProperties(this, init)
        const θinitFn: any = (this as any).init
        if (typeof θinitFn == 'function') θinitFn(init)
    }

    θtoPlainObject(recursive = true, includeType = false) {
        const obj = {}

        for (const key of this.θgetPropertyNames(includeType)) {
            let value = Reflect.get(this, key) as any
            if (recursive && typeof value?.θtoPlainObject == 'function') {
                value = value.θtoPlainObject(recursive, includeType)
            }

            Reflect.set(obj, key, value)
        }

        return obj
    }

    θclone() {
        const clone = new PurchaseToPay__PurchaseGoodsReceiptNote(this)
        for (const [key, value] of Object.entries(this)) {
            if (typeof value?.θclone == 'function') {
                Reflect.set(clone, key, value.θclone())
            }

        }

        return clone
    }

    θupdateFrom(source: Partial<PurchaseToPay__PurchaseGoodsReceiptNote>, restrictToClass?: Class<Partial<PurchaseToPay__PurchaseGoodsReceiptNote>>) {
        LowgileDataUtil.assignCommonProperties(this, source, { restrictToClass })
    }

    θgetPropertyNames(includeType: boolean = false) {
        const names: string[] = []
        names.push('id')
        names.push('postingDate', 'number', 'receiptNumber', 'items', 'purchaseOrder', 'purchaseOrderId', 'createdOn', 'createdBy', 'updatedOn', 'updatedBy', 'deletedOn', 'deletedBy', 'versionNumber')
        if (includeType) names.push('__type')
        return names
    }

    θcleanupBeforeSave() {
    }
}

namespace PurchaseToPay__PurchaseGoodsReceiptNote { }

export import PurchaseGoodsReceiptNote = PurchaseToPay__PurchaseGoodsReceiptNote
setTimeout(() => {
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'postingDate')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'number')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'receiptNumber')
    jsonArrayMember(() => PurchaseToPay.PurchaseGoodsReceiptNoteItem)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'items')
    jsonMember(() => PurchaseToPay.PurchaseOrder)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'purchaseOrder')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'purchaseOrderId')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'createdOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'createdBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'updatedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'updatedBy')
    jsonMember(() => Date)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'deletedOn')
    jsonMember(() => String)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'deletedBy')
    jsonMember(() => Number)(PurchaseToPay__PurchaseGoodsReceiptNote.prototype, 'versionNumber')
})

namespace PurchaseToPay__PurchaseGoodsReceiptNote {
}

export const PurchaseGoodsReceiptNoteθId = Number;

export type PurchaseGoodsReceiptNoteθId = number;

namespace PurchaseToPay__PurchaseGoodsReceiptNote {
    export type Id = NominalType<number, 'PurchaseToPay.PurchaseGoodsReceiptNote'>
    export const Id = Number
}

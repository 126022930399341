// Auto-generated by Lowgile
import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, inject } from "@angular/core";
import { GlobalStateInterface, globalInitialState } from "@app/store/global-state-interface";
import { AbstractScreen } from "@ng-shared/lib/components/abstract-screen";
import { ValidationRule } from "@shared/types";
import { ProxyTargetSymbol } from "@shared/util/proxy-util";
import { combineLatest } from "rxjs";
import { ProcessTaskComponent } from "@app/components/process-task.component";
import * as LowgileDataUtil from "@shared/util/data-util";
import * as Ramda from "ramda";
import * as $Modules from "@app/Module/$modules";
import * as This from "../../Module/PurchaseToPay";
import * as PurchaseToPay from "../../Module/PurchaseToPay";
import { System, Common } from "@app/Module/$modules";
import type { HasId, IdType, Class, DeepReadonly, NominalType } from "@shared/types";
import * as Sys from "@ng-shared/lib/frontend-sys";
import { ExecutionService } from "@ng-shared/lib/services/execution.service";

interface Properties {
    deliveryAddress: string;
    billingAddress: string;
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

interface State {
    app: GlobalStateInterface;
    screen: Properties;
}

@Component({
    selector: 'PurchaseToPay-UserProfile',
    templateUrl: './UserProfile.html',
    styleUrls: ['./UserProfile.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfile extends AbstractScreen implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    static readonly $canBeMainScreen = true;
    static readonly $canBeDialogScreen = true;
    readonly $qualifiedScreenName = 'PurchaseToPay.UserProfile';
    $isDialog = false;
    $Modules = $Modules;
    PurchaseToPay = PurchaseToPay;
    System = System;
    Common = Common;
    Sys = Sys;
    This = This;
    app = globalInitialState;
    $scope = {};
    $pathSegments: string[] = [];
    $processTaskComponent = inject(ProcessTaskComponent, { optional: true });
    $output = {

    };
    $validators: Record<number, ValidationRule<any>[]> = {
        13: [
            async ($value: any, $scope: any) => {
                const { deliveryAddress, billingAddress, oldPassword, newPassword, newPasswordConfirmation, app, screen, $pathSegments, $disabled, form } = $scope
                if (!screen.newPassword || screen.newPassword === screen.newPasswordConfirmation) return undefined
                return {
                    error: `Passwords don't match`,
                    ruleId: 'passwordMatch',
                }
            }
        ],
        16: [
            async ($value: any, $scope: any) => {
                const { deliveryAddress, billingAddress, oldPassword, newPassword, newPasswordConfirmation, app, screen, $pathSegments, $disabled, form } = $scope
                if (!screen.newPassword || screen.oldPassword) return undefined
                return {
                    error: `Current password is required`,
                    ruleId: 'oldPassword',
                }
            }
        ]
    };
    componentStore = this.storeService.createComponentStore<Properties>({
        deliveryAddress: this.deliveryAddress !== undefined ? this.deliveryAddress : (undefined) as string,
        billingAddress: this.billingAddress !== undefined ? this.billingAddress : (undefined) as string,
        oldPassword: this.oldPassword !== undefined ? this.oldPassword : (undefined) as string,
        newPassword: this.newPassword !== undefined ? this.newPassword : (undefined) as string,
        newPasswordConfirmation: this.newPasswordConfirmation !== undefined ? this.newPasswordConfirmation : (undefined) as string
    });

    get $inputs() {
        return {

        }
    }

    get deliveryAddress() {
        return this.screen?.deliveryAddress
    }

    set deliveryAddress(deliveryAddress: string) {
        deliveryAddress = (deliveryAddress as any)?.[ProxyTargetSymbol] ?? deliveryAddress
        this.storeService.setProperty([this.componentStore.name], 'deliveryAddress', deliveryAddress)
    }

    get billingAddress() {
        return this.screen?.billingAddress
    }

    set billingAddress(billingAddress: string) {
        billingAddress = (billingAddress as any)?.[ProxyTargetSymbol] ?? billingAddress
        this.storeService.setProperty([this.componentStore.name], 'billingAddress', billingAddress)
    }

    get oldPassword() {
        return this.screen?.oldPassword
    }

    set oldPassword(oldPassword: string) {
        oldPassword = (oldPassword as any)?.[ProxyTargetSymbol] ?? oldPassword
        this.storeService.setProperty([this.componentStore.name], 'oldPassword', oldPassword)
    }

    get newPassword() {
        return this.screen?.newPassword
    }

    set newPassword(newPassword: string) {
        newPassword = (newPassword as any)?.[ProxyTargetSymbol] ?? newPassword
        this.storeService.setProperty([this.componentStore.name], 'newPassword', newPassword)
    }

    get newPasswordConfirmation() {
        return this.screen?.newPasswordConfirmation
    }

    set newPasswordConfirmation(newPasswordConfirmation: string) {
        newPasswordConfirmation = (newPasswordConfirmation as any)?.[ProxyTargetSymbol] ?? newPasswordConfirmation
        this.storeService.setProperty([this.componentStore.name], 'newPasswordConfirmation', newPasswordConfirmation)
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit()
        await this.authService.initializedPromise
        this.$isDialog = this.dialogData != undefined
        if (this.$isDialog) {

        }

        if (!this.$processTaskComponent) {
            this.processService.tokenId.set(undefined)
        }



        this.subscriptions.push(combineLatest([this.route.queryParams, this.route.url]).subscribe(async ([params, urlSegments]) => {
            this.$pathSegments = urlSegments.map(s => decodeURIComponent(s.path))
            await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
                const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event: null }




                try {
                    await this.action_onInit($context)
                } catch (err: any) {
                    Sys.App.showNotification('error', err.message)
                }

            }, this.componentStore)
            this.cdRef.detectChanges()
        }))
        this.subscriptions.push(
            this.storeService.appStore.state$.subscribe(state => {
                this.app = state
                this.cdRef.detectChanges()
            }),
            this.componentStore.state$.subscribe(state => {
                this.cdRef.detectChanges()
            }),
        )
    }

    async ngAfterViewInit(): Promise<void> {
    }

    async ngOnDestroy(): Promise<void> {
        super.ngOnDestroy()
        this.storeService.dropComponentStore(this.componentStore.name)
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {

    }

    async handler_ButtonBlock_0_0_0_0_2_0_0_click_save_0($event: any, $scope: any): Promise<void> {
        let { deliveryAddress, billingAddress, oldPassword, newPassword, newPasswordConfirmation, app, screen, $pathSegments, $disabled, form }: { deliveryAddress: string, billingAddress: string, oldPassword: string, newPassword: string, newPasswordConfirmation: string, app: any, screen: any, $pathSegments: string[], $disabled: boolean, form: Sys.Types.Form } = $scope ?? {}



        await this.storeService.update<GlobalStateInterface, Properties>(async θstate => {
            const $context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties> = { ...θstate, output: this.$output, $event }

            try {
                await this.action_save($context)
            } catch (err: any) {
                Sys.App.showNotification('error', err.message)
            }
            this.cdRef.detectChanges()
            $event.preventDefault?.()
        }, this.componentStore)
    }

    async action_onInit($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            screen.deliveryAddress = await This.DefaultDeliveryAddress.get()
            screen.billingAddress = await This.DefaultBillingAddress.get()
        }
    }

    async action_save($context: Sys.Types.ScreenActionContext<GlobalStateInterface, Properties>) {
        const { app, screen, output, $event } = $context
        {
            try {
                const promises: Promise<any>[] = [
                    This.DefaultDeliveryAddress.set(screen.deliveryAddress),
                    This.DefaultBillingAddress.set(screen.billingAddress),
                ]

                if (screen.newPassword && screen.newPassword === screen.newPasswordConfirmation) {
                    promises.push(Sys.User.updatePassword(screen.oldPassword, screen.newPassword).then(successful => {
                        if (!successful) throw new Error('Failed to change password')
                    }))
                }

                await Promise.all(promises)
                Sys.App.showNotification('success', 'Saved successfully')
            } catch (err) {
                Sys.App.showNotification('error', err.message)
            }

        }
    }
}

import { StoreService } from './store.service';
import { Directive, HostListener, Input } from '@angular/core';
import { LowgilePathSymbol } from './store-proxy-handler'
import { MatCheckboxChange } from '@angular/material/checkbox'
// import { isMoment } from 'moment'

@Directive({
  selector: '[bindingObject][bindingProperty]',
  exportAs: 'lowgileBinding',
})
export class BindingDirective {
  private obj!: object
  private property!: string
  private type!: string

  @Input() set bindingObject(obj: object) {
    this.obj = obj
  }

  @Input() set bindingProperty(property: string) {
    this.property = property
  }

  @Input() set bindingType(type: string) {
    this.type = type
  }

  constructor(
    private storeService: StoreService,
  ) { }

  @HostListener('input', ['$event'])
  @HostListener('selectionChange', ['$event'])
  // @HostListener('dateInput', ['$event'])
  @HostListener('dateChange', ['$event'])
  @HostListener('change', ['$event']) // for ToggleGroupBlock
  onChange(event: Event) {
    if(event.type == 'input' && (event.target as HTMLInputElement)?.classList.contains('mat-datepicker-input')) return // ignore event on input element of date picker when manually typing date; another event will be fired as well
    if(event.type == 'change') {
      // ignore as this will only be the case for "normal" change events, but we're interested only in MatButtonToggleChange
      return
    }

    const valueType = (event.target as any)?.type

    let value = 
      (event.target as any)?.value
      ?? (event as any).value
      ?? (event as any as MatCheckboxChange).checked
      ?? null
    
    // if(isMoment(value)) {
    //   const strOrObjValue = (value as any)._i
    //   const formatStr = (value as any)._f
    //   if(typeof strOrObjValue == 'string' && strOrObjValue.length != formatStr.length) return
    //   value = value.toDate()
    // }
        
    if(valueType == 'number') {
      value = parseFloat(value)
      if(Number.isNaN(value)) value = null
    }
    if(valueType == 'checkbox') value = (event.target as any).checked

    if(value != this.getValue()) {
      this.setValue(value)
    }
  }

  getValue() {
    return Reflect.get(this.obj, this.property)
  }
  setValue(value: any) {
    const path: string[] = (this.obj as any)?.[LowgilePathSymbol];
    this.storeService.setPropertyAndPropagateToOtherStores(path, this.property, value)
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../lowgile/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../lowgile/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  display: grid;
  grid-template-columns: max-content minmax(10em, 1fr) max-content max-content;
  align-items: center;
  gap: 1em;
}

.grid:empty {
  display: none;
}

.dropzone {
  width: 100%;
  min-height: 1em;
  border: 1px dashed gray;
  background-color: lightgray;
  color: gray;
  padding: 1em;
  cursor: pointer;
}
.dropzone.disabled {
  cursor: not-allowed;
}

.draggingOver {
  background-color: darkgray;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbGUtdXBsb2FkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0MsYUFBQTtFQUNBLDRFQUFBO0VBQ0EsbUJBQUE7RUFDQSxRQUFBO0FBQ0Q7O0FBRUE7RUFDQyxhQUFBO0FBQ0Q7O0FBRUE7RUFDQyxXQUFBO0VBQ0EsZUFBQTtFQUNBLHVCQUFBO0VBQ0EsMkJBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGVBQUE7QUFDRDtBQUNDO0VBQ0MsbUJBQUE7QUFDRjs7QUFHQTtFQUNDLDBCQUFBO0FBQUQiLCJmaWxlIjoiZmlsZS11cGxvYWQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZ3JpZCB7XG5cdGRpc3BsYXk6IGdyaWQ7XG5cdGdyaWQtdGVtcGxhdGUtY29sdW1uczogbWF4LWNvbnRlbnQgbWlubWF4KDEwZW0sIDFmcikgbWF4LWNvbnRlbnQgbWF4LWNvbnRlbnQ7XG5cdGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cdGdhcDogMWVtO1xufVxuXG4uZ3JpZDplbXB0eXtcblx0ZGlzcGxheTogbm9uZTtcbn1cblxuLmRyb3B6b25lIHtcblx0d2lkdGg6IDEwMCU7XG5cdG1pbi1oZWlnaHQ6IDFlbTtcblx0Ym9yZGVyOiAxcHggZGFzaGVkIGdyYXk7XG5cdGJhY2tncm91bmQtY29sb3I6IGxpZ2h0Z3JheTtcblx0Y29sb3I6IGdyYXk7XG5cdHBhZGRpbmc6IDFlbTtcblx0Y3Vyc29yOiBwb2ludGVyO1xuXG5cdCYuZGlzYWJsZWQge1xuXHRcdGN1cnNvcjogbm90LWFsbG93ZWQ7XG5cdH1cbn1cblxuLmRyYWdnaW5nT3ZlciB7XG5cdGJhY2tncm91bmQtY29sb3I6IGRhcmtncmF5O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./../../../lowgile/libs/ng-shared/src/lib/components/file-upload/file-upload.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,4EAAA;EACA,mBAAA;EACA,QAAA;AACD;;AAEA;EACC,aAAA;AACD;;AAEA;EACC,WAAA;EACA,eAAA;EACA,uBAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AACD;AACC;EACC,mBAAA;AACF;;AAGA;EACC,0BAAA;AAAD;AACA,glCAAglC","sourcesContent":[".grid {\n\tdisplay: grid;\n\tgrid-template-columns: max-content minmax(10em, 1fr) max-content max-content;\n\talign-items: center;\n\tgap: 1em;\n}\n\n.grid:empty{\n\tdisplay: none;\n}\n\n.dropzone {\n\twidth: 100%;\n\tmin-height: 1em;\n\tborder: 1px dashed gray;\n\tbackground-color: lightgray;\n\tcolor: gray;\n\tpadding: 1em;\n\tcursor: pointer;\n\n\t&.disabled {\n\t\tcursor: not-allowed;\n\t}\n}\n\n.draggingOver {\n\tbackground-color: darkgray;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

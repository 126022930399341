import { assignCommonProperties } from '@shared/util/data-util'

import { UserIdCtor, type UserIdType } from './../types';
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class UserPassword {
	@jsonMember(UserIdCtor)
	userId?: UserIdType
	
	@jsonMember(String)
	passwordHash: string = ''
	@jsonMember(String)
	recoveryTokenHash: string = ''
	@jsonMember(Date)
	recoveryTokenValidUntil?: Date

	constructor(init?: Partial<UserPassword>) {
		assignCommonProperties(this, init)
	}
}
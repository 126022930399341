import { assignCommonProperties } from '@shared/util/data-util'

import type { UserIdType, BusinessObjectTypeType, ModuleIdType, BusinessObjectIdType } from './../types';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class UserPermission {
	@jsonMember(Number)
	id?: number

	userId?: UserIdType

	@jsonMember(String)
	permissionModuleId: ModuleIdType = ''

	@jsonMember(String)
	permissionBoId: BusinessObjectIdType = ''

	@jsonArrayMember(String)
	tenantIds: string[] = []
	@jsonMember(Boolean)
	appliesAlsoToSubTenants: boolean = true

	constructor(init?: Partial<UserPermission>) {
		assignCommonProperties(this, init)
	}

	static fromQualifiedName(qualifiedName: string) {
		const [permissionModuleId, permissionBoId] = qualifiedName.split('.')
		return new UserPermission({ permissionModuleId, permissionBoId })
	}

	getQualifiedTypeAndName() {
		return `Permission:${this.getQualifiedName()}`
	}

	getQualifiedName() {
		return `${this.permissionModuleId}.${this.permissionBoId}`
	}

	matches(other: UserPermission) {
		return this.permissionModuleId === other.permissionModuleId && this.permissionBoId === other.permissionBoId
	}
}